import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { useTranslate, useLocale, useSetLocale, Title, TextInput, required, FormWithRedirect, FormDataConsumer, SaveButton } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import { Typography, Box } from '@material-ui/core';
import { useLogin, useNotify, useSafeSetState } from 'ra-core';
import { bleudineAuthApi } from '../../api/bleudineAuthApi';

const useStyles = makeStyles((theme) => ({
    formGrid: {
        paddingTop: "0!important",
        paddingBottom: "0!important",
    },
    formInput: {
        marginTop: "0!important",
        marginBottom: "0!important",
    },
    textBold: {
        fontWeight: "bold",
    },
}));

const ChangePassword = () => {
    const translate = useTranslate();
    const classes = useStyles();
    const notify = useNotify();

    const changePassword = (formData) => {
        if (formData.new_password != formData.confirm_new_password) {
            notify("New password does not match with confirm new password")
        } else {
            var user = JSON.parse(localStorage.getItem("bleudine-user"));

            const changedPassword ={
                userId : user.id,
                oldPassword : formData.current_password,
                newPassword :formData.new_password
            }

            bleudineAuthApi.changedPassword(changedPassword).then(ret => {
                notify("Change password successfull");
                window.location.reload();
            }).catch(error => {
                notify("Fail to change password");
            });
        }
    }

    return (
        <FormWithRedirect
            render={formProps => (
                <Card>
                    <form>
                        <Title title="Change Password" />
                        <CardContent>
                            <Grid container spacing={2}>
                                {/* <Grid item xs={12}>
                                    <Typography variant="h6" className={classes.textBold} gutterBottom>
                                        Change Password
                                    </Typography>
                                </Grid> */}
                                <Grid item xs={12} className={classes.formGrid}>
                                    <TextInput
                                        type="password"
                                        className={classes.formInput}
                                        source="current_password"
                                        label="Current Password"
                                        fullWidth
                                        validate={[required()]}
                                    />
                                </Grid>
                                <Grid item xs={12} className={classes.formGrid}>
                                    <TextInput
                                        type="password"
                                        className={classes.formInput}
                                        source="new_password"
                                        label="New Password"
                                        fullWidth
                                        validate={[required()]}
                                    />
                                </Grid>
                                <Grid item xs={12} className={classes.formGrid}>
                                    <TextInput
                                        type="password"
                                        className={classes.formInput}
                                        source="confirm_new_password"
                                        label="Confirm New Password"
                                        fullWidth
                                        validate={[required()]}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} className={classes.formGrid}>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => (
                                            <SaveButton
                                                {...formProps}
                                                variant="contained"
                                                color="primary"
                                                label="Confirm"
                                                className={classes.formInput}
                                                onSave={() => changePassword(formData)}
                                            />
                                        )}
                                    </FormDataConsumer>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </form>
                </Card>
            )}
        />

    );
};

export default ChangePassword;
