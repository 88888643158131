import { apiConfig } from "./apiConfig";

export async function executeAsync(variables, operation) {
  const hasuraHost = apiConfig.hasuraUrl;
  const fetchResponse = await fetch(hasuraHost, {
    method: "POST",
    headers: {
      //Authorization: `Bearer ${apiConfig.getRideumToken()}`,
      "x-hasura-admin-secret": apiConfig.hasuraSecret,
    },
    body: JSON.stringify({
      query: operation,
      variables,
    }),
  });
  return await fetchResponse.json();
}

export function output(data, errors) {
  return {
    data: data,
    hasError: errors !== undefined,
    errors,
  };
}
