import React, { useContext, useEffect } from "react";
import {
  FormWithRedirect,
  TextInput,
  SelectInput,
  required,
  minLength,
  maxLength,
  email,
  useTranslate,
  ReferenceInput,
  Toolbar,
} from "react-admin";
import { Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import CustomSaveButton from "./CustomSaveButton";
import { useNotify, useRedirect } from "react-admin";

const validateFirstName = [required(), minLength(2), maxLength(25)];
const validateEmail = [required(), email()];

const useStyles = makeStyles((theme) => ({
  formGrid: {
    paddingTop: "0!important",
    paddingBottom: "0!important",
  },
  formInput: {
    marginTop: "0!important",
    marginBottom: "0!important",
  },
  textBold: {
    fontWeight: "bold",
  },
}));

const CMAccountsForm = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const { source, ...rest } = props;

  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <Card>
          <form>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    className={classes.textBold}
                    gutterBottom
                  >
                    {translate("ra.label.client_info")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    fullWidth
                    resource="cm_accounts"
                    source="login"
                    validate={validateEmail}
                    label="ra.column.email"
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    fullWidth
                    resource="cm_accounts"
                    source="password"
                    label="ra.label.client_password"
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <ReferenceInput
                    className={classes.formInput}
                    source="status"
                    reference="cm_client_status"
                    defaultValue="Active"
                    sort={{ field: "comment", order: "ASC" }}
                    label="ra.label.client_status"
                    fullWidth
                  >
                    <SelectInput optionText="comment" />
                  </ReferenceInput>
                </Grid>

                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <ReferenceInput
                    className={classes.formInput}
                    source="cm_client_id"
                    reference="cm_clients"
                    sort={{ field: "name", order: "ASC" }}
                    label="ra.label.client_name"
                    fullWidth
                  >
                    <SelectInput optionText="name" />
                  </ReferenceInput>
                </Grid>
              </Grid>
            </CardContent>
            <Toolbar>
              <Box display="flex" justifyContent="space-between" width="100%">
                <CustomSaveButton {...formProps} />
                {/* {formProps.record.id != undefined ?
                  <DeleteButton record={formProps.record} />
                  : null} */}
              </Box>
            </Toolbar>
          </form>
        </Card>
      )}
    />
  );
};

export default CMAccountsForm;
