import React from "react";
import {
  FormWithRedirect,
  DateInput,
  TextInput,
  SelectInput,
  SaveButton,
  DeleteButton,
  required,
  minLength,
  maxLength,
  minValue,
  maxValue,
  number,
  email,
  useTranslate,
  ReferenceInput,
  NumberInput,
  FormDataConsumer,
  Toolbar,
} from "react-admin";
import { Typography, Box } from "@material-ui/core";
import { IntlPhoneInput } from "../components/IntlPhoneInput";
import { Card, CardContent } from "@material-ui/core";
import { formatter } from "../../utils/formatter";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import { orderStatus } from "../../models/orderStatus";
import { DataProviderContext } from "react-admin";
import { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useNotify, useRedirect } from "react-admin";
import ClearIcon from "@material-ui/icons/Clear";


const useStyles = makeStyles((theme) => ({
  formGrid: {
    paddingTop: "0!important",
    paddingBottom: "0!important",
  },
  formInput: {
    marginTop: "0!important",
    marginBottom: "0!important",
  },
  textBold: {
    fontWeight: "bold",
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  subCardWrap: {
    minHeight: 260,
  },
}));

const OrderForm = (props) => {
  const authInfo = JSON.parse(localStorage.getItem("auth"));
  const translate = useTranslate();
  const { source, ...rest } = props;
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useContext(DataProviderContext);
  const [availableBooking, setAvailableBooking] = React.useState([]);
  const [availableService, setAvailableService] = React.useState([]);

  useEffect(() => {
    var request = {
      tenant_id: authInfo.tenant.id,
    };
    dataProvider
      .getList("services", { filter: { ...request } })
      .then(({ data }) => {
        setAvailableService(data);
      })
      .catch((error) => {
        notify(error);
      });
    dataProvider
      .getList("bookings", { filter: { ...request } })
      .then(({ data }) => {
        setAvailableBooking(data);
      })
      .catch((error) => {
        notify(error);
      });
  }, []);

  const addOrder = (formData) => {
    let booking = availableBooking.filter((item) => {
      return item.id == formData.booking_id;
    })[0];

    var selected_service = availableService.filter(
      (fitem) => fitem.id === formData.service_id
    )[0];

    var request = {
      tenant_id: authInfo.tenant.id,
      accommodation_id: formData.accommodation_id,
      service_id: formData.service_id,
      qty: formData.qty,
      price: selected_service.price,
      service_charge: selected_service.service_charge,
      total_price:
        (selected_service.price +
          selected_service.price * selected_service.service_charge) *
        formData.qty,
      booking_id: booking.id,
      description: selected_service.name,
      order_type: "Service",
      status: "New",
    };
    dataProvider
      .create("orders", { data: request })
      .then(({ data }) => {
        refreshBooking();
        notify("Order created");
      })
      .catch((error) => {
        notify(error);
      });
  };

  const updateOrder = (process, formData) => {
    let booking = availableBooking.filter((item) => {
      return item.id == formData.booking_id;
    })[0];

    var request = {};
    if (process == "update") {
      var selected_service = availableService.filter(
        (fitem) => fitem.id === formData.service_id
      )[0];

      request.accommodation_id = formData.accommodation_id;
      request.service_id = formData.service_id;
      request.qty = formData.qty;
      request.price = selected_service.price;
      request.service_charge = selected_service.service_charge;
      request.total_price =
        (selected_service.price +
          selected_service.price * selected_service.service_charge) *
        formData.qty;
      request.booking_id = booking.id;
      request.description = selected_service.name;
      request.order_type = "Service";
      request.status = "New";
    }
    if (process == "cancel") {
      request.status = "Cancelled";
    }

    dataProvider
      .update("orders", {
        id: props.record?.id,
        data: { ...request },
      })
      .then(({ data }) => {
        refreshBooking();
        notify("Order updated");
      })
      .catch((error) => {
        notify(error);
      });
  };

  const refreshBooking = () => {
    window.location.href = "/orders";
  };

  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <Card>
          <form>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    className={classes.textBold}
                    gutterBottom
                  >
                    Order Info
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.formGrid}>
                  <SelectInput
                    className={classes.formInput}
                    fullWidth
                    label="Booking No"
                    source="booking_id"
                    choices={availableBooking?.map(function (item) {
                      return {
                        id: item.id,
                        name: formatter.bookingNo(item.booking_no),
                      };
                    })}
                    options={{
                      InputProps: { autoComplete: "no" },
                    }}
                    validate={[required()]}
                    disabled={
                      props.record?.status == "Invoiced" ||
                      props.record?.status == "Paid"
                    }
                  />
                </Grid>
                <Grid item xs={12} className={classes.formGrid}>
                  <FormDataConsumer>
                    {({ formData, ...rest }) =>
                      formData.booking_id ? (
                        <SelectInput
                          className={classes.formInput}
                          fullWidth
                          label="Accommodation"
                          source="accommodation_id"
                          choices={availableBooking
                            ?.filter(
                              (fitem) => fitem.id === formData.booking_id
                            )[0]
                            ?.accommodations.filter(
                              (item) => item.status == "Checked_In"
                            )
                            .map((item) => {
                              return {
                                id: item.id,
                                name: item.room.name,
                              };
                            })}
                          optionText="name"
                          options={{
                            InputProps: { autoComplete: "no" },
                          }}
                          validate={[required()]}
                          disabled={
                            props.record?.status == "Invoiced" ||
                            props.record?.status == "Paid"
                          }
                        />
                      ) : null
                    }
                  </FormDataConsumer>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <SelectInput
                    className={classes.formInput}
                    fullWidth
                    label="Service"
                    source="service_id"
                    choices={availableService.map((item) => {
                      return {
                        id: item.id,
                        name: item.name,
                      };
                    })}
                    optionText="name"
                    options={{
                      InputProps: { autoComplete: "no" },
                    }}
                    validate={[required()]}
                    disabled={
                      props.record?.status == "Invoiced" ||
                      props.record?.status == "Paid"
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <NumberInput
                    className={classes.formInput}
                    fullWidth
                    label="Quantity"
                    source="qty"
                    defaultValue={1}
                    min={0}
                    validate={[required(), number(), minValue(1)]}
                    disabled={
                      props.record?.status == "Invoiced" ||
                      props.record?.status == "Paid"
                    }
                  />
                </Grid>
                <Grid item xs={6} className={classes.formGrid}>
                  <Typography gutterBottom>Price</Typography>
                </Grid>
                <FormDataConsumer>
                  {({ formData, ...rest }) => {
                    var selected_service = availableService.filter(
                      (fitem) => fitem.id === formData.service_id
                    )[0];

                    return (
                      <Grid
                        item
                        xs={6}
                        className={classes.formGrid}
                        align="right"
                      >
                        {selected_service
                          ? formatter.formatMoney(
                              selected_service.price,
                              authInfo.tenant.locale.currency_code,
                              authInfo.tenant.locale.precision
                            )
                          : "-"}
                      </Grid>
                    );
                  }}
                </FormDataConsumer>
                <Grid item xs={6} className={classes.formGrid}>
                  <Typography gutterBottom>VAT</Typography>
                </Grid>
                <FormDataConsumer>
                  {({ formData, ...rest }) => {
                    var selected_service = availableService.filter(
                      (fitem) => fitem.id === formData.service_id
                    )[0];

                    return (
                      <Grid
                        item
                        xs={6}
                        className={classes.formGrid}
                        align="right"
                      >
                        {selected_service
                          ? formatter.formatMoney(
                              selected_service.service_charge *
                                selected_service.price,
                              authInfo.tenant.locale.currency_code,
                              authInfo.tenant.locale.precision
                            )
                          : "-"}
                      </Grid>
                    );
                  }}
                </FormDataConsumer>
                <Grid item xs={6} className={classes.formGrid}>
                  <Typography gutterBottom>Total Price</Typography>
                </Grid>
                <FormDataConsumer>
                  {({ formData, ...rest }) => {
                    var selected_service = availableService.filter(
                      (fitem) => fitem.id === formData.service_id
                    )[0];

                    return (
                      <Grid
                        item
                        xs={6}
                        className={classes.formGrid}
                        align="right"
                      >
                        {selected_service && formData.qty
                          ? formatter.formatMoney(
                              (selected_service.price +
                                selected_service.service_charge *
                                  selected_service.price) *
                                formData.qty,
                              authInfo.tenant.locale.currency_code,
                              authInfo.tenant.locale.precision
                            )
                          : "-"}
                      </Grid>
                    );
                  }}
                </FormDataConsumer>
                {Object.keys(props.record).length != 0 ? (
                  <Grid item xs={6} className={classes.formGrid}>
                    <Typography gutterBottom>Status</Typography>
                  </Grid>
                ) : null}
                {Object.keys(props.record).length != 0 ? (
                  <Grid
                    item
                    xs={6}
                    className={classes.formGrid}
                    style={{ textAlign: "right" }}
                  >
                    <Chip
                      label={props.record?.e_order_status.comment}
                      style={{
                        color: "white",
                        background: orderStatus.getColor(props.record?.status),
                      }}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </CardContent>

            <Toolbar>
              <Box display="flex" justifyContent="space-between" width="100%">
                <div>
                  <FormDataConsumer>
                    {({ formData, ...rest }) =>
                      Object.keys(props.record).length == 0 ? (
                        <SaveButton
                          {...formProps}
                          variant="contained"
                          color="primary"
                          className={classes.formInput}
                          onSave={() => addOrder(formData)}
                        />
                      ) : null
                    }
                  </FormDataConsumer>
                  <FormDataConsumer>
                    {({ formData, ...rest }) =>
                      props.record?.status == "New" ? (
                        <SaveButton
                          {...formProps}
                          variant="contained"
                          color="primary"
                          label="Update"
                          className={classes.formInput}
                          onSave={() => updateOrder("update", formData)}
                        />
                      ) : null
                    }
                  </FormDataConsumer>
                </div>
                <FormDataConsumer>
                  {({ formData, ...rest }) =>
                    props.record?.status == "New" ? (
                      <SaveButton
                        {...formProps}
                        style={{ background: "red" }}
                        variant="contained"
                        color="primary"
                        label="Cancel"
                        icon={<ClearIcon />}
                        className={classes.formInput}
                        onSave={() => updateOrder("cancel", formData)}
                      />
                    ) : null
                  }
                </FormDataConsumer>
              </Box>
            </Toolbar>
          </form>
        </Card>
      )}
    />
  );
};

export default OrderForm;
