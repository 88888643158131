export const roomCleanStatus = {
  getColor,
};

function getColor(status) {
  switch (status) {
    case "Clean":
      return "darkgreen";
    case "Dirty":
      return "darkred";
    default:
      return "white";
  }
}
