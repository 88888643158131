import React, { useEffect, useState } from "react";
import { Title } from "react-admin";
import { Field, Form } from 'react-final-form';

import { useTranslate, useNotify, useSafeSetState } from 'ra-core';

import {
    Button,
    Typography,
    CardActions,
    CircularProgress,
    TextField,
} from '@material-ui/core';

import Grid from "@material-ui/core/Grid";
import { Card, CardContent } from "@material-ui/core";
import { makeStyles, Theme } from '@material-ui/core/styles';

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import icConfirm from "../../images/ico_confirm.png";

import { authApi } from "../../api/authApi";
import { tenantGroupApi } from "../../api/tenantGroupApi";


const useStyles = makeStyles(
  (theme) => ({
      form: {
          padding: '0 1em 1em 1em',
      },
      input: {
          marginTop: '1em',
      },
      button: {
          width: '100%',
      },
      icon: {
          marginRight: theme.spacing(1),
      },
      textBold: {
        fontWeight: "bold",
      },
      card: {
        minWidth: 350,
        paddingTop: 40,
        paddingBottom: 40,
        paddingLeft: 20,
        paddingRight: 20,
        borderRadius: 5
      },
  }),
  { name: 'RaLoginForm' }
);

const Input = ({
  meta: { touched, error },
  input: inputProps,
  ...props
}) => (
  <TextField
      error={!!(touched && error)}
      helperText={touched && error}
      {...inputProps}
      {...props}
      fullWidth
  />
);

const ConnectRideum = () => {
	const authInfo = JSON.parse(localStorage.getItem("auth"));
  const [loading, setLoading] = useSafeSetState(false);
	const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();
  const [openDisconnect, setOpenDisconnect] = useState(false);
  const [isConnected, setIsConnected] = useState(false);

  useEffect(async () => {
    const merchant = await tenantGroupApi.getRideumMerchantAsync(authInfo.tenant_group_id);
    if (merchant.errors) notify(merchant.errors);
    setIsConnected(merchant.data && merchant.data.id);
  }, []);
  
  const validate = (values) => {
    const errors = { username: undefined, password: undefined };

    if (!values.username) {
        errors.username = translate('ra.validation.required');
    }
    if (!values.password) {
        errors.password = translate('ra.validation.required');
    }
    return errors;
  };

  const submit = async values => {
    setLoading(true);

    try {
      const merchant = await authApi.loginRideumMerchantAsync(values.username, values.password);
      if (merchant.errors) throw new Error(merchant.errors[0].message);
        
      if (merchant.data && merchant.data.id) {
        const updateMerchant = await tenantGroupApi.updateRideumMerchantAsync(authInfo.tenant_group_id, merchant.data.id, merchant.data.name);
        if (updateMerchant.errors) throw new Error(merchant.errors[0].message);

        setLoading(false);
        setIsConnected(true);
      }
    } catch (err) {
      setLoading(false);
      notify(err.message);
    }
  };

  const handleOpenDisconnect = () => setOpenDisconnect(true);
  const handleCloseDisconnect = () => setOpenDisconnect(false);

  const handleConfirmDisconnect = async () => {
    const merchant = await tenantGroupApi.removeRideumMerchantAsync(authInfo.tenant_group_id);
    if (merchant.errors) notify(merchant.errors[0].message);

    setOpenDisconnect(false);
    setIsConnected(false);
    notify("Bleustay is disconnected");
  };

	return (
		<div>
			<Title title={translate("ra.label.connect_rideum")} />
			<Card className={classes.card}>
        <CardContent>
          <Grid item xs={12}>
						<Typography variant="h6" className={classes.textBold} gutterBottom>
              {translate("ra.label.connect_rideum")}
						</Typography>
          </Grid>
          <Grid item xs={12}>
          {
            isConnected ?
            <div>
              <center>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <div style={{flexBasis: '6%'}}>
                    {/* <img src={icConfirm} style={{ width: 27, height: 27, marginLeft:0, marginRight: 15 }} /> */}
                    <LinkIcon fontSize="large" />
                  </div>
                  <div>
                    You have connected to Bleustay Service
                  </div>
                </div>
                <br />
                <Button
                  style={{ color: "red" }}
                  variant="outline"
                  color="primary"
                  startIcon={<LinkOffIcon />}
                  onClick={handleOpenDisconnect}
                >
                  Disconnect
                </Button>
                <Dialog
                  open={openDisconnect}
                  onClose={handleCloseDisconnect}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Confirm Disconnect?"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Please confirm to disconnect
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => handleCloseDisconnect()} color="primary">
                      Cancel
                    </Button>
                    <Button
                      onClick={() => handleConfirmDisconnect()}
                      color="primary"
                      autoFocus
                    >
                      Confirm
                    </Button>
                  </DialogActions>
                </Dialog>
              </center>
            </div> :
            <Form
              onSubmit={submit}
              validate={validate}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <div className={classes.form}>
                    <div className={classes.input}>
                      <Field
                        id="username"
                        name="username"
                        component={Input}
                        label={translate('ra.auth.username')}
                        disabled={loading}
                        variant="outlined"
                      />
                    </div>
                    <div className={classes.input}>
                      <Field
                        id="password"
                        name="password"
                        component={Input}
                        label={translate('ra.auth.password')}
                        type="password"
                        disabled={loading}
                        autoComplete="current-password"
                        variant="outlined"
                      />
                    </div>
                  </div>
                  <CardActions>
                    <Button
                      style={{ height: 40 }}
                      variant="contained"
                      type="submit"
                      color="primary"
                      disabled={loading}
                      className={classes.button}
                    >
                      {loading && (
                        <CircularProgress
                          className={classes.icon}
                          size={18}
                          thickness={2}
                        />
                      )}
                      {translate('ra.label.connect')}
                    </Button>
                  </CardActions>
                </form>
              )}
            />
          }
          </Grid>
        </CardContent>
      </Card>
		</div>
	);
}

export default ConnectRideum;