import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import MealsList from './MealsList';
import MealsCreate from './MealsCreate';
import MealsEdit from './MealsEdit';

export default {
    list: MealsList,
    create: MealsCreate,
    edit: MealsEdit,
    icon: MeetingRoomIcon,
};