import * as React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import {
    Button,
    CardActions,
    CircularProgress,
    TextField,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslate, useLogin, useNotify, useSafeSetState } from 'ra-core';
import { DataProviderContext } from "react-admin";
import { useContext, useEffect } from "react";
import { bleudineMerchantApi } from "../../api/bleudineMerchantApi";
import { bleudineAuthApi } from "../../api/bleudineAuthApi";
import { userApi } from "../../api/userApi";

const useStyles = makeStyles(
    (theme) => ({
        form: {
            padding: '0 1em 1em 1em',
        },
        input: {
            marginTop: '1em',
        },
        button: {
            width: '100%',
        },
        icon: {
            marginRight: theme.spacing(1),
        },
    }),
    { name: 'RaLoginForm' }
);

const Input = ({
    meta: { touched, error }, // eslint-disable-line react/prop-types
    input: inputProps, // eslint-disable-line react/prop-types
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);


const CustomLogin = (props) => {
    const { redirectTo } = props;
    const [loading, setLoading] = useSafeSetState(false);
    const login = useLogin();
    const translate = useTranslate();
    const notify = useNotify();
    const classes = useStyles(props);
    const dataProvider = useContext(DataProviderContext);


    const validate = (values) => {
        const errors = { username: undefined, password: undefined };

        if (!values.username) {
            errors.username = translate('ra.validation.required');
        }
        if (!values.password) {
            errors.password = translate('ra.validation.required');
        }
        return errors;
    };

    // const submit = values => {
    //     setLoading(true);
    //     authProvider.login(values).then(ret => {
    //         dataProvider
    //             .getOne("users", { id: ret.user.uid })
    //             .then(({ data }) => {
    //                 getMerchantById(data.tenant.mapped_id,data)
    //                 .then(({ data }) => {
    //                     setLoading(false);
    //                 })
    //             })
    //             .catch((error) => {
    //                 notify(error);
    //             });

    //     }).catch(error => {
    //         setLoading(false);
    //         notify(
    //             typeof error === 'string'
    //                 ? error
    //                 : typeof error === 'undefined' || !error.message
    //                     ? 'ra.auth.sign_in_error'
    //                     : error.message,
    //             'warning',
    //             {
    //                 _:
    //                     typeof error === 'string'
    //                         ? error
    //                         : error && error.message
    //                             ? error.message
    //                             : undefined,
    //             }
    //         );
    //     });
    // };

    const submit = values => {
        const login = {
            emailOrPhone: values.username,
            password: values.password,
        }

        setLoading(true);
        bleudineAuthApi.login(login, 8).then(ret => {
            localStorage.setItem("bleudine-user", JSON.stringify(ret));
            localStorage.setItem("token", ret.token);
            userApi.getUser(ret.id)
                .then(({ data }) => {
                    bleudineMerchantApi.getById(data.users[0].tenant_group.mapped_id).then(
                        (merchant) => {
                            if (window.$chatwoot) {
                                window.$chatwoot.reset()
                            }
                            data.users[0].merchantCore = merchant
                            localStorage.setItem('auth', JSON.stringify(data.users[0]));
                            window.location.href = "/"
                        },
                        (error) => {
                            notify(error);
                        }
                    );

                    // data.users[0].merchantCore = null

                })
                .catch((error) => {
                    notify(error);
                });

        }).catch(error => {
            setLoading(false);
            notify(
                typeof error === 'string'
                    ? error
                    : typeof error === 'undefined' || !error.message
                        ? 'ra.auth.sign_in_error'
                        : error.message,
                'warning',
                {
                    _:
                        typeof error === 'string'
                            ? error
                            : error && error.message
                                ? error.message
                                : undefined,
                }
            );
        });
    };

    // const getMerchantById = (merchantId,data) => {
    //     bleudineMerchantApi.getById(merchantId).then(
    //       (merchant) => {
    //         data.users[0].merchantCore = merchant
    //         localStorage.setItem('auth', JSON.stringify(data.users[0]));
    //         window.location.href = "/"
    //       },
    //       (error) => {
    //         notify(error);
    //       }
    //     );
    //   };

    return (
        <Form
            onSubmit={submit}
            validate={validate}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <div className={classes.form}>
                        <div className={classes.input}>
                            <Field
                                id="username"
                                name="username"
                                component={Input}
                                label={translate('ra.auth.username')}
                                disabled={loading}
                                variant="outlined"
                            />
                        </div>
                        <div className={classes.input}>
                            <Field
                                id="password"
                                name="password"
                                component={Input}
                                label={translate('ra.auth.password')}
                                type="password"
                                disabled={loading}
                                autoComplete="current-password"
                                variant="outlined"
                            />
                        </div>
                    </div>
                    <CardActions>
                        <Button
                            style={{ height: 40 }}
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={loading}
                            className={classes.button}
                        >
                            {loading && (
                                <CircularProgress
                                    className={classes.icon}
                                    size={18}
                                    thickness={2}
                                />
                            )}
                            {translate('ra.auth.sign_in')}
                        </Button>
                    </CardActions>
                </form>
            )}
        />
    );
};

export default CustomLogin;
