import React, { useState, useEffect } from "react";
import { Admin, Resource } from "react-admin";
import buildHasuraProvider from "ra-data-hasura";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import {authProvider} from "./utils/authProvider"
import { createBrowserHistory as createHistory } from "history";
import themeReducer from "./themeReducer";
import customRoutes from "./routes";
import polyglotI18nProvider from "ra-i18n-polyglot";
import { englishMessages } from "./i18n/ra-language-english";
import { indonesianMessages } from "./i18n/ra-language-indonesian";
import { Login, Layout } from "./resources/layout";
import Dashboard from "./resources/dashboard/Dashboard";
import guests from "./resources/guest";
import companies from "./resources/company";
import accommodations from "./resources/accommodation";
import deposits from "./resources/deposit";
import refunds from "./resources/refund";
import orders from "./resources/order";
import invoices from "./resources/invoice";
import roomTypes from "./resources/roomType";
import roomRates from "./resources/roomRate";
import rooms from "./resources/room";
import cancel from "./resources/cancellation_policies"
import meals from "./resources/meals";
import bookings from "./resources/booking";
import maintenances from "./resources/maintenance";
import roomServices from "./resources/roomServices";
import cmRoomRate from "./resources/cm_room_rate_type";
import cmRoomType from "./resources/cm_room_type";
import cmAccount from "./resources/cm_account";
import cm2FASessions from "./resources/cm_2fa_sessions";
import user from "./resources/user";
import tenants from "./resources/tenant";
import "./App.css";
import customBuildFields from "./customBuildFields";
import { apiConfig } from "./api/apiConfig";
import CircularProgress from "@material-ui/core/CircularProgress";
import icLogo from "./images/ic_logo_text.png";

const App = () => {
  const history = createHistory();
  const messages = { id: indonesianMessages, en: englishMessages };

  const [dataProvider, setDataProvider] = useState(null);
  const i18nProvider = polyglotI18nProvider((locale) => messages[locale]);

  useEffect(() => {
    const authInfo = JSON.parse(localStorage.getItem("auth"));

    if (authInfo == null && window.location.pathname !== "/login") {
      window.location.href = "/login";
    }

    if(authInfo != null && window.$chatwoot)
    {
      window.$chatwoot.setUser(authInfo.id, {
        email: authInfo.email,
        name: authInfo.first_name + " " + authInfo.last_name,
        company_name: authInfo.tenant_group.name,
        country_code: authInfo.tenant_group.country,
      });
    }

    const checkAuth = () => {
      const tokenResponse = localStorage.getItem("token");
      initHasuraAuth(tokenResponse);
      // authProvider
      //   .getJWTToken()
      //   .then((tokenResponse) => {
      //     localStorage.setItem("token", tokenResponse);
      //     initHasuraAuth(tokenResponse);
      //   })
      //   .catch((error) => {
      //     initHasuraAuth();
      //   });
    };

    const initHasuraAuth = (token) => {
      const hasuraAuth = new ApolloClient({
        uri: apiConfig.hasuraUrl,
        cache: new InMemoryCache(),
        headers: token
          ? {
            "x-hasura-admin-secret": apiConfig.hasuraSecret,
          }
          : {},
        // headers: token
        //   ? {
        //       Authorization: `Bearer ${token}`,
        //     }
        //   : {},
      });

      const buildDataProvider = async () => {
        const dataProvider = await buildHasuraProvider(
          { client: hasuraAuth },
          { buildFields: customBuildFields }
        );
        setDataProvider(() => dataProvider);
      };

      buildDataProvider();
    };

    checkAuth();
  }, []);

  if (!dataProvider)
    return (
      <div
        style={{ position: "absolute", marginLeft: "50vw", marginTop: "50vh" }}
      >
        <img
          src={icLogo}
          style={{
            marginTop: -100,
            width: 200,
            display: "block",
            marginBottom: 50,
            marginLeft: -75,
          }}
        />
        <CircularProgress />
        <div style={{ marginTop: 10, marginLeft: -10, fontWeight: "bold" }}>
          Loading
        </div>
      </div>
    );

  return (
    <Admin
      authProvider={authProvider}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
      layout={Layout}
      customReducers={{ theme: themeReducer }}
      customRoutes={customRoutes}
      history={history}
      dashboard={Dashboard}
      loginPage={Login}
     // logoutButton={Logout}
    >
      <Resource
        name="guests"
        options={{ label: "ra.label.guests" }}
        list={guests.list}
        create={guests.create}
        edit={guests.edit}
      />
      <Resource
        name="companies"
        options={{ label: "ra.label.companies" }}
        list={companies.list}
        create={companies.create}
        edit={companies.edit}
      />
      <Resource
        name="accommodations"
        options={{ label: "ra.label.accommodations" }}
        list={accommodations.list}
        create={accommodations.create}
        edit={accommodations.edit}
      />
      <Resource
        name="deposits"
        options={{ label: "ra.label.deposits" }}
        list={deposits.list}
        create={deposits.create}
        edit={deposits.edit}
      />
      <Resource
        name="refunds"
        options={{ label: "ra.label.refunds" }}
        list={refunds.list}
        create={refunds.create}
        edit={refunds.edit}
      />
      <Resource
        name="invoices"
        options={{ label: "ra.label.invoices" }}
        list={invoices.list}
        create={invoices.create}
        edit={invoices.edit}
      />
      <Resource
        name="orders"
        options={{ label: "ra.label.orders" }}
        list={orders.list}
        create={orders.create}
        edit={orders.edit}
      />
      <Resource
        name="room_types"
        options={{ label: "ra.label.room_types" }}
        list={roomTypes.list}
        create={roomTypes.create}
        edit={roomTypes.edit}
      />
      <Resource
        name="room_rates"
        options={{ label: "ra.label.room_rates" }}
        list={roomRates.list}
        create={roomRates.create}
        edit={roomRates.edit}
      />
      <Resource
        name="rooms"
        options={{ label: "ra.label.rooms" }}
        list={rooms.list}
        create={rooms.create}
        edit={rooms.edit}
      />
      <Resource
        name="bookings"
        options={{ label: "ra.label.bookings" }}
        list={bookings.list}
        create={bookings.create}
        edit={bookings.edit}
      />
      <Resource
        name="maintenances"
        options={{ label: "ra.label.maintenances" }}
        list={maintenances.list}
        create={maintenances.create}
        edit={maintenances.edit}
      />

      <Resource
        name="services"
        options={{ label: "ra.label.room_services" }}
        list={roomServices.list}
        create={roomServices.create}
        edit={roomServices.edit}
      />

      <Resource
        name="cm_accounts"
        options={{ label: "ra.label.accounts" }}
        list={cmAccount.list}
        create={cmAccount.create}
        edit={cmAccount.edit}
      />

      <Resource
        name="cm_room_type_maps"
        options={{ label: "ra.label.room_types" }}
        list={cmRoomType.list}
        create={cmRoomType.create}
        edit={cmRoomType.edit}
      />
      <Resource
        name="cm_room_type_rate_maps"
        options={{ label: "ra.label.room_rates" }}
        list={cmRoomRate.list}
        create={cmRoomRate.create}
        edit={cmRoomRate.edit}
      />

      <Resource
        name="cm_2fa_sessions"
        options={{ label: "ra.label.fASessions" }}
        list={cm2FASessions.list}
        // create={cm2FASessions.create}
        edit={cm2FASessions.edit}
      />
      <Resource name="cm_clients" />
      <Resource name="cm_client_status" />

      <Resource
        name="users"
        options={{ label: "ra.label.users" }}
        list={user.list}
        create={user.create}
        edit={user.edit}
      />
      <Resource
        name="tenants"
        options={{ label: "ra.label.property" }}
        list={tenants.list}
        create={tenants.create}
        edit={tenants.edit}
      />
      <Resource name="cancellation_policies"
          options={{ label: "cancel policy" }}
          list={cancel.list}
          create={cancel.create}
          edit={cancel.edit}
      />
      <Resource name="e_countries" />
      <Resource name="e_nationalities" />
      <Resource name="e_genders" />
      <Resource name="e_titles" />
      <Resource name="e_document_types" />
      <Resource name="e_customer_types" />
      <Resource name="e_booking_status" />
      <Resource name="e_booking_sources" />
      <Resource name="e_room_types" />
      <Resource name="e_bed_types" />
      <Resource name="e_mattress_types" />
      <Resource name="e_room_facilities" />
      <Resource name="e_bathroom_facilities" />
      <Resource name="e_view_facilities" />
      <Resource name="e_bedroom_facilities" />
      <Resource name="e_entertainment_facilities" />
      <Resource name="e_kitchen_facilities" />
      <Resource name="e_outdoor_facilities" />
      <Resource name="e_floor_facilities" />
      <Resource name="e_room_status" />
      <Resource name="e_accommodation_status" />
      <Resource name="e_order_status" />
      <Resource name="e_invoice_status" />
      <Resource name="room_type_rates" />
      <Resource name="payments" />
      <Resource name="e_payment_types" />
      <Resource name="e_payment_status" />
      <Resource name="e_deposit_types" />
      <Resource name="e_deposit_status" />
      <Resource name="e_refund_types" />
      <Resource name="e_refund_status" />
      <Resource name="e_room_type_status" />
      <Resource name="e_room_rate_status" />
      <Resource name="e_guest_status" />
      <Resource name="e_company_status" />
      <Resource name="e_tenant_status" />
      <Resource name="e_user_roles" />
      <Resource name="e_user_status" />
      <Resource name="locales" />
      <Resource name="e_priority_levels" />
    </Admin>
  );
};

export default App;

