import moment from 'moment'
import React, { useState, useEffect, useContext } from 'react'
import {
    DataProviderContext,
    Title,
    useTranslate,
} from "react-admin";
import { Grid, Button, Table, TableCell, Typography, TableRow, TableBody, TableHead, TableContainer, Paper, TextField } from '@material-ui/core';
import { formatter } from '../../utils/formatter';
import { reportApi } from '../../api/reportApi';
import { allAccommodationAsOfDate, getMaintenanceByRoomIds } from '../../api/dashboardApi';
import DownloadIcon from '@material-ui/icons/GetApp'
import * as XLSX from 'xlsx';

export default function EndofDayReport() {
    const translate = useTranslate();
    const dataProvider = useContext(DataProviderContext);

    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const authInfo = JSON.parse(localStorage.getItem("auth"));
    const [totalSalesRevenue, setTotalSalesRevenue] = useState(0)
    const [paymentType, setPaymentType] = useState([])
    const [payments, setPayments] = useState([])
    const [refunds, setRefunds] = useState([])
    const [todayOccupancyStatistic, setTodayOccupancyStatistic] = useState([])
    const [tomorrowOccupancyStatistic, setTomorrowOccupancyStatistic] = useState([])

    const exporter = () => {
        const first_data = [
            [`This is end of day report generated on ${moment(startDate).format("YYYY-MM-DD")}`],
        ]
        const revenue_data = [
            ["option", "total"],
            ["Accommodation and Service Sales", totalSalesRevenue]
        ]
        const payment_data = paymentType.filter((type) => type.id != 'Deposit').map(type => {
            return {
                payment_method: type.comment,
                cash_in: formatter.formatMoney(
                    payments
                        .filter((p) => p.payment_type == type.id)
                        .reduce((sum, p) => sum + p.amount, 0),
                    authInfo.tenant.locale.currency_code,
                    authInfo.tenant.locale.precision
                ),
                cash_out: formatter.formatMoney(
                    refunds
                        .filter((p) => p.type == type.id && p.deposit_id == null)
                        .reduce((sum, p) => sum + p.amount, 0),
                    authInfo.tenant.locale.currency_code,
                    authInfo.tenant.locale.precision
                ),
            }
        });

        const occupancy_data = [
            ["option", "Today", "tomorrow"],
            ["Occupied Rooms", todayOccupiedRooms, tomorrowOccupiedRooms],
            ["Available Rooms", todayAvalaibleRooms, tomorrowAvalaibleRooms],
            ["Occupancy Rooms %", todayOccupanyPerc, tomorrowOccupanyPerc]
        ]

        const revenue_sheet = XLSX.utils.aoa_to_sheet(first_data)
        const payment_sheet = XLSX.utils.aoa_to_sheet(first_data)
        const occupancy_sheet = XLSX.utils.aoa_to_sheet(first_data)

        /* generate workbook and add the worksheet */
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, revenue_sheet, 'Revenue');
        XLSX.utils.sheet_add_aoa(wb.Sheets.Revenue, revenue_data, { origin: "A3" })

        XLSX.utils.book_append_sheet(wb, payment_sheet, 'Payments');
        XLSX.utils.sheet_add_json(wb.Sheets.Payments, payment_data, { origin: "A3" })

        XLSX.utils.book_append_sheet(wb, occupancy_sheet, 'Occupancy_Statistic');
        XLSX.utils.sheet_add_aoa(wb.Sheets.Occupancy_Statistic, occupancy_data, { origin: "A3" })


        /* save to file */
        XLSX.writeFile(wb, `End_of_day_${moment(startDate).format("YYYY-MM-DD")}.xlsx`);
        // jsonExport(
        //     forExport,
        //     {
        //         headers: [
        //             "payment_method",
        //             "cash_in",
        //             "cash_out",
        //         ],
        //     },
        //     (err, csv) => {
        //         downloadCSV(csv, "End_Of_Day");
        //     }
        // );
    };

    useEffect(() => {
        endDate.setDate(startDate.getDate() + 1);
        reportApi.getPaymentType()
            .then(({ data }) => {
                setPaymentType(data.e_payment_types)
                getPaymentByDate()
                getRefundByDate()
                getRoomByDate()
            })

    }, []);

    const getPaymentByDate = () => {
        reportApi.getPaymentByDate(authInfo.tenant_id
            , moment(startDate).format('YYYY-MM-DD'),
            moment(endDate).format('YYYY-MM-DD'))
            .then(({ data }) => {

                let summaryAmount = data.payments
                    .reduce((sum, data) => sum + data.amount, 0);

                setTotalSalesRevenue(formatter.formatMoney(
                    summaryAmount,
                    authInfo.tenant.locale.currency_code,
                    authInfo.tenant.locale.precision
                ))

                setPayments(data.payments)
            })
    }

    const getRefundByDate = () => {
        reportApi.getRefundByDate(authInfo.tenant_id
            , moment(startDate).format('YYYY-MM-DD'),
            moment(endDate).format('YYYY-MM-DD'))
            .then(({ data }) => {
                setRefunds(data.refunds)
            })
    }

    const getRoomByDate = () => {
        dataProvider.getList("room_types", { filter: { tenant_id: authInfo.tenant.id } }).then(({ data }) => {
            const room_types = data.map(room_type => ({ name: room_type.name, id: room_type.id }));
            dataProvider.getList("rooms", { filter: { tenant_id: authInfo.tenant.id, status: 'Active' } })
                .then(res => {
                    const rooms = res.data;
                    getMaintenanceByRoomIds(rooms.map(r => r.id)).then(({ data }) => {
                        const { maintenances } = data
                        allAccommodationAsOfDate(moment(startDate).format('YYYY-MM-DD')).then(({ data }) => {
                            const { accommodations } = data;
                            const todayOccupancyStatistic = room_types.map((room_type) => {
                                let today = moment(startDate)
                                const validOoo = (m) => {

                                    return m.room.room_type.id == room_type.id && (!m.start_date || moment(m.start_date).isSameOrBefore(today, 'day')) && (!m.end_date || moment(m.end_date).isSameOrAfter(today, 'day'))
                                }
                                const outOfOrder = maintenances.filter(validOoo).length
                                const sold = accommodations.filter(ac => ac.room_type.id == room_type.id).length
                                const total = rooms.filter(r => r.room_type.id == room_type.id).length
                                const avail = total - outOfOrder - sold
                                return { name: room_type.name, outOfOrder, sold, available: avail, total }
                            })
                            setTodayOccupancyStatistic(todayOccupancyStatistic)
                        })

                        allAccommodationAsOfDate(moment(endDate).format('YYYY-MM-DD')).then(({ data }) => {
                            const { accommodations } = data;
                            const tomorrowOccupancyStatistic = room_types.map((room_type) => {
                                let today = moment(endDate)
                                const validOoo = (m) => {

                                    return m.room.room_type.id == room_type.id && (!m.start_date || moment(m.start_date).isSameOrBefore(today, 'day')) && (!m.end_date || moment(m.end_date).isSameOrAfter(today, 'day'))
                                }
                                const outOfOrder = maintenances.filter(validOoo).length
                                const sold = accommodations.filter(ac => ac.room_type.id == room_type.id).length
                                const total = rooms.filter(r => r.room_type.id == room_type.id).length
                                const avail = total - outOfOrder - sold
                                return { name: room_type.name, outOfOrder, sold, available: avail, total }
                            })
                            setTomorrowOccupancyStatistic(tomorrowOccupancyStatistic)
                        })
                    })
                })
        });
    }

    function handleStartDate(start_date) {
        let end_date = new Date(start_date)
        end_date.setDate(start_date.getDate() + 1);

        setStartDate(start_date)
        setEndDate(end_date)
    }

    function generateReport() {
        getPaymentByDate()
        getRefundByDate()
        getRoomByDate()
    }

    let todayOccupiedRooms = todayOccupancyStatistic
        .reduce((sum, o) => sum + o.sold, 0)
    let todayAvalaibleRooms = todayOccupancyStatistic
        .reduce((sum, o) => sum + o.available, 0)

    let todayOccupanyPerc = todayOccupiedRooms / todayAvalaibleRooms * 100

    let tomorrowOccupiedRooms = tomorrowOccupancyStatistic
        .reduce((sum, o) => sum + o.sold, 0)
    let tomorrowAvalaibleRooms = tomorrowOccupancyStatistic
        .reduce((sum, o) => sum + o.available, 0)

    let tomorrowOccupanyPerc = tomorrowOccupiedRooms / tomorrowAvalaibleRooms * 100

    return (
        <div style={{ padding: '1rem' }}>
            <Title title={translate(`ra.label.end_of_day_report`, { smart_count: 2 })}></Title>

            <Grid container direction="column">
                <Grid container direction="row" alignItems='center' spacing={2}>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            style={{
                                marginTop: 12,
                            }}
                            type="date"
                            variant="filled"
                            value={moment(startDate).format('YYYY-MM-DD')}
                            onChange={(e) => handleStartDate(e.target.valueAsDate)}
                            fullWidth
                            label="Date"
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Button
                            style={{
                                marginTop: 12,
                                marginRight: 5,
                            }}
                            onClick={generateReport}
                            variant="contained"
                            color="primary"
                        >
                            Search
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={3} style={{ marginLeft: 'auto', textAlignLast: 'end' }}>
                        <Button
                            style={{
                                marginTop: 12,
                                marginRight: 5,
                            }}
                            onClick={exporter}
                            variant="text"
                            color="primary"
                            endIcon={<DownloadIcon />}
                        >
                            Export
                        </Button>
                    </Grid>
                </Grid>
                <br />
                <Grid item>
                    <Typography variant='h6' style={{ fontWeight: 'bold' }}>Revenue</Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Option</TableCell>
                                    <TableCell>Total</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Accommodation and Service Sales</TableCell>
                                    <TableCell>{totalSalesRevenue}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <br />
                <Grid item>
                    <Typography variant='h6' style={{ fontWeight: 'bold' }}>Payments</Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Payment method</TableCell>
                                    <TableCell> Cash In</TableCell>
                                    <TableCell> Cash Out</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paymentType.filter((type) => type.id != 'Deposit').map(type => <TableRow key={type.id}>
                                    <TableCell>{type.comment}</TableCell>
                                    <TableCell>{formatter.formatMoney(
                                        payments
                                            .filter((p) => p.payment_type == type.id)
                                            .reduce((sum, p) => sum + p.amount, 0),
                                        authInfo.tenant.locale.currency_code,
                                        authInfo.tenant.locale.precision
                                    )}</TableCell>
                                    <TableCell>{formatter.formatMoney(
                                        refunds
                                            .filter((p) => p.type == type.id && p.deposit_id == null)
                                            .reduce((sum, p) => sum + p.amount, 0),
                                        authInfo.tenant.locale.currency_code,
                                        authInfo.tenant.locale.precision
                                    )}</TableCell>
                                </TableRow>)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <br />
                <Grid item>
                    <Typography variant='h6' style={{ fontWeight: 'bold' }}>Occupancy Statistic</Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Option</TableCell>
                                    <TableCell>Today</TableCell>
                                    <TableCell>Tomorrow</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Occupied Rooms</TableCell>
                                    <TableCell>{todayOccupiedRooms}</TableCell>
                                    <TableCell>{tomorrowOccupiedRooms}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Available Rooms</TableCell>
                                    <TableCell>{todayAvalaibleRooms}</TableCell>
                                    <TableCell>{tomorrowAvalaibleRooms}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Occupancy %</TableCell>
                                    <TableCell>{formatter.formatMoneyNumber(!isNaN(todayOccupanyPerc) ? todayOccupanyPerc : 0)}</TableCell>
                                    <TableCell>{formatter.formatMoneyNumber(!isNaN(tomorrowOccupanyPerc) ? tomorrowOccupanyPerc : 0)}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </div>
    )
}
