import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import RoomRateList from './RoomRateList';
import RoomRateCreate from './RoomRateCreate';
import RoomRateEdit from './RoomRateEdit';

export default {
    list: RoomRateList,
    create: RoomRateCreate,
    edit: RoomRateEdit,
    icon: LocalOfferIcon,
};