import gql from 'graphql-tag';

export const GET_LIST_COMPANIES = gql`
{
  id
  address_1
  email
  country
  e_country {
    comment
    id
  }
  status
  e_company_status {
    id
    comment
  }
  name
  phone_no
  region
  updated_at
  zip_code
  address_2
  city
  comment
  created_at
}
`;
