import { apiConfig } from "./apiConfig";
import { handleResponse, handleError } from "./apiHelper";
import moment from "moment";


function query(gql) {
    const url = apiConfig.hasuraUrl;
    const requestOptions = {
        method: "POST",
        headers: {
            //Authorization: `Bearer ${apiConfig.getRideumToken()}`,
            "x-hasura-admin-secret": apiConfig.hasuraSecret,
            "Content-Type": "application/json-patch+json",
        },
        body: JSON.stringify(gql),
    };

    return fetch(url, requestOptions)
        .then(handleResponse, handleError)
        .then((data) => {
            return data;
        });

}

export function allBookingStatus() {
    const gql = {
        query: `query {
           e_booking_status{
                id
               comment
           }
        }`
    }
    return query(gql)
}

export function allBookingsAsOfDate(tenant_id,date) {
    const gql = {
        query: `query {
            bookings(where:{tenant_id: {_eq: "${tenant_id}"},check_in_date: {_lte: "${date}"}, check_out_date: {_gte: "${date}"}}){
                id
                booking_no
                check_in_date
                check_out_date
                status
                customer_type
                guest{
                    first_name
                    last_name
                    phone_no
                    email
                }
                company {
                    name
                    phone_no
                    email
                }
            }
        }`
    }
    return query(gql)
}

export function allAccommodationAsOfDate(date) {
    const gql = {
        query: `query {
            accommodations(where:{status: {_in:[New,Checked_In,Confirmed]}, check_in_date: {_lte: "${date}"}, check_out_date: {_gte: "${date}"}}){
                room_type {
                    id
                    name
                }
            }

        }`
    }
    return query(gql);
}


export async function guestSummary(date,tenant_id) {
    const arriving_filter = (booking) => booking.status == 'New' && moment(new Date()).isSame(moment(booking.check_in_date), 'day');
    const staying_filter = (booking) => !['Checked_Out', 'New'].includes(booking.status);
    const departing_filter = (booking) => moment(booking.check_out_date).isSame(moment(new Date()), 'day');
    const response = await allBookingsAsOfDate(tenant_id,date.toISOString().substring(0, 10))
    const { bookings } = response.data
    const mapper = booking => ({
        id: booking.id,
        booking_no:booking.booking_no,
        customer_type: booking.customer_type,
        name: booking.customer_type == 'Individual' ? `${booking.guest.first_name} ${booking.guest.last_name}` : booking.company.name,
        email: booking.customer_type == 'Individual' ? booking.guest.email : booking.company.email,
        phone_no: booking.customer_type == 'Individual' ? booking.guest.phone_no : booking.company.phone_no
    })
    return {
        arriving: bookings.filter(arriving_filter).map(mapper),
        staying: bookings.filter(staying_filter).map(mapper),
        departing: bookings.filter(departing_filter).map(mapper)
    }


}

export async function getMaintenanceByRoomIds(roomIds) {
    const gql = {
        query: `query {
            maintenances(where:{room_id: {_in: ${JSON.stringify(roomIds)}}}){
                id
                start_date
                end_date
                room {
                    room_type{
                        id
                        name
                    }
                }
            }
        }`
    }

    return query(gql)
}

export async function occupancyStatistics(startDate, endDate, tenantId) {
    const actualStart = moment(startDate).add(-1, 'day').toDate()
    let projectingRange = []
    let numOfDay = moment(endDate).diff(moment(startDate), 'day')
    for (let i = 0; i <= numOfDay; i++) {
        projectingRange.push(moment(startDate).add(i, 'day'))
    }

    const allAccommodationsQuery = {
        query: `query {
            accommodations(where:
                {
                    status: {_in:[Checked_In, New,Confirmed]},
                    tenant_id:{_eq:"${tenantId}"},
                    check_in_date: {_gte: "${actualStart.toISOString().substring(0, 10)}"},
                    check_out_date: { _lte: "${endDate.toISOString().substring(0, 10)}"}
                }
                ){
                id
                check_in_date
                check_out_date
            }
        }`
    }
    let accommodations = (await query(allAccommodationsQuery)).data.accommodations;

    const allRoomsQuery = {
        query: `query {rooms(where: {tenant_id: {_eq:"${tenantId}"}}){
        id
    }}`
    }

    const rooms = (await query(allRoomsQuery)).data.rooms;
    const maintenances = (await getMaintenanceByRoomIds(rooms.map(r => r.id))).data.maintenances;
    const computeStaying = (momentDate) => {
        return accommodations.filter(ac => !['Checked_Out', 'Cancelled'].includes(ac.status) && momentDate.isSameOrAfter(ac.check_in_date) && momentDate.isBefore(ac.check_out_date)).length
    }

    const computeArrival = (momentDate) => {
        return accommodations.filter(ac => momentDate.isSame(moment(ac.check_in_date), 'day')).length;
    }

    const computeDepature = (momentDate) => {
        return accommodations.filter(ac => momentDate.isSame(moment(ac.check_out_date), 'day')).length;
    }

    const computeAvailableRoom = (momentDate) => {
        return rooms.length - maintenances.filter(m => {
            return (m.start_date == null || momentDate.isSameOrAfter(moment(m.start_date), 'day')) && (m.end_date == null || momentDate.isSameOrBefore(moment(m.end_date), 'day'))
        }).length
    }
    const computeOccupancyRate = (momentDate) => {
        return (computeStaying(momentDate) / computeAvailableRoom(momentDate) * 100.0).toPrecision(2)
    }
    const computeTrend = (momentDate) => {
        let prevRate = computeOccupancyRate(moment(momentDate).add(-1, 'day'));
        let curRate = computeOccupancyRate(momentDate);
        return prevRate == 0 ? '-' : ((curRate - prevRate) / prevRate * 100.0).toFixed(2)
    }

    //available = rooms.length - maintenance.length
    //arrival = # of accommodations with check_in_date = date
    //departure = # of accommodations with check_out_date = date
    //stays = # of accommodations with check_in_date < date < check_out_date
    return projectingRange.map(momentDate => {
        return {
            date: momentDate.toISOString().substring(0, 10),
            arrival: computeArrival(momentDate),
            departure: computeDepature(momentDate),
            stays: computeStaying(momentDate),
            occupancy: computeOccupancyRate(momentDate),
            trend: computeTrend(momentDate)
        }

    })

}

export async function serviceSalesStats(date, tenantId) {
    const gql = {
        query: `query{
        orders(where:{tenant_id: {_eq:"${tenantId}"}, created_at: {_gte: "${date.toISOString().substring(0, 10)}"}, order_type: {_eq:Service}, status:{_neq:Cancelled}}){
            qty
            total_price
            service{
                id
                name
            }
        }
    }`}
    const orders = (await query(gql)).data.orders;
    const services = orders.reduce((acc, curr) => {
        if (!acc.map(s => s.id).includes(curr.service.id)) {
            return [...acc, curr.service]
        }
        return acc
    }, [])
    const inversed = orders.map(o => ({ service: o.service, qty: o.qty, total_price: o.total_price }))
    return services.map(service => ({
        service: service.name,
        total_order: inversed.filter(o => o.service.id == service.id).reduce((acc, o) => acc + o.qty, 0),
        total_earning: inversed.filter(o => o.service.id == service.id).reduce((acc, o) => acc + o.total_price, 0)
    }))
}

async function maintenancesByDate(date, tenantId) {
    const gql = {
        query: `query{
            maintenances(where:{tenant_id:{_eq:"${tenantId}"}}){
                start_date
                end_date
            }
        }`
    }
    const maintenances = (await query(gql)).data.maintenances
    return maintenances.filter(m => (m.start_date == null || moment(m.start_date).isSameOrBefore(moment(date))) && (m.end_date == null || moment(m.end_date).isSameOrAfter(moment(date))))
}


async function availableRoomAsOfDate(date, tenantId) {
    const gql = {
        query: `query{
            rooms(where:{tenant_id:{_eq: "${tenantId}"}, status:{_eq: Active}}){
                id
            }
        }`
    }
    const { rooms } = (await query(gql)).data

    const maintenances = (await maintenancesByDate(date, tenantId))

    return rooms.length - maintenances.length
}


async function accommodationOrders(date, tenantId) {
    const accommodationOrdersQuery = {
        query: `query{
            orders(where:{
                order_type:{_eq:Accommodation},
                tenant_id:{_eq:"${tenantId}"},
                status:{_neq:Cancelled},
                accommodation:{
                    check_in_date: {_lte:"${date.toISOString().substring(0, 10)}"},
                    check_out_date: {_gt: "${date.toISOString().substring(0, 10)}"}
                }
            }){
                total_price
                qty
            accommodation{

                check_in_date
                check_out_date
                room_type{
                    name
                }
            }
        }
    }`}
    const result =(await query(accommodationOrdersQuery))
    return result.data.orders

}

async function revenueStats(date, tenantId) {
    const orders = await accommodationOrders(date, tenantId)

    const roomRevenue = orders.map((o) => (o.total_price / o.qty)).reduce((acc, cur) => acc + cur, 0);

    const availableRooms = await availableRoomAsOfDate(date, tenantId)

    const adr = orders.length == 0 ? 0 : 1.0 * roomRevenue / orders.length

    const revpar = availableRooms == 0 ? 0 : adr * orders.length / availableRooms

    return { date:date.toISOString().substring(0,10), revenue: roomRevenue.toFixed(2), adr: adr.toFixed(2), revpar: revpar.toFixed(2) }

}

export async function roomRevenueStats(date, tenantId) {
    return [await revenueStats(moment(date).add(-1, 'day'), tenantId), await revenueStats(date,tenantId)]
}
