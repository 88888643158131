import gql from 'graphql-tag';

const GET_LIST_PAYMENTS = gql`
{
  is_valid
  received_currency
    amount
    created_at
    e_payment_status {
      comment
      id
    }
    e_payment_type {
      id
      comment
    }
    id
    payment_no
    payment_type
    deposit_id
    payment_transaction_id
    credit_card_number
    deposit {
      amount
      booking_id
      created_at
      deposit_no
      id
      status
      tenant_id
      type
      updated_at
      e_deposit_status {
        comment
        id
      }
      e_deposit_type {
        comment
        id
      }
    }
    remarks
    status
    updated_at
}
`;

const GET_ONE_PAYMENTS = gql`
{
  is_valid
    received_currency
    amount
    created_at
    e_payment_status {
      comment
      id
    }
    e_payment_type {
      id
      comment
    }
    id
    payment_no
    payment_type
    deposit_id
    payment_transaction_id
    credit_card_number
    deposit {
      amount
      booking_id
      created_at
      deposit_no
      id
      status
      tenant_id
      type
      updated_at
      e_deposit_status {
        comment
        id
      }
      e_deposit_type {
        comment
        id
      }
    }
    remarks
    status
    updated_at
}
`;

export{GET_LIST_PAYMENTS, GET_ONE_PAYMENTS}
