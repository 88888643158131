import React from 'react';
import { Edit } from 'react-admin';
import RoomRateForm from './RoomRateForm';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";

const RoomRateTitle = ({ record }) => {
  return <span>Room Rate {record ? `${record.name}` : ''}</span>
};

const RoomRateEdit = props => (
  <div>
    <Breadcrumbs aria-label="breadcrumb" style={{
      borderRadius: 5,
      background: "white",
      padding: 12,
      border: "1px solid #e0e0e3",
      //marginLeft: 16,
      //marginRight: 30,
      marginTop: 12,
    }}>
      <Link color="inherit" href="/room_rates">
        Room Rates
      </Link>
      <Link style={{ fontWeight: "bold" }} color="textPrimary" aria-current="page" disabled>
        Room Rate
      </Link>
    </Breadcrumbs>
    <Edit title={<RoomRateTitle />} {...props} component="div">
      <RoomRateForm />
    </Edit>
  </div>

);

export default RoomRateEdit;
