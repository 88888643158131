import React from 'react';
import {
    FormWithRedirect,
    TextInput,
    NumberInput,
    SelectInput,
    required,
    minLength,
    maxLength,
    ArrayInput,
    SimpleFormIterator,
    ReferenceInput,
    AutocompleteInput,
    Toolbar,
    BooleanInput
} from 'react-admin';

import { Typography, Box, InputAdornment } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import CustomSaveButton from './CustomSaveButton';


const useStyles = makeStyles((theme) => ({
    formGrid: {
        paddingTop: "0!important",
        paddingBottom: "0!important",
    },
    formInput: {
        marginTop: "0!important",
        marginBottom: "0!important",
    },
    textBold: {
        fontWeight: "bold",
    },
    // groupLabel: {
    //   color: "rgba(0, 0, 0, 0.54)",
    //   fontSize: "14px"
    // }
}));

const RoomRateForm = props => {
    const authInfo = JSON.parse(localStorage.getItem('auth'));
    const classes = useStyles();

    const { source, ...rest } = props;

    return (
        <FormWithRedirect
            {...props}
            render={formProps => (
                <Card>
                    <form>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" className={classes.textBold} gutterBottom>
                                        Room Rate Info
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} className={classes.formGrid}>
                                    <TextInput
                                        className={classes.formInput}
                                        fullWidth
                                        resource="room_rates"
                                        source="name"
                                        validate={[required(), minLength(2), maxLength(25)]}
                                        label="ra.column.name"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} className={classes.formGrid}>
                                    <ReferenceInput className={classes.formInput}
                                        fullWidth label="Cancellation policy"
                                        source="cancellation_policy_id"
                                        reference="cancellation_policies"
                                        filter={{ tenant_id: authInfo.tenant.id }}
                                        validate={[required()]} >
                                        <SelectInput optionText="name" />
                                    </ReferenceInput>
                                </Grid>

                                <Grid item xs={12} className={classes.formGrid}>
                                    <Card>
                                        <CardContent>
                                            <ArrayInput label="Room types" resource="room_rates" source="room_type_rates" className={classes.formInput}>
                                                <SimpleFormIterator>
                                                    <TextInput
                                                        style={{ display: "none" }}
                                                        className={classes.formInput}
                                                        source="tenant_id"
                                                        label="Tenant ID"
                                                        fullWidth
                                                        defaultValue={authInfo.tenant.id}
                                                        validate={[required()]}
                                                    />
                                                    <ReferenceInput
                                                        source="room_type_id"
                                                        reference="room_types"
                                                        filter={{ tenant_id: authInfo.tenant.id }}
                                                        filterToQuery={(searchText) => ({
                                                            name: searchText,
                                                        })}
                                                        label="ra.column.type"
                                                        validate={[required()]}
                                                    >
                                                        <AutocompleteInput
                                                            optionText="name"
                                                            options={{ InputProps: { autoComplete: "no" } }}
                                                        />
                                                    </ReferenceInput>
                                                    <NumberInput min={0} source="price" label="Price" InputProps={{
                                                        startAdornment: <InputAdornment position="start">{authInfo.tenant.locale.currency_code}</InputAdornment>,
                                                    }} validate={[required()]} />
                                                    <NumberInput min={0} source="additional_charge_per_adult" label="Additional charge per adult" InputProps={{
                                                        startAdornment: <InputAdornment position="start">{authInfo.tenant.locale.currency_code}</InputAdornment>,
                                                    }} validate={[required()]} />
                                                </SimpleFormIterator>
                                            </ArrayInput>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={12} className={classes.formGrid}>
                                    <BooleanInput label="Is IBE" source="is_ibe" valueLabelTrue="Yes" valueLabelFalse="No" />
                                </Grid>
                                <Grid item xs={12} sm={12} className={classes.formGrid}>
                                    <ReferenceInput
                                        fullWidth
                                        label="Status"
                                        source="status"
                                        reference="e_room_rate_status"
                                        validate={[required()]}
                                    >
                                        <SelectInput optionText="comment" />
                                    </ReferenceInput>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <Toolbar>
                            <Box display="flex" justifyContent="space-between" width="100%">
                                <CustomSaveButton {...formProps} />
                                {/* {formProps.record.id != undefined ?
                  <DeleteButton record={formProps.record} />
                  : null} */}
                            </Box>
                        </Toolbar>
                    </form>
                </Card>

            )}
        />
    )
}

export default RoomRateForm;
