import { executeAsync, output } from "./hasura";

export const tenantGroupApi = {
  getTenantGroupIdAsync,
  getRideumMerchantAsync,
  updateRideumMerchantAsync,
  removeRideumMerchantAsync,
}

async function getTenantGroupIdAsync(tenant_id) {
  var gql = `
    query GetTenantGroupId($tenant_id: uuid!) {
      tenants_by_pk(id: $tenant_id) {
        tenant_group_id
      }
    }
  `;

  const { data, errors } = await executeAsync({ tenant_id }, gql);
  const value = {
    tenant_group_id: data?.tenants_by_pk?.tenant_group_id
  }
  return output(value, errors);
}


async function getRideumMerchantAsync (tenant_group_id) {
  var gql = `
    query GetMerchant($tenant_group_id: uuid!) {
			tenant_groups_by_pk(id: $tenant_group_id) {
				rideum_merchant_id
        rideum_merchant_name
			}
    }
  `;
  
  const { data, errors } = await executeAsync({ tenant_group_id }, gql);
  const value = { 
    id: data?.tenant_groups_by_pk?.rideum_merchant_id,
    name: data?.tenant_groups_by_pk?.rideum_merchant_name,
  };
  return output(value, errors);
}


async function updateRideumMerchantAsync(tenant_group_id, rideum_merchant_id, rideum_merchant_name) {
  var gql = `
    mutation UpdateRideumMerchantId($tenant_group_id: uuid!, $rideum_merchant_id: uuid!, $rideum_merchant_name: String!) {
      update_tenant_groups_by_pk(pk_columns: {id: $tenant_group_id}, _set: {rideum_merchant_id: $rideum_merchant_id, rideum_merchant_name: $rideum_merchant_name}) {
        id
      }
    }
  `;
  
  const { data, errors } = await executeAsync({ tenant_group_id,
                                                rideum_merchant_id,
                                                rideum_merchant_name 
                                              }, gql);
  const value = { 
    id: data?.update_tenant_groups_by_pk?.id
  };
  return output(value, errors);
}

async function removeRideumMerchantAsync(tenant_group_id) {
  var gql = `
    mutation UpdateRideumMerchantId($tenant_group_id: uuid!) {
      update_tenant_groups_by_pk(pk_columns: {id: $tenant_group_id}, _set: {rideum_merchant_id: null, rideum_merchant_name: null}) {
        id
      }
    }
  `;
  
  const { data, errors } = await executeAsync({ tenant_group_id }, gql);
  const value = { 
    id: data?.update_tenant_groups_by_pk?.id
  };
  return output(value, errors);
}