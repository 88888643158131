import React, { useContext, useEffect } from "react";
import {
  FormWithRedirect,
  TextInput,
  SelectInput,
  required,
  minLength,
  maxLength,
  email,
  useTranslate,
  ReferenceInput,
  Toolbar,
} from "react-admin";
import { Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import CustomSaveButton from "./CustomSaveButton";
import { useNotify, useRedirect } from "react-admin";

const validateFirstName = [required(), minLength(2), maxLength(25)];
const validateEmail = [required(), email()];

const useStyles = makeStyles((theme) => ({
  formGrid: {
    paddingTop: "0!important",
    paddingBottom: "0!important",
  },
  formInput: {
    marginTop: "0!important",
    marginBottom: "0!important",
  },
  textBold: {
    fontWeight: "bold",
  },
}));

const CM2FASessionsForm = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const { source, ...rest } = props;
  
  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <Card>
          <form>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    className={classes.textBold}
                    gutterBottom
                  >
                    {translate("ra.label.session")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    fullWidth
                    resource="cm_2fa_sessions"
                    disabled source="cm_account.cm_client.name"
                    label="ra.label.client_name"
                    value = "Read Only"
                    editable = {false}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    fullWidth
                    resource="cm_2fa_sessions"
                    disabled source="cm_account.login"
                    validate={validateEmail}
                    label="ra.column.email"
                    value = "Read Only"
                    editable = {false}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    fullWidth
                    resource="cm_2fa_sessions"
                    source="code"
                    label="ra.label.client_code"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Toolbar>
              <Box display="flex" justifyContent="space-between" width="100%">
                <CustomSaveButton {...formProps} />
                {/* {formProps.record.id != undefined ?
                  <DeleteButton record={formProps.record} />
                  : null} */}
              </Box>
            </Toolbar>
          </form>
        </Card>
      )}
    />
  );
};

export default CM2FASessionsForm;
