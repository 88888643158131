import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AccommodationList from './AccommodationList';
import AccommodationCreate from './AccommodationCreate';
import AccommodationEdit from './AccommodationEdit';

export default {
    list: AccommodationList,
    create: AccommodationCreate,
    edit: AccommodationEdit,
    icon: AssignmentIndIcon,
};
