export async function executeAsync(method, url, token, body) {
	let requestOptions = {
		method: method.toUpperCase(),
	};

	if (token === undefined) {
		requestOptions["headers"] = new Headers({
			"Content-Type": "application/json-patch+json",
		});
	} else {
		requestOptions["headers"] = new Headers({
			"Authorization": `Bearer ${token}`,
			"Content-Type": "application/json-patch+json",
		});
	}
	
	if (body !== undefined) {
		requestOptions["body"] = JSON.stringify(body);
	}

	return await fetch(url, requestOptions);
}


export async function outputAsync(response, data) {
	if (response.ok) {
		return { data: data, hasError: false, errors: undefined };
	}
	else {
		let errors;

		if (response.status === 401 ||
        response.status === 403 || 
        response.status === 404) 
		{
      const error = {
        message: response.statusText,
      };
      errors = [error];
		}
		else if ( response.status === 400 || 
              response.status === 500 || 
              response.status === 503 || 
              response.status === 550 ) 
		{
			const payload = await response.json();
      const error = {
        type: payload?.error?.type,
        code: payload?.error?.code,
        message: payload?.error?.message,
        exception: payload?.error?.exception,
      };
      errors = [error];
		} 
		else 
		{
      const payload = await response.text();
      const error = {
        message: payload,
      };
      errors = [error];
		}

		return { data: undefined, hasError: true, errors };
	}
}