import React from 'react';
import { Edit } from 'react-admin';
import OrderForm from './OrderForm';
import { useTranslate } from "react-admin";
import { formatter } from "../../utils/formatter";

const OrderTitle = ({record}) => {
  const translate = useTranslate();
  return <span>Order { record ? `"${formatter.orderNo(record.order_no)}"` : ''}</span>
};

const OrderEdit = props => (
  <Edit title={<OrderTitle />} {...props} component="div">
    <OrderForm />
  </Edit>
);

export default OrderEdit;
