import FaceIcon from '@material-ui/icons/Face';
import RoomServicesList from './RoomServicesList';
import RoomServicesCreate from './RoomServicesCreate';
import RoomServicesEdit from './RoomServicesEdit';

export default {
    list: RoomServicesList,
    create: RoomServicesCreate,
    edit: RoomServicesEdit,
    icon: FaceIcon,
};