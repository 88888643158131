export function handleResponse(response) {
  return new Promise((resolve, reject) => {
    if (response.ok) {
      // return json if it was returned in the response
      var contentType = response.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        response.json().then((json) => resolve(json));
      } else {
        resolve();
      }
    } else {
      if (response.status === 401 || 
          response.status === 403 || 
          response.status === 404) {
        reject(response.statusText);
      }
      else
      {
        // return error message from response body
        response.text().then((text) => reject(text));
      }
    }
  });
}

export function handleTextResponse(response) {
  return new Promise((resolve, reject) => {
    if (response.ok) {
      response.text().then((text) => resolve(text));
    } else {
      // return error message from response body
      response.text().then((text) => reject(text));
    }
  });
}

export function handleError(error) {
  return Promise.reject(error && error.message);
}



export function handleAsyncResponse(data) {
  return { data: data, hasError: false, error: undefined };
}

export async function halndleAsyncError(response) {
  let error;

  if (response.status === 401 || 
      response.status === 403 || 
      response.status === 404) 
  {
    error = response.statusText;
  } else {
    error = await response.text();
  }

  return { data: undefined, hasError: true, error };
}