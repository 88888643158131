import gql from 'graphql-tag';

const GET_LIST_MAINTENANCES = gql`
{
  created_at
  start_date
  end_date
  id
  room_id
  room {
    name
    status
    room_type_id
  }
  title
  updated_at
  priority_level
  e_priority_level {
    id
    comment
  }
  images_url
  maintenance_jobs {
    id
    job
    quantity
  }
  refDeliveryId
}
`;


const GET_ONE_MAINTENANCES = gql`
{
  created_at
  start_date
  end_date
  id
  room_id
  room {
    name
    status
    room_type_id
  }
  title
  updated_at
  priority_level
  e_priority_level {
    id
    comment
  }
  images_url
  maintenance_jobs {
    id
    job
    quantity
  }
  refDeliveryId
}
`;

export{GET_LIST_MAINTENANCES, GET_ONE_MAINTENANCES}
