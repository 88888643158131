const isLocalOrDev = window.location.href.includes("localhost") || window.location.href.includes("dev");
const isStaging = window.location.href.includes("stg");

const bleudineUrl = isLocalOrDev ? "https://api.dev.bleudine.com" : isStaging ? "https://api.stg.bleudine.com" : "https://api.bleudine.com";
const bleudineSecret = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJiMGVlMGI2Yi02ZTc1LTRjZjMtODE0OC00NDE2OGY2ZDA3YzIiLCJqdGkiOiI1MjVkODg1Yi1jZjM4LTQ3M2ItOGE0Yi1mNWM0OGMxY2E5NDciLCJuYW1laWQiOiJhZG1pbkBibGV1ZGluZS5ieWNvZGVycy5jb20iLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJBZG1pbiIsImV4cCI6MTY0NTg1MTQxOCwiaXNzIjoiaHR0cDovL2JsZXVkaW5lLmNvbSIsImF1ZCI6Imh0dHA6Ly9ibGV1ZGluZS5jb20ifQ.sCdOn0TYZ0qeZqDQGlAYW8FyWbk8mSPE5DB-ER9xT1c";
const hasuraUrl = isLocalOrDev ? "https://hasura.dev.bleustay.com/v1/graphql" : isStaging ? "https://hasura.stg.bleustay.com/v1/graphql" : "https://hasura.bleustay.com/v1/graphql";
const hasuraSecret = isLocalOrDev ? "2drH5MsdDIsNzxlhdEIAuioyNuJtktQLxhhnLuGEqH11QWsBz0q5ce0dgqXVSCU5" : isStaging ? "gS0WpS6Fgl0FFFNbTAWO1CT5K5c1qOLNqqhH2iss7jsPwo2cnzyb52QpA4dYwWHf" : "Pp1pqMRPjzGlaCsjnShpmIenTdewdsUrfTzMW2TAI5E7o4baJ2zTmGHoZf3lVrDR";

export const apiConfig = {
  url: bleudineUrl,
  path: "/api",
  secret: bleudineSecret,
  getBleudineToken: function () {
     const user = JSON.parse(localStorage.getItem("bleudine-user"));
     return user ? user.token : "";
  },
  getRideumToken: function () {
    const token = localStorage.getItem("token");
    return token ? token : "";
  },

  hasuraUrl: hasuraUrl,
  hasuraSecret: hasuraSecret,
};
