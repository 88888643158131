export const paymentStatus = {
  getColor,
};

function getColor(status) {
  switch (status) {
    case "Confirmed":
      return "darkblue";
    case "Processing":
      return "purple";
    case "Success":
      return "darkgreen";
    case "Cancelled":
      return "darkred";
    case "Paid":
      return "green";
    case "Unpaid":
      return "red";
    case "Overpaid":
      return "orange";
    default:
      return "white";
  }
}
