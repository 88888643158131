import React, { useContext } from 'react';
import { Create } from 'react-admin';
import RoomTypeForm from './RoomTypeForm';
import { DataProviderContext } from 'react-admin';
import { useNotify, useRedirect } from 'react-admin';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";

const RoomTypeCreate = props => {
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useContext(DataProviderContext);

  const handleSubmit = (formProps, formData) => {
    var request = {
      ...formData,
      room_type_beds: { data: formData.room_type_beds },
      room_facilities: formData.room_facilities != undefined ? `{${formData.room_facilities?.toString()}}` : `{}`,
      bathroom_facilities: formData.bathroom_facilities != undefined ? `{${formData.bathroom_facilities?.toString()}}` : `{}`,
      view_facilities: formData.view_facilities != undefined ? `{${formData.view_facilities?.toString()}}` : `{}`,
      bedroom_facilities: formData.bedroom_facilities != undefined ? `{${formData.bedroom_facilities?.toString()}}` : `{}`,
      entertainment_facilities: formData.entertainment_facilities != undefined ? `{${formData.entertainment_facilities?.toString()}}` : `{}`,
      kitchen_facilities: formData.kitchen_facilities != undefined ? `{${formData.kitchen_facilities?.toString()}}` : `{}`,
      outdoor_facilities: formData.outdoor_facilities != undefined ? `{${formData.outdoor_facilities?.toString()}}` : `{}`,
      floor_facilities: formData.floor_facilities != undefined ? `{${formData.floor_facilities?.toString()}}` : `{}`
    }

    dataProvider.create(formProps.resource, { data: { ...request } })
      .then(({ data }) => {
        redirect(`/room_types`);
        notify("Room type created");
      })
      .catch(error => {
        notify(error);
      })
  }

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb" style={{
      borderRadius: 5,
      background: "white",
      padding: 12,
      border: "1px solid #e0e0e3",
      //marginLeft: 16,
      //marginRight: 30,
      marginTop: 12,
    }}>
        <Link color="inherit" href="/room_types">
          Room Types
        </Link>
        <Link style={{ fontWeight: "bold" }} color="textPrimary" aria-current="page" disabled>
          Room Type
        </Link>
      </Breadcrumbs>
      <Create {...props} component="div" >
        <RoomTypeForm handleSubmit={handleSubmit} />
      </Create>
    </div>

  )

};

export default RoomTypeCreate;
