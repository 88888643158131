import React from 'react';
import { Create } from 'react-admin';
import AccommodationForm from './AccommodationForm';

const AccommodationCreate = props => (
  <Create {...props} component="div">
    <AccommodationForm />
  </Create>
);

export default AccommodationCreate;
