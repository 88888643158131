import { apiConfig } from "./apiConfig";
import { handleResponse, handleError } from "./apiHelper";
const localhostPort = 64638;

export const bleudineMemberApi = {
  getById,
  getBySearch
};

function getById(userId) {
  const url = `${apiConfig.url}${apiConfig.path}/shoppers/${userId}`;
  //const url = `http://localhost:${localhostPort}/api/shoppers/${userId}`;

  const requestOptions = {
    method: "GET",
    headers: new Headers({
      Authorization: `Bearer ${apiConfig.getBleudineToken()}`,
      "Content-Type": "application/json-patch+json",
    })
  };

  return fetch(url, requestOptions)
    .then(handleResponse, handleError)
    .then((user) => {
      return user;
    });
}

function getBySearch(searchKey) {
  const url = `${apiConfig.url}${apiConfig.path}/shoppers/search/${searchKey}`;
  //const url = `http://localhost:${localhostPort}/api/shoppers/search/${searchKey}`;

  const requestOptions = {
    method: "GET",
    headers: new Headers({
      Authorization: `Bearer ${apiConfig.getBleudineToken()}`,
      "Content-Type": "application/json-patch+json",
    })
  };

  return fetch(url, requestOptions)
    .then(handleResponse, handleError)
    .then((user) => {
      return user;
    });
}
