import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  EditButton,
  TextInput,
  FunctionField,
  ReferenceInput,
  SelectInput,
  downloadCSV,
} from "react-admin";
import { Button, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { companyStatus } from "../../models/companyStatus";
import Chip from "@material-ui/core/Chip";
import jsonExport from "jsonexport/dist";


const companyFilters = [
  <TextInput
    source="email,name,phone_no"
    label="Search"
    alwaysOn
    resettable
    InputProps={{
      endAdornment: (
        <InputAdornment>
          <SearchIcon color="disabled" />
        </InputAdornment>
      ),
    }}
  />,
  <ReferenceInput
    style={{ width: 250 }}
    label="Status"
    source="status"
    reference="e_company_status"
    alwaysOn
    resettable
  >
    <SelectInput optionText="comment" />
  </ReferenceInput>,
];

const exporter = (records) => {
  const forExport = records.map((record) => {
    return {
      name: record.name,
      email: record.email,
      phone_no: record.phone_no,
      country: record.e_country.comment,
      status: record.e_company_status.comment,
    };
  });
  jsonExport(
    forExport,
    {
      headers: ["name", "email", "phone_no", "country", "status"],
    },
    (err, csv) => {
      downloadCSV(csv, "companies");
    }
  );
};

const CompanyList = (props) => {
  const authInfo = JSON.parse(localStorage.getItem("auth"));

  return (
    <List
      sort={{ field: "created_at", order: "Desc" }}
      filters={companyFilters}
      filterDefaultValues={{ tenant_id: authInfo.tenant.id }}
      bulkActionButtons={false}
      exporter={exporter}
      {...props}
    >
      <Datagrid>
        <TextField source="name" label="ra.column.name" />
        <EmailField source="email" label="ra.column.email" />
        <TextField source="phone_no" label="ra.column.phone_no" />
        <TextField source="e_country.comment" label="ra.column.country" />
        <FunctionField
          sortBy="status"
          label="Status"
          render={(record) => (
            <Chip
              label={record.e_company_status?.comment}
              style={{
                color: "white",
                background: companyStatus.getColor(record.e_company_status?.id),
              }}
            />
          )}
        />
        <EditButton style={{ float: "right" }} basePath="/companies" />
      </Datagrid>
    </List>
  );
}

export default CompanyList;
