import { apiConfig } from "./apiConfig";
import { handleResponse, handleError } from "./apiHelper";

export const userApi = {
  createUser,
  updateUser,
  deleteUser,
  getUser,
  searchGuest
};


function createUser(tenant_id, tenant_name, email, role, first_name, last_name, status, mapped_id) {
  var gql = {
    query: `mutation CreateUser {
			create_user (
        tenant_id: "${tenant_id}"
        tenant_name: "${tenant_name}"
				email: "${email}", 
				role: "${role}",
				first_name: "${first_name}", 
				last_name: "${last_name}",
        status: "${status}"
        mapped_id: "${mapped_id}"
        comment: ""
	    ) {
        uid
      }
    }`,
  };

  const url = apiConfig.hasuraUrl;
  const requestOptions = {
    method: "POST",
    headers: {
      "x-hasura-admin-secret": apiConfig.hasuraSecret,
      //'Authorization': `Bearer ${apiConfig.getRideumToken()}`,
      "Content-Type": "application/json-patch+json",
    },
    body: JSON.stringify(gql),
  };

  return fetch(url, requestOptions)
    .then(handleResponse, handleError)
    .then((data) => { return data; });
}


function updateUser(uid, role, first_name, last_name, status) {
  var gql = {
    query: `mutation UpdateUser {
			update_user (
        uid: "${uid}"
				role: "${role}",
				first_name: "${first_name}", 
				last_name: "${last_name}",
        status: "${status}",
        comment: ""
      ) {
        uid
      }
		}`,
  };
  const url = apiConfig.hasuraUrl;
  const requestOptions = {
    method: "POST",
    headers: {
      "x-hasura-admin-secret": apiConfig.hasuraSecret,
      // 'Authorization': `Bearer ${apiConfig.getRideumToken()}`,
      "Content-Type": "application/json-patch+json",
    },
    body: JSON.stringify(gql),
  };

  return fetch(url, requestOptions)
    .then(handleResponse, handleError)
    .then((data) => { return data; });
}


function deleteUser(uid) {
  var gql = {
    query: `mutation DeleteUser {
			delete_user ( uid: "${uid}" ) {
        uid
      }
		}`,
  };
  const url = apiConfig.hasuraUrl;
  const requestOptions = {
    method: "POST",
    headers: {
      "x-hasura-admin-secret": apiConfig.hasuraSecret,
      // 'Authorization': `Bearer ${apiConfig.getRideumToken()}`,
      "Content-Type": "application/json-patch+json",
    },
    body: JSON.stringify(gql),
  };

  return fetch(url, requestOptions)
    .then(handleResponse, handleError)
    .then((data) => { return data; });
}

function getUser(mapped_id) {
  var gql = {
    query: `query MyQuery {
      users(where: {mapped_id: {_eq: "${mapped_id}"},is_deleted: {_eq: false}}) {
        created_at
        email
        first_name
        id
        last_name
        role
        status
        tenant_id
        tenant_group_id
        updated_at
        tenant_group {
          address_1
          address_2
          city
          country
          e_country {
            comment
            id
          }
          locale_id
          locale {
            country
            currency_code
            decimal_seperator
            id
            precision
            thousand_seperator
          }
          created_at
          email
          id
          name
          phone_no
          region
          status
          updated_at
          zip_code
          mapped_id
      }
        tenant {
          address_1
          address_2
          city
          country
          e_country {
            comment
            id
          }
          locale_id
          locale {
            country
            currency_code
            decimal_seperator
            id
            precision
            thousand_seperator
          }
          created_at
          email
          id
          name
          phone_no
          region
          status
          updated_at
          zip_code
      }
    }
  }
    `,
  };

  const url = apiConfig.hasuraUrl;
  const requestOptions = {
    method: "POST",
    headers: {
      "x-hasura-admin-secret": apiConfig.hasuraSecret,
      //'Authorization': `Bearer ${apiConfig.getRideumToken()}`,
      "Content-Type": "application/json-patch+json",
    },
    body: JSON.stringify(gql),
  };

  return fetch(url, requestOptions)
    .then(handleResponse, handleError)
    .then((data) => { return data; });
}

function searchGuest(tenant_id, document_type, document_no) {
  var gql = {
    query: `query MyQuery {
        guests(where: {tenant_id: {_eq: "${tenant_id}"}, 
        document_type: {_eq: ${document_type}}, document_no: {_ilike: "${document_no}"}}) {
        email
        first_name
        document_no
        address_1
        address_2
        birth_date
        bleudine_user_id
        city
        comment
        country
        created_at
        display_name
        document_back_image_url
        document_expiry
        document_front_image_url
        document_type
        gender
        id
        last_name
        nationality
        phone_no
        region
        status
        tenant_id
        title
        updated_at
        zip_code
      }
  }
    `,
  };

  const url = apiConfig.hasuraUrl;
  const requestOptions = {
    method: "POST",
    headers: {
      "x-hasura-admin-secret": apiConfig.hasuraSecret,
      //'Authorization': `Bearer ${apiConfig.getRideumToken()}`,
      "Content-Type": "application/json-patch+json",
    },
    body: JSON.stringify(gql),
  };

  return fetch(url, requestOptions)
    .then(handleResponse, handleError)
    .then((data) => { return data; });
}
