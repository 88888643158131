import React from 'react';
import { SaveButton } from 'react-admin';
import { useFormState } from 'react-final-form';
import { useNotify, useRedirect } from 'react-admin';
import { userApi } from '../../api/userApi';
import { bleudineUserApi } from '../../api/bleudineUserApi';
import { userStatus } from '../../models/userStatus';

const CustomSaveButton = (formProps) => {
  const authInfo = JSON.parse(localStorage.getItem('auth'));
  const notify = useNotify();
  const redirect = useRedirect();
  let { values } = useFormState();
  const [saving, setSaving] = React.useState(false);

  //create hasura user
  const tenant_id = authInfo.tenant ? authInfo.tenant.id : authInfo.tenant_group_id;
  const tenant_name = authInfo.tenant ? authInfo.tenant.name : authInfo.tenant_group.name;
  const id = values.id;
  const email = values.email;
  const role = values.role;
  const first_name = values.first_name;
  const last_name = values.last_name;
  const status = values.status;


  const handleCreateClick = () => {
    setSaving(true);
    const user =
    {
      accountType: 1,
      email: email,
      phoneNumber: "",
      displayName: first_name + " " + last_name,
      firstName: first_name,
      lastName: last_name,
      birthDate: "",
      countryOrigin: "",
      remarks: "",
      imageFileName: "",
      imageBase64: "",
      imageUrl: "",
      type: userStatus.getEnum(role.toLowerCase()),
      status: values.status
    }
    bleudineUserApi.createUser(authInfo.tenant_group.mapped_id,user).then(newUser => {
      // userApi.createUser(tenant_id, tenant_name, email, role, first_name, last_name, status, user.id)
      // .then(data => {
      //   redirect(`/users`);
      //   notify("User Created");
      // }).catch(error => {
      //   setSaving(false);
      //   notify(error);
      // });
      authInfo.tenant ? redirect(`/users`) : redirect(`/users?role=manager`);
      notify("User Created");
    }).catch(error => {
      setSaving(false);
      notify(error);
    });
  }


  const handleUpdateClick = () => { 
    const user =
    {
      displayName: first_name + " " + last_name,
      firstName: first_name,
      lastName: last_name,
      birthDate: "",
      countryOrigin: "",
      remarks: "",
      imageFileName: "",
      imageBase64: "",
      imageUrl: "",
      status: values.status,
      type: userStatus.getEnum(role.toLowerCase()),
    }
    setSaving(true);

    bleudineUserApi.updateUser(values.mapped_id,user)
    .then((updatedUser) => {
        authInfo.tenant ? redirect(`/users`) : redirect(`/users?role=manager`);
        notify("User updated");
      }
    )
      .catch(error => {
        setSaving(false);
        notify(error);
      });
  }

  return <SaveButton {...formProps} saving={saving} disabled={saving} onSave={values.id != undefined ? handleUpdateClick : handleCreateClick} />;
};

export default CustomSaveButton;