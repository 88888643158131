import { apiConfig } from "./apiConfig";
import { handleResponse, handleError } from "./apiHelper";

export const cancellationPolicyApi = {
deleteCancellationPolicy
};


function deleteCancellationPolicy(id) {
	var gql = {
    query: `mutation DeleteCancellationPolicies {
      delete_cancellation_policies(where: {id: {_eq: "${id}"}}) {
        returning {
          id
        }
      }
    }`,
  };
  const url = apiConfig.hasuraUrl;
  const requestOptions = {
    method: "POST",
    headers: {
      "x-hasura-admin-secret": apiConfig.hasuraSecret,
      // 'Authorization': `Bearer ${apiConfig.getRideumToken()}`,
      "Content-Type": "application/json-patch+json",
    },
    body: JSON.stringify(gql),
  };

  return fetch(url, requestOptions)
    .then(handleResponse, handleError)
    .then((data) => { return data; });
}
