import FaceIcon from '@material-ui/icons/Business';
import TenantList from './TenantList';
import TenantCreate from './TenantCreate';
import TenantEdit from './TenantEdit';

export default {
    list: TenantList,
    create: TenantCreate,
    edit: TenantEdit,
    icon: FaceIcon,
};