import React from 'react';
import { Create } from 'react-admin';
import OrderForm from './OrderForm';

const OrderCreate = props => (
  <Create {...props} component="div">
    <OrderForm />
  </Create>
);

export default OrderCreate;
