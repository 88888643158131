import React from "react";
import { Edit } from "react-admin";
import BookingEditForm from "./BookingEditForm";
import { useTranslate } from "react-admin";
import { formatter } from "../../utils/formatter";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";

const BookingTitle = ({ record }) => {
  const translate = useTranslate();
  return (
    <span>
      Booking {record ? `${formatter.bookingNo(record.booking_no)}` : ""}
    </span>
  );
};

const BookingEdit = (props) => (
  <div>
    <Breadcrumbs
      aria-label="breadcrumb"
      style={{
        borderRadius: 5,
        background: "white",
        padding: 12,
        border: "1px solid #e0e0e3",
        marginLeft: 16,
        marginRight: 30,
        marginTop: 12,
      }}
    >
      <Link color="inherit" href="/bookings">
        Bookings
      </Link>
      <Link color="textPrimary" style={{fontWeight:"bold"}} aria-current="page" disabled>
        Booking
      </Link>
    </Breadcrumbs>
    <Edit title={<BookingTitle />} {...props} component="div">
      <BookingEditForm />
    </Edit>
  </div>
);

export default BookingEdit;
