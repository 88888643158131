import DescriptionIcon from '@material-ui/icons/Description';
import OrderList from './OrderList';
import OrderCreate from './OrderCreate';
import OrderEdit from './OrderEdit';

export default {
    list: OrderList,
    create: OrderCreate,
    edit: OrderEdit,
    icon: DescriptionIcon,
};
