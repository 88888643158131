import { apiConfig } from "./apiConfig";
import { executeAsync, outputAsync } from "./bleudine";
import { handleResponse, handleError } from "./apiHelper";
const localhostPort = 64638;

export const authApi = {
  getGuestToken,
  getSystemTokenAsync,
  loginRideumMerchantAsync,
};


function getGuestToken() {
  const url = `${apiConfig.url}${apiConfig.path}/auth/guest`;
  //const url = `http://localhost:${localhostPort}/api/auth/guest`;

  const requestOptions = {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json-patch+json",
    })
  };

  return fetch(url, requestOptions)
    .then(handleResponse, handleError)
    .then((user) => {
      localStorage.setItem("bleudine-user", JSON.stringify(user));
      return user;
    });
}


async function getSystemTokenAsync() {
  const url = `${apiConfig.url}${apiConfig.path}/auth/system-token?secretKey=${apiConfig.secret}`;
  //const url = `http://localhost:${localhostPort}/api/auth/system-token?secretKey=${apiConfig.secret}`;

  const fetchResponse = await executeAsync("GET", url);
  const value = fetchResponse.ok ? await fetchResponse.text() : null;
  return await outputAsync(fetchResponse, value);
}


async function loginRideumMerchantAsync(email, password) {
  const url = `${apiConfig.url}${apiConfig.path}/auth/login?platformSite=5`;
  //const url = `http://localhost:${localhostPort}/api/auth/login?platformSite=5`;

  const body = {
    emailOrPhone: email,
    password: password,
  };

  const fetchResponse = await executeAsync("POST", url, undefined, body);
  const data = fetchResponse.ok ? await fetchResponse.json() : null;
  const value = {
    id: data?.merchant?.id,
    name: data?.merchant?.name,
  }
  return await outputAsync(fetchResponse, value);
}
