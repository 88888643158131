import React from "react";
import { Edit } from "react-admin";
import MaintenanceForm from "./MaintenanceForm";
import { useTranslate } from "react-admin";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";

const MaintenanceTitle = ({ record }) => {
  const translate = useTranslate();
  return <span>Maintenance {record ? `${record.title}` : ""}</span>;
};

const MaintenanceEdit = (props) => (
  <div>
    <Breadcrumbs
      aria-label="breadcrumb"
      style={{
        borderRadius: 5,
        background: "white",
        padding: 12,
        border: "1px solid #e0e0e3",
        //marginLeft: 16,
        //marginRight: 30,
        marginTop: 12,
      }}
    >
      <Link color="inherit" href="/maintenances">
        Maintenances
      </Link>
      <Link style={{fontWeight:"bold"}} color="textPrimary" aria-current="page" disabled>
        Maintenance
      </Link>
    </Breadcrumbs>
    <Edit title={<MaintenanceTitle />} {...props} component="div">
      <MaintenanceForm />
    </Edit>
  </div>
);

export default MaintenanceEdit;
