import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  TextField,
  CardActions
} from "@material-ui/core";

import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { bleudineAuthApi } from "../../api/bleudineAuthApi";

const useStyles = makeStyles(theme => ({
  actions: {
    display: 'flex',
    padding: '8px',
    alignItems: 'center'
  },
  buttons: {
    width: '100%'
  }
}));

export default function AlertDialog() {
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState("");

  const [toastOpen, setToastOpen] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = async () => {
    console.log("sending email to: ", email);

    bleudineAuthApi.resetPassword(email, 8).then(ret => {
      setOpen(false);
      setToastOpen(true);
      setToastMessage("Password reset email sent!");
    }).catch(error => {
    });
  };

  const handleOnChange = (event) => {
    const email = event.target.value;
    setEmail(email);
  };

  const handleToastClose = () => {
    setToastOpen(false);
    setToastOpen(false);
  };

  const classes = useStyles();
  return (
    <CardActions className={classes.actions}>
      <Button onClick={handleClickOpen} className={classes.buttons} style={{ color: "#494949" }}>
        Forgot Password?
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Send Password Reset?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            A password reset will be sent to the following email:
          </DialogContentText>
          <TextField
            id="outlined-basic"
            label="Email"
            type="email"
            variant="outlined"
            style={{ width: '100%' }}
            onChange={handleOnChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={handleSubmit} color="primary" autoFocus>
            Send Email
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={toastOpen}
        onClose={handleToastClose}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        message={toastMessage}
      ></Snackbar>
    </CardActions>
  );
}