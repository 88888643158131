import gql from 'graphql-tag';

const GET_LIST_GUESTS = gql`
{
  zip_code
  updated_at
  title
  region
  phone_no
  nationality
  last_name
  id
  gender
  first_name
  display_name
  email
  address_1
  address_2
  birth_date
  city
  comment
  country
  created_at
  document_back_image_url
  document_expiry
  document_front_image_url
  document_no
  document_type
  bleudine_user_id
  status
  e_guest_status {
    id
    comment
  }
  e_country {
    comment
    id
  }
  e_document_type {
    comment
    id
  }
  e_gender {
    comment
    id
  }
  e_nationality {
    comment
    id
  }
  e_title {
    comment
    id
  }
}
`;

export{GET_LIST_GUESTS}
