import DescriptionIcon from '@material-ui/icons/Description';
import InvoiceList from './InvoiceList';
import InvoiceCreate from './InvoiceCreate';
import InvoiceEdit from './InvoiceEdit';

export default {
    list: InvoiceList,
    create: InvoiceCreate,
    edit: InvoiceEdit,
    icon: DescriptionIcon,
};
