import HouseIcon from '@material-ui/icons/House';
import MaintenanceList from './MaintenanceList';
import MaintenanceCreate from './MaintenanceCreate';
import MaintenanceEdit from './MaintenanceEdit';

export default {
    list: MaintenanceList,
    create: MaintenanceCreate,
    edit: MaintenanceEdit,
    icon: HouseIcon,
};