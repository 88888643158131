export const guestStatus = {
  getColor,
};

function getColor(status) {
  switch (status) {
    case "Active":
      return "darkgreen";
    case "Inactive":
      return "darkred";
    case "Pending":
        return "darkred"
    case "Complete":
      return "darkgreen";
    default:
      return "white";
  }
}
