import gql from 'graphql-tag';

const GET_LIST_ROOM_RATES = gql`
{
  cancellation_policy_id
  created_at
  id
  meal_id
  name
  updated_at
  status
  is_ibe
  e_room_rate_status {
    id
    comment
  }
  meal {
    created_at
    id
    name
    price
    service_charge
    updated_at
  }
  cancellation_policy {
    after_period_cancel_charge
    created_at
    id
    name
    no_show_cancel_charge
    period_cancel_without_charge
    updated_at
  }
  room_type_rates {
    created_at
    id
    price
    room_rate_id
    room_type_id
    additional_charge_per_adult
    updated_at
  }
}
`;

const GET_ONE_ROOM_RATES = gql`
{
  cancellation_policy_id
  created_at
  id
  meal_id
  name
  updated_at
  status
  is_ibe
  e_room_rate_status {
    id
    comment
  }
  meal {
    created_at
    id
    name
    price
    service_charge
    updated_at
  }
  cancellation_policy {
    after_period_cancel_charge
    created_at
    id
    name
    no_show_cancel_charge
    period_cancel_without_charge
    updated_at
  }
  room_type_rates {
    created_at
    id
    price
    room_rate_id
    room_type_id
    additional_charge_per_adult
    updated_at
  }
}
`;

export { GET_LIST_ROOM_RATES, GET_ONE_ROOM_RATES }
