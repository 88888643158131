import gql from "graphql-tag";

const cmClient = gql`
  {
    id
    login
    password
    status
    cm_client_id
    cm_client {
      name
      status
    }
  }
`;

export const GET_LIST_CM_CLIENTS = cmClient;
export const GET_ONE_CM_CLIENTS = cmClient;
