import React from 'react';
import { Create } from 'react-admin';
import UserForm from './UserForm';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";

const UserCreate = props => (
  <div>
    {/* <Breadcrumbs aria-label="breadcrumb" style={{
      borderRadius: 5,
      background: "white",
      padding: 12,
      border: "1px solid #e0e0e3",
      marginTop: 12,
    }}
    >
      <Link color="inherit" href="/users">
        Users
      </Link>
      <Link style={{ fontWeight: "bold" }} color="textPrimary" aria-current="page" disabled>
        User
      </Link>
    </Breadcrumbs> */}
    <Create {...props} component="div">
      <UserForm />
    </Create>
  </div>

);

export default UserCreate;
