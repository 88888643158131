import React, { useContext, useEffect } from "react";
import {
  FormWithRedirect,
  List,
  Datagrid,
  EditButton,
  SearchInput,
  FunctionField,
  useTranslate,
  Title,
} from "react-admin";
import { roomCleanStatus } from "../../models/roomCleanStatus";
import { makeStyles } from "@material-ui/core/styles";
import { useNotify, useRedirect } from "react-admin";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  Typography,
  Box,
  InputAdornment,
  Button,
  TextField,
} from "@material-ui/core";
import moment from "moment";
import { getFirstLastDayInCurrentMonth } from "../../utils/date";
import { formatter } from "../../utils/formatter";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import EditIcon from "@material-ui/icons/Edit";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import { reportApi } from "../../api/reportApi";

const useStyles = makeStyles((theme) => ({
  formGrid: {
    paddingTop: "0!important",
    paddingBottom: "0!important",
  },
  formInput: {
    marginTop: "0!important",
    marginBottom: "0!important",
  },
  textBold: {
    fontWeight: "bold",
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  subCardWrap: {
    minHeight: 260,
  },
}));

const BookingBalanceReport = () => {
  const notify = useNotify();
  const classes = useStyles();

  const [bookingBalanceData, setBookingBalanceData] = React.useState([]);

  const authInfo = JSON.parse(localStorage.getItem("auth"));
  useEffect(() => searchReport(), []);

  const searchReport = () => {
    reportApi
      .getBookingBalanceReport(authInfo.tenant.id)
      .then(({ data }) =>
        setBookingBalanceData(data.get_booking_balance_report)
      )
      .catch((error) => notify(error));
  };

  return (
    <div>
      <Title title={"Booking Balance"} />
      <Grid
        container
        spacing={2}
        style={{
          marginTop: 12,
        }}
      >
        <Grid item xs={12}>
          {reportList(bookingBalanceData)}
        </Grid>
      </Grid>
    </div>
  );

  function reportList(bookingBalanceData) {
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Booking no</TableCell>
              <TableCell>Guest</TableCell>
              <TableCell>Check in date</TableCell>
              <TableCell>Check out date</TableCell>
              <TableCell>Charged amount</TableCell>
              <TableCell>Paid amount</TableCell>
              <TableCell>Due amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bookingBalanceData?.map((row) => (
              <TableRow>
                <TableCell>{formatter.bookingNo(row.booking_no)}</TableCell>
                <TableCell>{row.guest_name}</TableCell>
                <TableCell>{row.check_in_date}</TableCell>
                <TableCell>{row.check_out_date}</TableCell>
                <TableCell>
                  {formatter.formatMoney(
                    row.charge_amount,
                    authInfo.tenant.locale.currency_code,
                    authInfo.tenant.locale.precision
                  )}
                </TableCell>
                <TableCell>
                  {formatter.formatMoney(
                    row.paid_amount + row.remaining_amount,
                    authInfo.tenant.locale.currency_code,
                    authInfo.tenant.locale.precision
                  )}
                </TableCell>
                <TableCell>
                  {formatter.formatMoney(
                    row.charge_amount - row.paid_amount,
                    authInfo.tenant.locale.currency_code,
                    authInfo.tenant.locale.precision
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
};

/*
booking_no
        guest_name
        check_in_date
        check_out_date
        charge_amount
        paid_amount
        due_amount
        id
*/
export default BookingBalanceReport;

/* 

Paid amount is: 
  Paid amount - remaining

Due amount is:
  Paid amount - charge amount   
*/
