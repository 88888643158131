import DescriptionIcon from '@material-ui/icons/Description';
import RefundList from './RefundList';
import RefundCreate from './RefundCreate';
import RefundEdit from './RefundEdit';

export default {
    list: RefundList,
    create: RefundCreate,
    edit: RefundEdit,
    icon: DescriptionIcon,
};
