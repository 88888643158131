
export const authProvider = {
    checkAuth() {
        const isAuthenticated = localStorage.getItem("auth") ? true : false;
        if (isAuthenticated)
            return Promise.resolve();

        return Promise.reject();
    },
    logout() {
        if (window.$chatwoot) {
            window.$chatwoot.reset()
        }
        localStorage.removeItem('bleudine-user');
        localStorage.removeItem('auth');
        localStorage.removeItem('token');
        return Promise.resolve();
    },
    getPermissions() {
        return Promise.resolve();
    }
}
