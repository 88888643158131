import React from "react";
import {
  FormWithRedirect,
  DateInput,
  TextInput,
  SelectInput,
  required,
  minLength,
  maxLength,
  email,
  useTranslate,
  ReferenceInput,
  AutocompleteInput,
  Toolbar,
  useNotify,
  useRedirect,
} from "react-admin";
import { Typography, Box } from "@material-ui/core";
import { IntlPhoneInput } from "../components/IntlPhoneInput";
import { Card, CardContent, Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import CustomSaveButton from './CustomSaveButton';
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { tenantApi } from "../../api/tenantApi";

const validateName = [required(), minLength(2), maxLength(125)];
const validateEmail = [required(), email()];

const useStyles = makeStyles((theme) => ({
  formGrid: {
    paddingTop: "0!important",
    paddingBottom: "0!important",
  },
  formInput: {
    marginTop: "0!important",
    marginBottom: "0!important",
  },
  textBold: {
    fontWeight: "bold",
  },
}));

const TenantForm = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();
  const [openDelete, setOpenDelete] = React.useState(false);
  const { source, ...rest } = props;


  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleConfirmDelete = (id) => {
    tenantApi.deleteTenant(id)
      .then((ret) => {
        redirect(`/tenants`);
        notify("Tenant deleted");
      },
        (error) => {
          notify(error);
        });
  };


  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <Card>
          <form>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    className={classes.textBold}
                    gutterBottom
                  >
                    Property Info
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    resource="tenants"
                    source="name"
                    fullWidth
                    validate={validateName}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    resource="tenants"
                    source="email"
                    type="email"
                    label="ra.column.email"
                    fullWidth
                    validate={validateEmail}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <IntlPhoneInput
                    className={classes.formInput}
                    resource="tenants"
                    source="phone_no"
                    label={translate("ra.column.phone_no")}
                    {...rest}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <DateInput
                    className={classes.formInput}
                    resource="tenants"
                    source="bizRegDate"
                    type="date"
                    label="ra.column.bizRegDate"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    resource="tenants"
                    source="bizRegNo"
                    type="text"
                    label="ra.column.bizRegNo"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    resource="tenants"
                    source="address_1"
                    label="ra.column.address_1"
                    validate={[required()]}
                    multiline
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    resource="tenants"
                    source="address_2"
                    label="ra.column.address_2"
                    multiline
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    resource="tenants"
                    source="zip_code"
                    label="ra.column.zip_code"
                    validate={[required()]}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    resource="tenants"
                    source="region"
                    label="ra.column.region"
                    validate={[required()]}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    resource="tenants"
                    source="city"
                    label="ra.column.city"
                    validate={[required()]}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <ReferenceInput
                    className={classes.formInput}
                    source="country"
                    reference="e_countries"
                    sort={{ field: "comment", order: "ASC" }}
                    filterToQuery={(searchText) => ({
                      comment: searchText,
                    })}
                    label="ra.column.country"
                    validate={[required()]}
                    fullWidth
                  >
                    <AutocompleteInput
                      optionText="comment"
                      options={{ InputProps: { autoComplete: "no" } }}
                    />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <ReferenceInput
                    fullWidth
                    className={classes.formInput}
                    label="Currency"
                    source="locale_id"
                    reference="locales"
                    validate={[required()]}
                    disabled={
                      props.record?.name != null
                    }
                  >
                    <SelectInput optionText="currency_code" />
                  </ReferenceInput>
                </Grid>
                <br />
                <br />
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    resource="tenants"
                    source="bank_name"
                    label="ra.column.bank_name"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    resource="tenants"
                    source="bank_account_no"
                    label="ra.column.bank_account_no"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextInput
                    className={classes.formInput}
                    resource="tenants"
                    source="service_charge"
                    label="ra.column.vat"
                    validate={[required()]}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <ReferenceInput
                    className={classes.formInput}
                    fullWidth
                    label="Status"
                    source="status"
                    reference="e_tenant_status"
                    validate={[required()]}
                  >
                    <SelectInput optionText="comment" />
                  </ReferenceInput>
                </Grid>
              </Grid>
            </CardContent>
            <Toolbar>
              <Box display="flex" justifyContent="space-between" width="100%">
                {/* <SaveButton {...formProps} /> */}
                <CustomSaveButton {...formProps} />
                {Object.keys(props.record).length === 0 ?
                  null :
                  <Button
                    style={{ color: "red" }}
                    variant="outline"
                    color="primary"
                    startIcon={<DeleteIcon />}
                    onClick={handleOpenDelete}
                  >
                    Delete
                  </Button>}
              </Box>
              <Dialog
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Confirm Delete?"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Please confirm to delete
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => handleCloseDelete()} color="primary">
                    Cancel
                  </Button>
                  <Button
                    onClick={() => handleConfirmDelete(formProps.record.id)}
                    color="primary"
                    autoFocus
                  >
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
            </Toolbar>
          </form>
        </Card>
      )}
    />
  );
};

export default TenantForm;
