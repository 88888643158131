import React, { createContext, useContext, useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import { Box, emphasize, List, ListItem, Table, TableCell, TableRow, TableHead, Typography, TableBody, TableContainer, Divider, Button } from '@material-ui/core'
import EChartsReact from 'echarts-for-react'
import echart from 'echarts/lib/echarts';
import { DataProviderContext } from "react-admin";
import { allBookingsAsOfDate, allBookingStatus, guestSummary, getMaintenanceByRoomIds, allAccommodationAsOfDate, occupancyStatistics, serviceSalesStats, roomRevenueStats } from '../api/dashboardApi'
import moment from 'moment'
import { formatter } from '../utils/formatter'
import ico_stay from "../images/ico_stay.png";
import ico_departure from "../images/ico_departure.png";
import ico_arrivals from "../images/ico_arrivals.png";
import ico_arrivals_no_background from "../images/ico_arrivals_no_background.png";
import ico_stay_no_background from "../images/ico_stay_no_background.png";
import ico_departure_no_background from "../images/ico_departure_no_background.png";
import AddIcon from "@material-ui/icons/Add";

const BIContext = createContext(undefined)

const useStyle = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        overflowY: 'auto'
    },
    chart_header: {
        borderBottom: `solid 1px ${theme.palette.grey[400]}`
    },
    chart_container: {
        padding: '1rem'
    },
    padding: {
        padding: '1rem'
    }
}))

function BookingInsight() {
    const classes = useStyle()
    const { authInfo } = useContext(BIContext);
    const [data, setData] = useState([])
    useEffect(() => {
        const whitelisted = ['New', 'Confirmed', 'Checked_In', 'Checked_Out']
        allBookingStatus().then(bookingStatusResponse => {
            allBookingsAsOfDate(authInfo.tenant_id, new Date().toISOString().substring(0, 10))
                .then(res => {
                    const allStatus = bookingStatusResponse.data.e_booking_status.filter(status => whitelisted.find(s => s == status.id));
                    const bookingData = res.data.bookings;
                    setData(allStatus.map((status) => ({ name: status.comment, value: bookingData.filter(booking => booking.status == status.id).length })))
                })
        })
    }, [])
    const width = 300, height = 300, offset = 50;
    const options = {
        legend: { type: 'plain' },
        width: width,
        height: height,
        series: [
            {
                left: offset / 2,
                top: offset / 2,
                data: data,
                type: 'pie',
                radius: ['50%', '80%'],
                smooth: false,
                avoidLabelOverlap: false,
                emphasize: {
                    label: {
                        show: true,
                        fontSize: 40,
                        position: "center"
                    },
                },
                labelLine: {
                    show: false,
                },
                label: {
                    show: false,
                    position: 'center'
                },

            },
        ],

        tooltip: {
            trigger: "item"
        }
    };
    return <Paper><div className={classes.chart_container} style={{ minHeight: "30rem" }}>
        <header className={classes.chart_header}>
            <Typography variant={'h5'} style={{ marginBottom: 5 }}>Bookings
                <Button
                    style={{ float: "right", color: "#4f3cc9" }}
                    variant="outline"
                    color="primary"
                    startIcon={<AddIcon />}
                    href="/bookings/create"
                    target="_blank"
                >
                </Button>
            </Typography>
        </header>
        <Box display="flex" flexDirection="row" minHeight={"20rem"}>
            <Grid container flexGrow={1} border={'1px solid'} style={{ height: "100%", placeContent: 'center' }} marginY={'0.2rem'} display="flex" flexDirection={"column"}>
                <Grid item flexGrow={1}>
                    <EChartsReact opts={{ renderer: 'svg', width: width + offset, height: height + offset }} style={{ width: "100%", padding: "2rem", minHeight: '25vh', border: 'none' }} option={options} echarts={echart} />
                </Grid>
                <Grid item flexGrow={0} container alignItems='center' justifyContent='center'>
                    <List style={{ width: "100%", paddingLeft: "0.5rem" }}>
                        {data.map(record => (<ListItem key={record.name}>
                            <Box display="flex" gridGap={4} width={"100%"} justifyContent={"space-between"} alignItems={"center"}>
                                <Typography>{record.name}</Typography>
                                <Typography>{record.value}</Typography>
                            </Box>
                        </ListItem>))}
                    </List>
                </Grid>
            </Grid>
        </Box>
    </div>
    </Paper>

}

function GuestSummary() {
    const { authInfo } = useContext(BIContext);
    const [summary, setSummary] = useState(undefined)
    useEffect(() => {
        // var date = new Date();
        // date.setDate(date.getDate() - 3)
        guestSummary(new Date(), authInfo.tenant.id).then(setSummary)
    }, [])
    return <Grid style={{ paddingTop: '1rem' }} container direction='row'>
        <Grid item xs={4}>
            <Grid container direction='row'>
                <Grid item xs={2} style={{ alignContent: 'center' }}>
                    <img src={ico_arrivals} alt="ico_arrivals" width={30} height={30} />
                </Grid>
                <Grid item xs={10}>
                    <Typography variant={"h6"}>Arriving</Typography>
                </Grid>
            </Grid>

            {summary?.arriving.length === 0 ?
                (
                    <Grid container direction='row'>
                        <Box component="section" sx={{ p: 5, borderStyle: 'solid', border: '1px lightGrey', margin: 5, textAlign: 'center', borderRadius: 5 }}>
                            <img src={ico_arrivals_no_background} alt="ico_arrivals_no_background" width={30} height={30} />
                            <br />
                            <Typography style={{ fontWeight: 'bold', color: '#9BA6B5' }}>No Result.</Typography>
                        </Box>
                    </Grid>
                )
                : null
            }

            <List>
                {summary ? summary.arriving.map(b =>
                    <Box>
                        <Typography style={{ fontWeight: 'bold' }}>{b.name}</Typography>
                        <Typography>#{formatter.bookingNo(b.booking_no)}</Typography>
                        <br />
                    </Box>
                ) : undefined}
            </List>

        </Grid>
        <Grid item xs={4}>
            <Grid container direction='row'>
                <Grid item xs={2} style={{ alignContent: 'center' }}>
                    <img src={ico_departure} alt="ico_departure" width={30} height={30} />
                </Grid>
                <Grid item xs={10}>
                    <Typography variant={"h6"}>Departing</Typography>
                </Grid>
            </Grid>

            {summary?.departing.length === 0 ?
                (
                    <Grid container direction='row'>
                        <Box component="section" sx={{ p: 5, borderStyle: 'solid', border: '1px lightGrey', margin: 5, textAlign: 'center', borderRadius: 5 }}>
                            <img src={ico_departure_no_background} alt="ico_departure_no_background" width={30} height={30} />
                            <br />
                            <Typography style={{ fontWeight: 'bold', color: '#9BA6B5' }}>No Result.</Typography>
                        </Box>
                    </Grid>
                )
                : null
            }

            <List>
                {summary ? summary.departing.map(b => <Box>
                    <Typography style={{ fontWeight: 'bold' }}>{b.name}</Typography>
                    <Typography>#{formatter.bookingNo(b.booking_no)}</Typography>
                    <br />
                </Box>) : undefined}
            </List>
        </Grid>
        <Grid item xs={4}>
            <Grid container direction='row'>
                <Grid item xs={2} style={{ alignContent: 'center' }}>
                    <img src={ico_stay} alt="ico_stay" width={30} height={30} />
                </Grid>
                <Grid item xs={10}>
                    <Typography variant={"h6"}>Stays</Typography>
                </Grid>
            </Grid>


            {summary?.staying.length === 0 ?
                (
                    <Grid container direction='row'>
                        <Box component="section" sx={{ p: 5, borderStyle: 'solid', border: '1px lightGrey', margin: 5, textAlign: 'center', borderRadius: 5 }}>
                            <img src={ico_stay_no_background} alt="ico_stay_no_background" width={30} height={30} />
                            <br />
                            <Typography style={{ fontWeight: 'bold', color: '#9BA6B5' }}>No Result.</Typography>
                        </Box>
                    </Grid>
                )
                : null
            }

            <List>
                {summary ? summary.staying.map(b =>
                    <Box>
                        <Typography style={{ fontWeight: 'bold' }}>{b.name}</Typography>
                        <Typography>#{formatter.bookingNo(b.booking_no)}</Typography>
                        <br />
                    </Box>) : undefined}
            </List>
        </Grid>
    </Grid>
}

function ServiceSalesStats() {
    const { authInfo } = useContext(BIContext);
    const [data, setData] = useState([])
    useEffect(() => {
        serviceSalesStats(new Date(), authInfo.tenant.id).then(setData);
    }, [])

    return <TableContainer style={{ paddingTop: '1rem' }} component={Box}>
        <Table size='small'>
            <TableHead>
                <TableRow>
                    <TableCell>
                        <Typography>Service</Typography>
                    </TableCell>

                    <TableCell>
                        <Typography>Total order</Typography>
                    </TableCell>

                    <TableCell>
                        <Typography>Total earning</Typography>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map(record => (<TableRow key={record.service}>
                    <TableCell>{record.service}</TableCell>
                    <TableCell>{record.total_order}</TableCell>
                    <TableCell>{formatter.formatMoney(
                        record.total_earning,
                        authInfo.tenant.locale.currency_code,
                        authInfo.tenant.locale.precision
                    )}</TableCell>


                </TableRow>))}
            </TableBody>
        </Table>
    </TableContainer>
}

function RoomRevenueStats() {
    const { authInfo } = useContext(BIContext);

    const [data, setData] = useState([])
    useEffect(() => {
        roomRevenueStats(new Date(), authInfo.tenant.id).then(setData)
    }, [])

    return <Grid item>
        <TableContainer style={{ paddingTop: '1rem' }} component={Box}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Revenue</TableCell>
                        <TableCell>RevPAR</TableCell>
                        <TableCell>ADR</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        data.map(record => (<TableRow key={record.date}>
                            <TableCell>{record.date}</TableCell>
                            <TableCell>{record.revenue}</TableCell>
                            <TableCell>{record.revpar}</TableCell>
                            <TableCell>{record.adr}</TableCell>

                        </TableRow>))

                    }
                </TableBody>
            </Table>

        </TableContainer>
    </Grid>
}

function HouseKeepingOverview() {
    const { dataProvider, authInfo } = useContext(BIContext)
    const [data, setData] = useState([])
    const width = 300, height = 300, offset = 50;
    const options = {
        legend: { type: 'plain' },
        width: width,
        height: height,
        series: [
            {
                left: offset / 2,
                top: offset / 2,
                data: data,
                type: 'pie',
                radius: ['50%', '80%'],
                smooth: false,
                avoidLabelOverlap: false,
                emphasize: {
                    label: {
                        show: true,
                        fontSize: 40,
                        position: "center"
                    },
                },
                labelLine: {
                    show: false,
                },
                label: {
                    show: false,
                    position: 'center'
                },

            },
        ],

        tooltip: {
            trigger: "item"
        }
    };
    useEffect(() => {
        var roomRequest = {
            tenant_id: authInfo.tenant.id,
        };
        dataProvider.getList("e_room_clean_status", { filter: {} }).then(({ data }) => {
            dataProvider.getList("rooms", { filter: { ...roomRequest } }).then(res => {
                const rooms = res.data;
                setData(data.map(status => ({ name: status.comment, value: rooms.filter(room => room.clean_status == status.id).length })))
            })
        })
    }, [])
    return <Grid container direction={'column'}>
        <Grid item style={{ alignSelf: 'center' }}>
            <EChartsReact option={options} opts={{ width, height, renderer: 'svg' }} />
        </Grid>
        <Grid item>
            <List>
                {data.map(record => (<ListItem key={record.name}>
                    <Box display='flex' style={{ width: '100%' }} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography>{record.name}</Typography>
                        <Typography>{record.value}</Typography>
                    </Box>
                </ListItem>))}
            </List>
        </Grid>
    </Grid>
}


function RoomStatusBreakdown() {
    const { dataProvider, authInfo } = useContext(BIContext);
    const [data, setData] = useState([])
    useEffect(() => {
        dataProvider.getList("room_types", { filter: { tenant_id: authInfo.tenant.id } }).then(({ data }) => {
            const room_types = data.map(room_type => ({ name: room_type.name, id: room_type.id }));
            dataProvider.getList("rooms", { filter: { tenant_id: authInfo.tenant.id, status: 'Active' } })
                .then(res => {
                    const rooms = res.data;
                    getMaintenanceByRoomIds(rooms.map(r => r.id)).then(({ data }) => {
                        const { maintenances } = data
                        allAccommodationAsOfDate(new Date().toISOString().substring(0, 10)).then(({ data }) => {
                            const { accommodations } = data;
                            const aggregated = room_types.map((room_type) => {
                                let today = moment(new Date())
                                const validOoo = (m) => {

                                    return m.room.room_type.id == room_type.id && (!m.start_date || moment(m.start_date).isSameOrBefore(today, 'day')) && (!m.end_date || moment(m.end_date).isSameOrAfter(today, 'day'))
                                }
                                const outOfOrder = maintenances.filter(validOoo).length
                                const sold = accommodations.filter(ac => ac.room_type.id == room_type.id).length
                                const total = rooms.filter(r => r.room_type.id == room_type.id).length
                                const avail = total - outOfOrder - sold
                                return { name: room_type.name, outOfOrder, sold, available: avail, total }
                            })
                            setData(aggregated)

                        })
                    })
                })
        });

    }, [])
    return <TableContainer component={Box} style={{ padding: '2rem', width: 'auto' }}>
        <Table size='small'>
            <TableHead>
                <TableRow>
                    <TableCell><Typography align="center">Room Type</Typography></TableCell>
                    <TableCell><Typography align="center">Available</Typography></TableCell>
                    <TableCell><Typography align="center">Sold</Typography></TableCell>
                    <TableCell><Typography align="center">Maintenance</Typography></TableCell>
                    <TableCell><Typography align="center">Total</Typography></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map(record => (<TableRow key={record.name}>
                    <TableCell><Typography align="center">{record.name}</Typography></TableCell>
                    <TableCell><Typography align="center">{record.available}</Typography></TableCell>
                    <TableCell><Typography align="center">{record.sold}</Typography></TableCell>
                    <TableCell><Typography align="center">{record.outOfOrder}</Typography></TableCell>
                    <TableCell><Typography align="center">{record.total}</Typography></TableCell>
                </TableRow>))
                }
            </TableBody >
        </Table >
    </TableContainer>
}


function OccupancyStatistic() {
    const { authInfo } = useContext(BIContext)
    const [statistics, setStatistics] = useState([])
    const [data, setData] = useState([])
    const [xAxis, setXAxis] = useState([])
    const [dateRange, setDateRange] = useState([])
    useEffect(() => {
        const range = [0, 1, 2, 3, 4, 5, 6]
        const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
        setDateRange(range.map(offset => {
            return moment(new Date()).add(offset, 'day').toDate()
        }))
        setXAxis(range.map(offset => {
            let value = moment(new Date()).add(offset, 'day');
            return `${days[value.day()]}\n${value.format("MM/DD")}`
        }))
    }, [])

    useEffect(() => {
        if (dateRange.length > 0) {
            occupancyStatistics(dateRange[0], dateRange[dateRange.length - 1], authInfo.tenant.id).then(setStatistics)
        }

    }, [dateRange])
    useEffect(() => {
        if (statistics) {
            setData(statistics.map(stats => stats.occupancy))
        }
    }, [statistics])
    const width = 900, height = 250, offset = 100;
    const options = {
        legend: { type: 'plain' },
        width: width - offset,
        height: height - offset,
        xAxis: {
            type: 'category',
            splitNumber: 7,
            data: xAxis,
            maxInterval: 0
        },
        yAxis: {
            formatter: "{value} %",
            max: 100
        },
        series: [
            {
                barWidth: 35,
                barGap: 1,
                data: data,
                type: 'bar',
                smooth: false,
            },
        ],

        tooltip: {
            trigger: "item"
        }
    };
    return <Grid container direction="column" style={{ height: '100%' }}>
        <Grid item>
            <EChartsReact option={options} opts={{ renderer: 'svg', height: height, padding: '1rem' }} />
        </Grid>
        <Grid item style={{ width: 'inherit' }}>
            <Table size='small'>
                <TableHead>
                    <TableRow>
                        <TableCell><Typography>Date</Typography></TableCell>
                        <TableCell><Typography>Occupancy %</Typography></TableCell>
                        <TableCell><Typography>Trend %</Typography></TableCell>
                        <TableCell><Typography>Arrival</Typography></TableCell>
                        <TableCell><Typography>Depature</Typography></TableCell>
                        <TableCell><Typography>Guest in house</Typography></TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {statistics.map(stats => (<TableRow key={stats.date}>
                        <TableCell><Typography>{stats.date}</Typography></TableCell>
                        <TableCell><Typography>{stats.occupancy !== "NaN" ? stats.occupancy : 0}</Typography></TableCell>
                        <TableCell><Typography>{stats.trend !== "NaN" ? stats.trend : 0}</Typography></TableCell>
                        <TableCell><Typography>{stats.arrival}</Typography></TableCell>
                        <TableCell><Typography>{stats.departure}</Typography></TableCell>
                        <TableCell><Typography>{stats.stays}</Typography></TableCell>
                    </TableRow>))}
                </TableBody>
            </Table>
        </Grid>
    </Grid >
}

export default function BusinessInsight() {
    const authInfo = JSON.parse(localStorage.getItem("auth"))
    const dataProvider = useContext(DataProviderContext)
    const [date, setDate] = useState(new Date())
    const classes = useStyle()
    useEffect(() => {
        const handler = setInterval(() => {
            setDate(new Date())
        }, 1000)
        return () => clearInterval(handler);
    }, [])
    return <BIContext.Provider value={{ authInfo, dataProvider }}>
        <div className={classes.root}>
            <div className={classes.padding}>
                <Grid container spacing={4}>

                    <Grid xs={12} item justifyContent='flex-end' container>
                        <Box display="flex" flexDirection={"row"} justifyContent={"flex-end"} alignItems={'center'} style={{ width: "100%" }} gridGap={13}>
                            <Typography variant="h6">Date</Typography>
                            <Typography variant="h6">{date.toLocaleString()}</Typography>
                        </Box>

                    </Grid>
                    <Grid xs={6} item>
                        <BookingInsight />
                    </Grid>
                    <Grid xs={6} item>
                        <Paper>
                            <div className={classes.chart_container}>
                                <header className={classes.chart_header}>
                                    <Typography variant={'h5'} style={{ marginBottom: 5 }}>Guest Management</Typography>
                                </header>
                                <GuestSummary />
                            </div>
                        </Paper>
                    </Grid>
                    <Grid xs={6} item>
                        <Paper>
                            <div className={classes.chart_container}>
                                <header className={classes.chart_header}>
                                    <Typography variant={'h5'} style={{ marginBottom: 5 }}>
                                        Housekeeping
                                        <Button
                                            style={{ float: "right", color: "#4f3cc9" }}
                                            variant="outline"
                                            color="primary"
                                            href="/housekeeping"
                                            target="_blank"
                                        >
                                            Show all
                                        </Button>

                                    </Typography>
                                </header>
                                <HouseKeepingOverview />
                            </div>
                        </Paper>
                    </Grid>
                    <Grid xs={6} item>
                        <Paper>
                            <div className={classes.chart_container}>
                                <header className={classes.chart_header}>
                                    <Typography variant={'h5'} style={{ marginBottom: 5 }}>Rooms</Typography>
                                </header>
                                <RoomStatusBreakdown />
                            </div>
                        </Paper>
                    </Grid>
                    <Grid xs={8} item>
                        <Paper>
                            <div className={classes.chart_container}>
                                <header className={classes.chart_header}>
                                    <Typography variant={'h5'} style={{ marginBottom: 5 }}>Rooms</Typography>
                                </header>
                                <OccupancyStatistic />
                            </div>
                        </Paper>
                    </Grid>
                    <Grid xs={4} item>
                        <Grid container direction='column' gap={4}>
                            <Grid item>
                                <Paper>
                                    <div className={classes.chart_container}>
                                        <header className={classes.chart_header}>
                                            <Typography variant={'h5'} style={{ marginBottom: 5 }}>Service Sales</Typography>
                                        </header>
                                        <ServiceSalesStats />
                                    </div>
                                </Paper>
                            </Grid>
                            <Grid item>
                                <Paper>
                                    <div className={classes.chart_container}>
                                        <header className={classes.chart_header}>
                                            <Typography variant={'h5'} style={{ marginBottom: 5 }}>Room Revenues</Typography>
                                        </header>
                                        <RoomRevenueStats />
                                    </div>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>


                </Grid>
            </div>
        </div>
    </BIContext.Provider>
}
