import React from "react";
import { Create } from "react-admin";
import CM2FASessionsForm from "./CM2FASessionsForm";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";

const CM2FASessionsCreate = (props) => (
  <div>
    <Breadcrumbs
      aria-label="breadcrumb"
      style={{
        borderRadius: 5,
        background: "white",
        padding: 12,
        border: "1px solid #e0e0e3",
        //marginLeft: 16,
        //marginRight: 30,
        marginTop: 12,
      }}
    >
      <Link color="inherit" href="/cm_2fa_sessions">
        2FA Sessions
      </Link>
      <Link
        style={{ fontWeight: "bold" }}
        color="textPrimary"
        aria-current="page"
        disabled
      >
        Session
      </Link>
    </Breadcrumbs>
    <Create {...props} component="div">
      <CM2FASessionsForm />
    </Create>
  </div>
);

export default CM2FASessionsCreate;
