import React, { useContext } from 'react';
import {
  SaveButton
} from 'react-admin';
import { useFormState } from 'react-final-form';
import { useNotify, useRedirect } from 'react-admin';
import { DataProviderContext } from 'react-admin';


const checkDuplicateRoom = (rooms) => {
  if (rooms && rooms.length > 0) {
    var valueArr = rooms.map(function (item) { return item.name });
    var isDuplicate = valueArr.some(function (item, idx) {
      return valueArr.indexOf(item) != idx
    });
    return isDuplicate;
  } else {
    return false
  }
}

const CustomCreateButton = (formProps) => {
  const authInfo = JSON.parse(localStorage.getItem('auth'));
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useContext(DataProviderContext);
  let { values } = useFormState();
  const [saving, setSaving] = React.useState(false);

  const handleSubmit = () => {
    setSaving(true);

    if (checkDuplicateRoom(values.room_rooms)) {
      notify("Duplicate room name");
      setSaving(false);
    } else {
      var promises = [];
      var successes = [];
      var errors = [];
      values.room_rooms.forEach(function (element) {
        promises.push(
          dataProvider.create(formProps.resource, {
            data: {
              name: element.name, floor: values.floor, room_type_id: values.room_type_id, status: "Active", clean_status: "Clean", tenant_id: authInfo.tenant.id
            }
          })
            .then(({ data }) => {
              successes.push(data);
            })
            .catch(error => {
              let message = "Something went wrong.";

              if (error?.message.includes("duplicate") && error?.message.includes("rooms_name_tenant_id_key")) {
                message = "Fail to create room, name already been used.";
              }
    
              notify(message, 'error');
              errors.push(error)
            })
        );
      });

      Promise.all(promises).then(() => {
        if (values.room_rooms.length == successes.length) {
          redirect(`/rooms`);
          notify("Rooms created");
        } else {
          setSaving(false);
          notify("Error when create rooms");
        };
      }
      );
    }

  }

  return <SaveButton {...formProps} saving={saving} disabled={saving} onSave={handleSubmit} />;
};

export default CustomCreateButton;