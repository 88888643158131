import React from 'react';
import { Edit } from 'react-admin';
import RoomEditForm from './RoomEditForm';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";

const RoomTitle = ({ record }) => {
  return <span>Room {record ? `${record.name}` : ''}</span>
};

const RoomEdit = props => (
  <div>
    <Breadcrumbs aria-label="breadcrumb" style={{
      borderRadius: 5,
      background: "white",
      padding: 12,
      border: "1px solid #e0e0e3",
      //marginLeft: 16,
      //marginRight: 30,
      marginTop: 12,
    }}>
      <Link color="inherit" href="/rooms">
        Rooms
      </Link>
      <Link style={{ fontWeight: "bold" }} color="textPrimary" aria-current="page" disabled>
        Room
      </Link>
    </Breadcrumbs>
    <Edit title={<RoomTitle />} {...props} component="div">
      <RoomEditForm />
    </Edit>
  </div>
);


export default RoomEdit;
