import gql from 'graphql-tag';

const GET_LIST_ROOM_TYPE_RATES = gql`
{
  updated_at
  room_type_id
  room_rate_id
  price
  id
  created_at
  room_rate {
    cancellation_policy_id
    created_at
    id
    meal_id
    name
    updated_at
    status
    meal {
      updated_at
      service_charge
      price
      name
      id
      created_at
    }
    cancellation_policy {
      updated_at
      period_cancel_without_charge
      no_show_cancel_charge
      name
      id
      created_at
      after_period_cancel_charge
    }
  }
}
`;

const GET_ONE_ROOM_TYPE_RATES = gql`
{
  updated_at
  room_type_id
  room_rate_id
  price
  id
  created_at
  room_rate {
    cancellation_policy_id
    created_at
    id
    meal_id
    name
    updated_at
    status
    meal {
      updated_at
      service_charge
      price
      name
      id
      created_at
    }
    cancellation_policy {
      updated_at
      period_cancel_without_charge
      no_show_cancel_charge
      name
      id
      created_at
      after_period_cancel_charge
    }
  }
}
`;

export{GET_LIST_ROOM_TYPE_RATES, GET_ONE_ROOM_TYPE_RATES}
