import React from 'react';
import { Create } from 'react-admin';
import CancelCreateForm from './CancelCreateForm';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";

const CancelCreate = props => (
  <div>
    <Breadcrumbs aria-label="breadcrumb" style={{
      borderRadius: 5,
      background: "white",
      padding: 12,
      border: "1px solid #e0e0e3",
      //marginLeft: 16,
      //marginRight: 30,
      marginTop: 12,
    }}>
      <Link color="inherit" href="/cancellation_policies">
        Cancellations
      </Link>
      <Link style={{ fontWeight: "bold" }} color="textPrimary" aria-current="page" disabled>
        Cancellation
      </Link>
    </Breadcrumbs>
    <Create {...props} component="div">
      <CancelCreateForm />
    </Create>
  </div>

);

export default CancelCreate;
