export const englishMessages = {
  ra: {
    action: {
      add_filter: "Add filter",
      add: "Add",
      back: "Go Back",
      bulk_actions: "1 item selected |||| %{smart_count} items selected",
      cancel: "Cancel",
      clear_input_value: "Clear value",
      clone: "Clone",
      confirm: "Confirm",
      create: "Create",
      create_item: "Create %{item}",
      delete: "Delete",
      edit: "Edit",
      export: "Export",
      list: "List",
      refresh: "Refresh",
      remove_filter: "Remove this filter",
      remove: "Remove",
      save: "Save",
      search: "Search",
      show: "Show",
      sort: "Sort",
      undo: "Undo",
      unselect: "Unselect",
      expand: "Expand",
      close: "Close",
      open_menu: "Open menu",
      close_menu: "Close menu",
      update: "Update",
    },
    boolean: {
      true: "Yes",
      false: "No",
      null: " ",
    },
    page: {
      create: "Create %{name}",
      dashboard: "Dashboard",
      edit: "%{name} #%{id}",
      error: "Something went wrong",
      list: "%{name}",
      loading: "Loading",
      not_found: "Not Found",
      show: "%{name} #%{id}",
      empty: "No %{name} yet.",
      invite: "Do you want to add one?",
    },
    input: {
      file: {
        upload_several: "Drop some files to upload, or click to select one.",
        upload_single: "Drop a file to upload, or click to select it.",
      },
      image: {
        upload_several: "Drop some pictures to upload, or click to select one.",
        upload_single: "Drop a picture to upload, or click to select it.",
      },
      references: {
        all_missing: "Unable to find references data.",
        many_missing:
          "At least one of the associated references no longer appears to be available.",
        single_missing:
          "Associated reference no longer appears to be available.",
      },
      password: {
        toggle_visible: "Hide password",
        toggle_hidden: "Show password",
      },
    },
    message: {
      about: "About",
      are_you_sure: "Are you sure?",
      bulk_delete_content:
        "Are you sure you want to delete this %{name}? |||| Are you sure you want to delete these %{smart_count} items?",
      bulk_delete_title: "Delete %{name} |||| Delete %{smart_count} %{name}",
      bulk_update_content:
        "Are you sure you want to update this %{name}? |||| Are you sure you want to update these %{smart_count} items?",
      bulk_update_title: "Update %{name} |||| Update %{smart_count} %{name}",
      delete_content: "Are you sure you want to delete this item?",
      delete_title: "Delete %{name} #%{id}",
      details: "Details",
      error: "A client error occurred and your request couldn't be completed.",
      invalid_form: "The form is not valid. Please check for errors",
      loading: "The page is loading, just a moment please",
      no: "No",
      not_found: "Either you typed a wrong URL, or you followed a bad link.",
      yes: "Yes",
      unsaved_changes:
        "Some of your changes weren't saved. Are you sure you want to ignore them?",
    },
    navigation: {
      no_results: "No results found",
      no_more_results:
        "The page number %{page} is out of boundaries. Try the previous page.",
      page_out_of_boundaries: "Page number %{page} out of boundaries",
      page_out_from_end: "Cannot go after last page",
      page_out_from_begin: "Cannot go before page 1",
      page_range_info: "%{offsetBegin}-%{offsetEnd} of %{total}",
      page_rows_per_page: "Rows per page:",
      next: "Next",
      prev: "Prev",
      skip_nav: "Skip to content",
    },
    sort: {
      sort_by: "Sort by %{field} %{order}",
      ASC: "ascending",
      DESC: "descending",
    },
    auth: {
      auth_check_error: "Please login to continue",
      user_menu: "Profile",
      username: "Username",
      password: "Password",
      sign_in: "Sign in",
      sign_in_error: "Authentication failed, please retry",
      logout: "Logout",
    },
    notification: {
      updated: "Element updated |||| %{smart_count} elements updated",
      created: "Element created",
      deleted: "Element deleted |||| %{smart_count} elements deleted",
      bad_item: "Incorrect element",
      item_doesnt_exist: "Element does not exist",
      http_error: "Server communication error",
      data_provider_error: "dataProvider error. Check the console for details.",
      i18n_error: "Cannot load the translations for the specified language",
      canceled: "Action cancelled",
      logged_out: "Your session has ended, please reconnect.",
      not_authorized: "You're not authorized to access this resource.",
    },
    validation: {
      required: "Required",
      minLength: "Must be %{min} characters at least",
      maxLength: "Must be %{max} characters or less",
      minValue: "Must be at least %{min}",
      maxValue: "Must be %{max} or less",
      number: "Must be a number",
      email: "Must be a valid email",
      oneOf: "Must be one of: %{options}",
      regex: "Must match a specific format (regexp): %{pattern}",
    },
    label: {
      property: "Property",
      company: "Company",
      companies: "Companies",
      guest: "Guest",
      guests: "Guests",
      personal_info: "Personal Info",
      contact_info: "Contact Info",
      legal_info: "Legal Info",
      housekeeping: "Housekeeping",
      orders: "Orders",
      accommodations: "Accommodations",
      maintenances: "Maintenances",
      order_info: "Order Info",
      accommodation_info: "Accommodation Info",
      guest_info: "Guest Info",
      cockpit: "Frontdesk",
      booking: "Booking",
      bookings: "Bookings",
      accommodations: "Accommodations",
      deposits: "Deposits",
      refunds: "Refunds",
      invoices: "Invoices",
      customer: "Customer",
      configuration: "Configuration",
      room_types: "Room Type",
      room_rates: "Room Rate",
      dynamic_rates:"Dynamic rates",
      rooms: "Rooms",
      setting: "Setting",
      name: "Name",
      light: "Light",
      dark: "Dark",
      home: "Back to Home",
      connect_rideum: "Connect Bleustay",
      connect: "Connect",
      users: "Users",

      /* Room  Serivce */
      room_services: "Services & Meals",
      room_service: "Services",

      /* Channal Manager */
      channel_manager: "Channel Manager",
      accounts: "Accounts",
      fASessions: "2FA Sessions",
      client_info: "Client Info",
      client_password: "Password",
      client_status: "Status",
      client_name: "Client Name",
      client_code: "OTP",
      session: "Session",

      /* Reports */
      reports: "Reports",
      accounting: "Accounting",
      bookingBalance: "Booking Balance",
      occupancy: "Occupancy",
      sales: "Sales",
      insight: "Dashboard",
      check_in_check_out: "Check in/Check out",
      end_of_day: "End of Day",
      end_of_day_report: "End of Day Report",
      account_balance_report: "Account balance",
      financial_report: "Financial report",
    },
    column: {
      id: "ID",
      name: "Name",
      description: "Description",
      first_name: "First name",
      last_name: "Last Name",
      gender: "Gender",
      title: "Title",
      nationality: "Nationality",
      birth_date: "Birth date",
      email: "Email",
      phone_no: "Phone number",
      address_1: "Address 1",
      address_2: "Address 2",
      region: "Region",
      city: "City",
      zip_code: "Postal code",
      country: "Country",
      document_type: "Document type",
      document_no: "Document number",
      document_expiry: "Document expiry",

      accommodation: "Accommodation",
      service: "Service",
      price: "Price",
      service_charge: "Service Charge",
      guest: "Guest",
      room_type: "Room Type",
      rate_plan: "Rate Plan",
      room_no: "Room Number",
      start_date: "Start Date",
      end_date: "End Date",
      type: "Type",
      currency: "Currency",
      status: "Status",
      bizRegDate: "Business Registration Date",
      bizRegNo: "Business Registration Number",
      vat:"VAT (%)",
      bank_name: "Bank Name",
      bank_account_no: "Bank Account No",
    },
  },
};
