import DescriptionIcon from '@material-ui/icons/Description';
import DepositList from './DepositList';
import DepositCreate from './DepositCreate';
import DepositEdit from './DepositEdit';

export default {
    list: DepositList,
    create: DepositCreate,
    edit: DepositEdit,
    icon: DescriptionIcon,
};
