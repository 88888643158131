import React from "react";
import { Login, LoginForm } from "react-admin";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import ForgotPassword from './CustomForgotPassword'
import CustomLogin from './CustomLogin'
import Grid from "@material-ui/core/Grid";
import { withStyles } from '@material-ui/core/styles';
import icLogo from '../../images/ic_logo_text.png'
import bgLogin from '../../images/bg_login.jpg'

const styles = ({
  main: { backgroundImage: `url("${bgLogin}")` },
  avatar: {
    display: 'none',
  },
  icon: { display: 'none' },
  card: {
    minWidth: 350,
    paddingTop: 40,
    paddingBottom: 40,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 5
  }
});

const CustomLoginForm = props => (
  <div>
    <Grid container style={{ margin: 15 }}>
      <Grid item xs={6}>
        <div style={{ fontSize: 24, fontWeight: "bold", marginBottom: 12 }}>Login</div>
        <div style={{ color: "#8692A6", fontSize: 14 }}>Bleustay Hotel</div>
      </Grid>
      <Grid item xs={6}>
        <img src={icLogo} style={{ width: 150 }} />
      </Grid>
    </Grid>
    <CustomLogin {...props} />
    <ForgotPassword {...props} />
  </div >
);

const LoginPage = props => (
  <Login {...props}>
    <CustomLoginForm {...props} />
  </Login>
);

export default withStyles(styles)(LoginPage);