import React, { useContext, useEffect } from "react";
import {
  FormWithRedirect,
  List,
  Datagrid,
  EditButton,
  SearchInput,
  FunctionField,
  useTranslate,
  Title,
} from "react-admin";
import { roomCleanStatus } from "../../models/roomCleanStatus";
import { makeStyles } from "@material-ui/core/styles";
import { useNotify, useRedirect } from "react-admin";
import { DataProviderContext } from "react-admin";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  Typography,
  Box,
  InputAdornment,
  Button,
  TextField,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
} from "@material-ui/core";
import moment from "moment";
import { getFirstLastDayInCurrentMonth } from "../../utils/date";
import { formatter } from "../../utils/formatter";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import EditIcon from "@material-ui/icons/Edit";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import { reportApi } from "../../api/reportApi";

const useStyles = makeStyles((theme) => ({
  formGrid: {
    paddingTop: "0!important",
    paddingBottom: "0!important",
  },
  formInput: {
    marginTop: "0!important",
    marginBottom: "0!important",
  },
  textBold: {
    fontWeight: "bold",
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  title: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },

  centerContent: {
    marginBottom: 44,
  },
  mainGrid: {
    marginTop: theme.spacing(1),
  },
}));

const AccountingReport = () => {
  const notify = useNotify();
  const classes = useStyles();

  const [initialCheckInDate, initialCheckOutDate] =
    getFirstLastDayInCurrentMonth();

  const [occupencyData, setAccountingData] = React.useState([]);
  const [checkInDate, setCheckInDate] = React.useState(initialCheckInDate);
  const [checkOutDate, setCheckOutDate] = React.useState(initialCheckOutDate);

  const authInfo = JSON.parse(localStorage.getItem("auth"));
  const currency = authInfo.tenant.locale.currency_code;

  useEffect(() => {
    searchReport();
  }, []);

  const searchReport = () => {
    reportApi
      .getAccountingReport(authInfo.tenant.id, checkOutDate, checkInDate)
      .then(({ data }) => setAccountingData(data.get_accounting_report))
      .catch((error) => notify(error));
  };

  return (
    <div>
      <Title title={"Accounting"} />
      <Grid
        container
        spacing={2}
        style={{
          marginTop: 12,
        }}
      >
        <Grid item xs={12} sm={3} className={classes.formGrid}>
          <TextField
            style={{
              marginTop: 12,
            }}
            type="date"
            variant="filled"
            className={classes.formInput}
            value={checkInDate}
            onChange={(e) => setCheckInDate(e.target.value)}
            fullWidth
            label="Start Date"
          />
        </Grid>
        <Grid item xs={12} sm={3} className={classes.formGrid}>
          <TextField
            type="date"
            variant="filled"
            className={classes.formInput}
            value={checkOutDate}
            onChange={(e) => {
              setCheckOutDate(e.target.value);
            }}
            fullWidth
            label="End Date"
          />
        </Grid>
        <Grid item xs={12} sm={3} className={classes.formGrid}>
          <Button
            style={{
              marginTop: 12,
              marginRight: 5,
            }}
            onClick={searchReport}
            variant="contained"
            color="primary"
          >
            Search
          </Button>
        </Grid>
        <Grid item xs={12}>
          {reportList(occupencyData)}
        </Grid>
      </Grid>
    </div>
  );

  function reportList(occupencyData) {
    if (
      occupencyData == null ||
      occupencyData == undefined ||
      occupencyData.length == 0
    )
      return <div></div>;

    const {
      total_cash_amount,
      total_credit_card_amount,
      total_refund_amount,
      total_room_revenue,
      total_service_charge,
      total_service_revenue,
    } = occupencyData[0];

    return (
      <Container maxWidth="lg" component="main">
        <Grid
          container
          spacing={5}
          alignItems="flex-end"
          className={classes.mainGrid}
        >
          {BuildCard("Total cash", total_cash_amount)}
          {BuildCard("Total credit card", total_credit_card_amount)}
          {BuildCard("Total refund", total_refund_amount)}
          {BuildCard("Total room revenue", total_room_revenue)}
          {BuildCard("Total service charge", total_service_charge)}
          {BuildCard("Total service revenue", total_service_revenue)}
        </Grid>
      </Container>
    );
  }

  function BuildCard(cardTitle, cardValue) {
    return (
      <Grid item xs={12} sm={12} md={3}>
        <Card>
          <CardHeader
            title={cardTitle}
            titleTypographyProps={{
              align: "center",
              component: "h3",
              variant: "h6",
              color: "#000000",
            }}
          />
          <CardContent className={classes.centerContent}>
            <div className={classes.cardPricing}>
              <Typography component="h3" variant="h5" color="textPrimary">
                {formatter.formatMoney(
                  cardValue,
                  authInfo.tenant.locale.currency_code,
                  authInfo.tenant.locale.precision
                )}
              </Typography>
            </div>
          </CardContent>
        </Card>
      </Grid>
    );
  }
};

export default AccountingReport;
