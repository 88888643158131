export const formatter = {
  bookingNo,
  depositNo,
  refundNo,
  orderNo,
  invoiceNo,
  paymentNo,
  formatMoney,
  formatMoneyNumber,
};

function bookingNo(bookingNo) {
  return "B" + String(bookingNo).padStart(10, "0");
}

function depositNo(depositNo) {
  return "D" + String(depositNo).padStart(10, "0");
}

function refundNo(refundNo) {
  return "R" + String(refundNo).padStart(10, "0");
}

function orderNo(orderNo) {
  return "O" + String(orderNo).padStart(10, "0");
}

function invoiceNo(invoiceNo) {
  return "I" + String(invoiceNo).padStart(10, "0");
}

function paymentNo(paymentNo) {
  return "P" + String(paymentNo).padStart(10, "0");
}

function formatMoney(input, locale, precision = 0) {
  if (input === undefined || input === null) return 0;
  if (!input || typeof input !== "number") {
    return input;
  }
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: locale,
    currencyDisplay: "symbol",
    minimumFractionDigits: precision,
  });
  return formatter.format(input);
}

function formatMoneyNumber(input, precision = 0) {
  if (!input || typeof input !== "number") {
    return input;
  }
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: precision,
  });
  return formatter.format(input);
}
