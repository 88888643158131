export const accommodationStatus = {
  getColor,
  getLightLinearGradient,
};

function getColor(status) {
  switch (status) {
    case "Confirmed":
      return "darkblue";
    case "Checked_In":
      return "purple";
    case "Checked_Out":
      return "darkgreen";
    case "Cancelled":
      return "darkred";
    case "New":
      return "grey";
    default:
      return "white";
  }
}

function getLightLinearGradient(status) {
  switch (status) {
    case "Confirmed":
      return "linear-gradient(rgba(0, 0, 139, 0.75), rgba(0, 0, 139, 0.75))";
    case "Checked_In":
      return "linear-gradient(rgba(128, 0, 128, 0.75), rgba(128, 0, 128, 0.75))";
    case "Checked_Out":
      return "linear-gradient(rgba(0, 100, 0, 0.75), rgba(0, 100, 0, 0.75))";
    case "Cancelled":
      return "linear-gradient(rgba(139, 0, 0, 0.75), rgba(139, 0, 0, 0.75))";
    default:
      return "white";
  }
}


