import React, { useContext, useEffect } from "react";
import {
  FormWithRedirect,
  TextInput,
  SelectInput,
  required,
  minLength,
  maxLength,
  email,
  useTranslate,
  ReferenceInput,
  Toolbar,
  FormDataConsumer
} from "react-admin";
import { DataProviderContext } from "react-admin";
import { Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import CustomSaveButton from "./CustomSaveButton";
import { useNotify, useRedirect } from "react-admin";

const validateFirstName = [required(), minLength(2), maxLength(25)];
const validateEmail = [required(), email()];

const useStyles = makeStyles((theme) => ({
  formGrid: {
    paddingTop: "0!important",
    paddingBottom: "0!important",
  },
  formInput: {
    marginTop: "0!important",
    marginBottom: "0!important",
  },
  textBold: {
    fontWeight: "bold",
  },
}));

const CMRoomRateForm = (props) => {
  const authInfo = JSON.parse(localStorage.getItem("auth"));
  const classes = useStyles();
  const translate = useTranslate();
  const { source, ...rest } = props;
  const [roomTypes, setRoomTypes] = React.useState([]);
  const notify = useNotify();
  const dataProvider = useContext(DataProviderContext);

  useEffect(() => {
    var request = {
      tenant_id: authInfo.tenant.id,
    };
    dataProvider
      .getList("room_types", { filter: { ...request } })
      .then(({ data }) => {
        setRoomTypes(data);
      })
      .catch((error) => {
        notify(error);
      });
  }, []);

  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <Card>
          <form>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    className={classes.textBold}
                    gutterBottom
                  >
                    {translate("ra.label.client_info")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <ReferenceInput
                    className={classes.formInput}
                    source="cm_client_id"
                    reference="cm_clients"
                    sort={{ field: "name", order: "ASC" }}
                    required
                    label="ra.label.client_name"
                    fullWidth
                  >
                    <SelectInput optionText="name" />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <ReferenceInput
                    filter={{ tenant_id: authInfo.tenant.id, status: "Active" }}
                    className={classes.formInput}
                    source="room_type_id"
                    reference="room_types"
                    sort={{ field: "name", order: "ASC" }}
                    label="ra.label.room_types"
                    required
                    fullWidth
                  >
                    <SelectInput optionText="name" />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <FormDataConsumer>
                    {({ formData, ...rest }) =>
                      roomTypes.length > 0 && formData.room_type_id ? (
                        <SelectInput
                          className={classes.formInput}
                          fullWidth
                          label="Room Type Rate"
                          source="room_type_rate_id"
                          choices={roomTypes
                            .filter(
                              (fitem) => fitem.id === formData.room_type_id
                            )[0]
                            .room_type_rates.map((item) => {
                              return {
                                id: item.id,
                                name: item.room_rate.name,
                              };
                            })}
                          optionText="name"
                          options={{
                            InputProps: { autoComplete: "no" },
                          }}
                          validate={[required()]}
                        />
                      ) : null
                    }
                  </FormDataConsumer>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    fullWidth
                    resource="cm_room_type_rate_maps"
                    source="cm_client_room_rate_name"
                    required
                    label="Client Room Rate Name"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Toolbar>
              <Box display="flex" justifyContent="space-between" width="100%">
                <CustomSaveButton {...formProps} />
                {/* {formProps.record.id != undefined ?
                  <DeleteButton record={formProps.record} />
                  : null} */}
              </Box>
            </Toolbar>
          </form>
        </Card>
      )}
    />
  );
};

export default CMRoomRateForm;
