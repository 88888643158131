import FaceIcon from '@material-ui/icons/Business';
import CompanyList from './CompanyList';
import CompanyCreate from './CompanyCreate';
import CompanyEdit from './CompanyEdit';

export default {
    list: CompanyList,
    create: CompanyCreate,
    edit: CompanyEdit,
    icon: FaceIcon,
};