export const userRole = {
	getColor,
};

function getColor(role) {
	switch (role) {
		case "manager":
			return "#3F51B5";
		case "frontdesk":
			return "#009688";
		case "housekeeper":
			return "#607D8B";
		default:
			return "teal";
	}
}
  