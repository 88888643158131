import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    EmailField,
    EditButton,
    Filter,
    SearchInput,
    TextInput,
    BooleanInput,
    FunctionField,
    ReferenceInput,
    SelectInput,
    downloadCSV,
} from "react-admin";
import {Button, InputAdornment} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import {guestStatus} from "../../models/guestStatus";
import Chip from "@material-ui/core/Chip";
import jsonExport from "jsonexport/dist";


const cmAccountsFilters = [
    <TextInput
        source="login"
        label="Search"
        alwaysOn
        resettable
        InputProps={{
            endAdornment: (
                <InputAdornment>
                    <SearchIcon color="disabled"/>
                </InputAdornment>
            ),
        }}
    />,
    <ReferenceInput
        style={{width: 250}}
        label="Status"
        source="status"
        reference="cm_client_status"
        alwaysOn
    >
        <SelectInput optionText="comment"/>
    </ReferenceInput>,
];

const exporter = (records) => {
    const forExport = records.map((record) => {
        return {
            client: record.cm_client.name,
            status: record.status,
            email: record.login,
            password: record.password,
        };
    });
    jsonExport(
        forExport,
        {
            headers: ["client", "status", "email", "password"],
        },
        (err, csv) => {
            downloadCSV(csv, "cm_acounts");
        }
    );
};

const CMAccountsList = (props) => {
    const authInfo = JSON.parse(localStorage.getItem("auth"));

    return (
        <List
            sort={{field: "status", order: "Desc"}}
            filters={cmAccountsFilters}
            filterDefaultValues={{tenant_id: authInfo.tenant.id}}
            bulkActionButtons={false}
            exporter={exporter}
            {...props}
        >
            <Datagrid>
                <TextField source="cm_client.name" label="Name"/>
                <EmailField source="login" label="Email"/>
                <FunctionField
                    sortBy="status"
                    label="Status"
                    render={(record) => (
                        <Chip
                            label={record.status}
                            style={{
                                color: "white",
                                background: guestStatus.getColor(record.status),
                            }}
                        />
                    )}
                />
                <EditButton style={{float: "right"}} basePath="/cm_accounts"/>
            </Datagrid>
        </List>
    );
};

export default CMAccountsList;
