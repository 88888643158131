import { executeAsync, output } from "./hasura";

export const maintenanceApi = {
  updateRefDeliveryIdAsync,
}

async function updateRefDeliveryIdAsync(maintenanceId, refDeliveryId) {
  var gql = `
    mutation UpdateRefDeliveryId($maintenanceId: uuid!, $refDeliveryId: uuid!) {
      update_maintenances_by_pk(pk_columns: {id: $maintenanceId}, _set: {refDeliveryId: $refDeliveryId}) {
        id
      }
    }
  `;
  
  const { data, errors } = await executeAsync({ maintenanceId, refDeliveryId }, gql);
  const value = { id: data?.update_maintenances_by_pk?.id };
  return output(value, errors);
}