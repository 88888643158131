import React from 'react';
import { Create } from 'react-admin';
import InvoiceForm from './InvoiceForm';

const InvoiceCreate = props => (
  <Create {...props} component="div">
    <InvoiceForm />
  </Create>
);

export default InvoiceCreate;
