import React from 'react';
import { Edit } from 'react-admin';
import AccommodationForm from './AccommodationForm';
import { useTranslate } from "react-admin";

const AccommodationTitle = ({record}) => {
  const translate = useTranslate();
  return <span>Accommodation { record && record.room ? `"${record.room.name}"` : ''}</span>
};

const AccommodationEdit = props => (
  <Edit title={<AccommodationTitle />} {...props} component="div">
    <AccommodationForm />
  </Edit>
);

export default AccommodationEdit;
