import { apiConfig } from "./apiConfig";
import { executeAsync, outputAsync } from "./bleudine";

export const deliveryApi = {
  createDeliveryAsync,
	cancelDeliveryAsync,
};


async function createDeliveryAsync( token, 
																		pickupLocationId, 
																		priorityLevel, 
																		merchantId, 
																		merchantName, 
																		currencyCode, 
																		customer, 
																		deliveryItems, 
																		deliveryPickUpImages) 
{
  const url = `${apiConfig.url}${apiConfig.path}/v1/deliveries`;
  //const url = `http://localhost:5001/api/v1/deliveries`;

	let convertedPriorityLevel;
	switch (priorityLevel) {
		case "Low":
			convertedPriorityLevel = 0;
			break;
		case "Medium":
			convertedPriorityLevel = 1;
			break;
		case "High":
			convertedPriorityLevel = 2;
			break;
	}

	const body = {
		deliveryItems,
		customer,
		priorityLevel: convertedPriorityLevel,
		deliveryPickUpImages,
		merchantId,
		merchantName,
		currencyCode,
		pickupLocationId,
		deliveryOption: "Self",
		deliveryFee: 0,
		transactionFee: 0
	};

  const fetchResponse = await executeAsync("POST", url, token, body);
	const data = fetchResponse.ok ? await fetchResponse.json() : null;
	const value = { id: data?.data };

	return await outputAsync(fetchResponse, value);
}


async function cancelDeliveryAsync(token, id) {
	const url = `${apiConfig.url}${apiConfig.path}/v1/deliveries/${id}/cancel`;
  //const url = `http://localhost:5001/api/v1/deliveries/${id}/cancel`;

  const fetchResponse = await executeAsync("PUT", url, token);
  return await outputAsync(fetchResponse, null);
}