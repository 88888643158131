import React from 'react';
import { Edit } from 'react-admin';
import UserForm from './UserForm';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";


const UserName = ({ record }) => { debugger
  return <span>Guest {record ? `${record.first_name} ${record.last_name != null ? record.last_name : ''}` : ''}</span>
};

const UserEdit = props => {

  return  (
    <div>
      {/* <Breadcrumbs aria-label="breadcrumb" style={{
        borderRadius: 5,
        background: "white",
        padding: 12,
        border: "1px solid #e0e0e3",
        marginTop: 12,
      }}>
        <Link color="inherit" href={ props.location.pathname.split("/")[3] ? `/users?role=manager`  : `/users` }>
          Users
        </Link>
        <Link style={{ fontWeight: "bold" }} color="textPrimary" aria-current="page" disabled>
          User
        </Link>
      </Breadcrumbs> */}
      <Edit title={<UserName />} {...props} component="div">
        <UserForm />
      </Edit>
    </div>
  
  );
}

export default UserEdit;
