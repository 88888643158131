import { apiConfig } from "./apiConfig";
import { executeAsync, outputAsync } from "./bleudine";
import { handleResponse, handleError } from "./apiHelper";
const localhostPort = 49386;

export const imageApi = {
  upload,
  uploadAsync,
  remove,
};

function upload(request) {
  const url = `${apiConfig.url}${apiConfig.path}/images/upload`;
  //const url = `http://localhost:${localhostPort}/api/images/upload`;
  
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${apiConfig.getBleudineToken()}`,
      "Content-Type": "application/json-patch+json",
    },
    body: JSON.stringify(request),
  };

  return fetch(url, requestOptions)
    .then(handleResponse, handleError)
    .then((response) => {
      return response;
    });
}


async function uploadAsync(imagePayload) {
  const url = `${apiConfig.url}${apiConfig.path}/images/upload`;
  //const url = `http://localhost:${localhostPort}/api/images/upload`;
  
  const token = `${apiConfig.getBleudineToken()}`;
  const body = imagePayload;

  const fetchResponse = await executeAsync("POST", url, token, body);
  const value = fetchResponse.ok ? await fetchResponse.json() : null;
  return await outputAsync(fetchResponse, value);
}


function remove(request) {
  const url = `${apiConfig.url}${apiConfig.path}/images/delete`;
  //const url = `http://localhost:${localhostPort}/api/images/delete`;

  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${apiConfig.getBleudineToken()}`,
      "Content-Type": "application/json-patch+json",
    },
    body: JSON.stringify(request),
  };

  return fetch(url, requestOptions)
    .then(handleResponse, handleError)
    .then((response) => {
      return response;
    });
}
