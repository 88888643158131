import React, { useContext, useEffect } from "react";
import {
  FormWithRedirect,
  TextInput,
  NumberInput,
  SelectInput,
  ImageInput,
  ImageField,
  DeleteButton,
  required,
  minLength,
  maxLength,
  useTranslate,
  ArrayInput,
  SimpleFormIterator,
  CheckboxGroupInput,
  ReferenceInput,
  AutocompleteInput,
  Toolbar,
  ReferenceArrayInput,
  SaveButton,
  FormDataConsumer,
  minValue
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { Typography, Box, InputAdornment } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import ImageUploader from "../components/ImageUploader";
import CustomSaveButton from './CustomSaveButton';
import { authApi } from "../../api/authApi";
import { useNotify, useRedirect } from "react-admin";


const useStyles = makeStyles((theme) => ({
  formGrid: {
    paddingTop: "0!important",
    paddingBottom: "0!important",
  },
  formInput: {
    marginTop: "0!important",
    marginBottom: "0!important",
  },
  textBold: {
    fontWeight: "bold",
  },
  // groupLabel: {
  //   color: "rgba(0, 0, 0, 0.54)",
  //   fontSize: "14px"
  // }
}));

const RoomTypeForm = (props) => {
  const authInfo = JSON.parse(localStorage.getItem('auth'));
  const classes = useStyles();
  const translate = useTranslate();
  const notify = useNotify();
  const { source, ...rest } = props;
  const [images, setImages] = React.useState([]);

  useEffect(() => {
    authApi.getGuestToken().then(
      (authData) => {},
      (error) => {notify("Fail to get token info"); })
    if (props.record != null) {
      setImages(props.record.images_url?.map(element => {
        return { data_url: element }
      }));
    }
  }, []);

  return (
    <FormWithRedirect
      {...props}
      render={formProps => (
        <Card>
          <form>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.textBold} gutterBottom>
                    Room Type Info
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    fullWidth
                    resource="room_types"
                    source="name"
                    validate={[required(), minLength(2), maxLength(25)]}
                    label="ra.column.name"
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <ReferenceInput
                    className={classes.formInput}
                    source="type"
                    reference="e_room_types"
                    sort={{ field: "comment", order: "ASC" }}
                    filterToQuery={(searchText) => ({
                      comment: searchText,
                    })}
                    label="ra.column.type"
                    fullWidth
                    validate={[required()]}
                  >
                    <AutocompleteInput
                      optionText="comment"
                      options={{ InputProps: { autoComplete: "no" } }}
                    />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={12} className={classes.formGrid}>
                  <RichTextInput
                    resource="room_types"
                    source="description"
                    label="ra.column.description"
                    validate={[required()]}
                  />
                </Grid>
                <Grid item xs={12} sm={4} className={classes.formGrid}>
                  <NumberInput
                    className={classes.formInput}
                    fullWidth
                    resource="room_types"
                    source="area"
                    label="Area"
                    min={0}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">square feet</InputAdornment>,
                    }}
                    validate={[required()]}
                  />
                </Grid>
                <Grid item xs={12} sm={2} className={classes.formGrid}>
                  <NumberInput
                    className={classes.formInput}
                    fullWidth
                    resource="room_types"
                    source="max_occupancy"
                    label="Max occupancy"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">person</InputAdornment>,
                    }}
                    min={1} max={10}
                    validate={[required()]}
                  />
                </Grid>
                <Grid item xs={12} sm={2} className={classes.formGrid}>
                  <NumberInput
                    className={classes.formInput}
                    fullWidth
                    resource="room_types"
                    source="default_occupancy"
                    label="Default occupancy"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">person</InputAdornment>,
                    }}
                    min={1} max={10}
                    validate={[required()]}
                  />
                </Grid>
                <Grid item xs={12} sm={2} className={classes.formGrid}>
                  <NumberInput
                    className={classes.formInput}
                    fullWidth
                    resource="room_types"
                    source="max_adults"
                    label="Max adults"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">person</InputAdornment>,
                    }}
                    min={1} max={10}
                    validate={[required()]}
                  />
                </Grid>
                <Grid item xs={12} sm={2} className={classes.formGrid}>
                  <NumberInput
                    className={classes.formInput}
                    fullWidth
                    resource="room_types"
                    source="max_children"
                    label="Max children"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">person</InputAdornment>,
                    }}
                    min={1} max={10}
                    validate={[required()]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Card><CardContent>
                    <ArrayInput label="Beds" resource="room_types" source="room_type_beds">
                      <SimpleFormIterator>
                        <NumberInput  min={0} source="width" label="Width" InputProps={{
                          endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                        }} validate={[required(),minValue(1)]} />
                        <NumberInput min={0} source="length" label="Length" InputProps={{
                          endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                        }} validate={[required(),minValue(1)]} />
                        <ReferenceInput label="Mattress type" source="mattress_type" reference="e_mattress_types" validate={[required(),minValue(1)]} >
                          <SelectInput optionText="comment" />
                        </ReferenceInput>
                        <NumberInput min={0} source="qty" defaultValue={1} label="Quantity" validate={[required(), minValue(1)]} />
                        <TextInput
                          style={{ display: "none" }}
                          source="tenant_id"
                          label="Tenant ID"
                          fullWidth
                          defaultValue={authInfo.tenant.id}
                          validate={[required()]}
                        />
                      </SimpleFormIterator>
                    </ArrayInput>
                  </CardContent></Card>
                </Grid>
                <Grid item xs={12}>
                  <Card><CardContent>
                    <ReferenceArrayInput label="Room facilities" source="room_facilities" reference="e_room_facilities">
                      <CheckboxGroupInput optionText="comment" optionValue="id" />
                    </ReferenceArrayInput>
                  </CardContent></Card>
                </Grid>
                <Grid item xs={12}>
                  <Card><CardContent>
                    <ReferenceArrayInput label="Bathroom facilities" source="bathroom_facilities" reference="e_bathroom_facilities">
                      <CheckboxGroupInput optionText="comment" optionValue="id" />
                    </ReferenceArrayInput>
                  </CardContent></Card>
                </Grid>
                <Grid item xs={12}>
                  <Card><CardContent>
                    <ReferenceArrayInput label="View facilities" source="view_facilities" reference="e_view_facilities">
                      <CheckboxGroupInput optionText="comment" optionValue="id" />
                    </ReferenceArrayInput>
                  </CardContent></Card>
                </Grid>
                <Grid item xs={12}>
                  <Card><CardContent>
                    <ReferenceArrayInput label="Bedroom facilities" source="bedroom_facilities" reference="e_bedroom_facilities">
                      <CheckboxGroupInput optionText="comment" optionValue="id" />
                    </ReferenceArrayInput>
                  </CardContent></Card>
                </Grid>
                <Grid item xs={12}>
                  <Card><CardContent>
                    <ReferenceArrayInput label="Entertainment facilities" source="entertainment_facilities" reference="e_entertainment_facilities">
                      <CheckboxGroupInput optionText="comment" optionValue="id" />
                    </ReferenceArrayInput>
                  </CardContent></Card>
                </Grid>
                <Grid item xs={12}>
                  <Card><CardContent>
                    <ReferenceArrayInput label="Kitchen facilities" source="kitchen_facilities" reference="e_kitchen_facilities">
                      <CheckboxGroupInput optionText="comment" optionValue="id" />
                    </ReferenceArrayInput>
                  </CardContent></Card>
                </Grid>
                <Grid item xs={12}>
                  <Card><CardContent>
                    <ReferenceArrayInput label="Outdoor facilities" source="outdoor_facilities" reference="e_outdoor_facilities">
                      <CheckboxGroupInput optionText="comment" optionValue="id" />
                    </ReferenceArrayInput>
                  </CardContent></Card>
                </Grid>
                <Grid item xs={12}>
                  <Card><CardContent>
                    <ReferenceArrayInput label="Floor facilities" source="floor_facilities" reference="e_floor_facilities">
                      <CheckboxGroupInput optionText="comment" optionValue="id" />
                    </ReferenceArrayInput>
                  </CardContent></Card>
                </Grid>
                <Grid item xs={12}>
                  <Card><CardContent>
                    <Typography style={{ marginTop: 10, color: "#000000", opacity: "0.5" }} variant="caption" display="block" gutterBottom>
                      Images
                    </Typography>
                    <ImageUploader
                      maxNumber={10}
                      images={images}
                      setImages={setImages}
                    />
                  </CardContent></Card>
                </Grid>
                <Grid item xs={12} sm={12} className={classes.formGrid}>
                  <ReferenceInput
                    className={classes.formInput}
                    fullWidth
                    label="Status"
                    source="status"
                    reference="e_room_type_status"
                    validate={[required()]}
                  >
                    <SelectInput optionText="comment" />
                  </ReferenceInput>
                </Grid>
              </Grid>
            </CardContent>
            <Toolbar>
              <Box display="flex" justifyContent="space-between" width="100%">
                <CustomSaveButton {...formProps} images={images} />
                {/* {formProps.record.id != undefined ?
                  <DeleteButton record={formProps.record} />
                  : null} */}
              </Box>
            </Toolbar>
          </form>
        </Card>

      )}
    />
  )
}

export default RoomTypeForm;