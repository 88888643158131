import HouseIcon from '@material-ui/icons/House';
import RoomTypeList from './RoomTypeList';
import RoomTypeCreate from './RoomTypeCreate';
import RoomTypeEdit from './RoomTypeEdit';

export default {
    list: RoomTypeList,
    create: RoomTypeCreate,
    edit: RoomTypeEdit,
    icon: HouseIcon,
};