import React from "react";
import { Edit } from "react-admin";
import CMRoomRateForm from "./CMRoomRateForm";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";

const ClientTitle = ({ record }) => {
  return <span>{record ? `${record.cm_client_room_rate_name}` : ""}</span>;
};

const CMRoomRateEdit = (props) => (
  <div>
    <Breadcrumbs
      aria-label="breadcrumb"
      style={{
        borderRadius: 5,
        background: "white",
        padding: 12,
        border: "1px solid #e0e0e3",
        //marginLeft: 16,
        //marginRight: 30,
        marginTop: 12,
      }}
    >
      <Link color="inherit" href="/cm_room_type_rate_maps">
        Channel Manager Room Rates
      </Link>
      <Link
        style={{ fontWeight: "bold" }}
        color="textPrimary"
        aria-current="page"
        disabled
      >
        Room Rate
      </Link>
    </Breadcrumbs>
    <Edit title={<ClientTitle />} {...props} component="div">
      <CMRoomRateForm />
    </Edit>
  </div>
);

export default CMRoomRateEdit;
