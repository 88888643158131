import React from 'react';
import { Edit } from 'react-admin';
import InvoiceForm from './InvoiceForm';
import { useTranslate } from "react-admin";
import { formatter } from "../../utils/formatter";

const InvoiceTitle = ({record}) => {
  const translate = useTranslate();
  return <span>Invoice { record ? `"${formatter.invoiceNo(record.invoice_no)}"` : ''}</span>
};

const InvoiceEdit = props => (
  <Edit title={<InvoiceTitle />} {...props} component="div">
    <InvoiceForm />
  </Edit>
);

export default InvoiceEdit;
