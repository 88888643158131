import FaceIcon from "@material-ui/icons/Face";
import CMRoomRateList from "./CMRoomRateList";
import CMRoomRateCreate from "./CMRoomRateCreate";
import CMRoomRateEdit from "./CMRoomRateEdit";

export default {
  list: CMRoomRateList,
  create: CMRoomRateCreate,
  edit: CMRoomRateEdit,
  icon: FaceIcon,
};
