import FaceIcon from '@material-ui/icons/Face';
import CMAccountsList from './CMAccountsList';
import CMAccountsCreate from './CMAccountsCreate';
import CMAccountsEdit from './CMAccountsEdit';

export default {
    list: CMAccountsList,
    create: CMAccountsCreate,
    edit: CMAccountsEdit,
    icon: FaceIcon,
};