import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  EditButton,
  Filter,
  SearchInput,
  TextInput,
  BooleanInput,
  FunctionField,
  ReferenceInput,
  SelectInput,
  downloadCSV
} from 'react-admin';
import { Button, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { guestStatus } from "../../models/guestStatus";
import Chip from '@material-ui/core/Chip';
import jsonExport from 'jsonexport/dist';


const guestFilters = [
  <TextInput
    source="email,first_name,last_name,phone_no"
    label="Search"
    alwaysOn
    resettable
    InputProps={{
      endAdornment: (
        <InputAdornment>
          <SearchIcon color="disabled" />
        </InputAdornment>
      ),
    }}
  />,
  <ReferenceInput
    style={{ width: 250 }}
    label="Document Type"
    source="document_type"
    reference="e_document_types"
    alwaysOn
    resettable
  >
    <SelectInput optionText="comment" />
  </ReferenceInput>,
  <TextInput
    source="document_no"
    label="Document No"
    alwaysOn
    resettable
    InputProps={{
      endAdornment: (
        <InputAdornment>
          <SearchIcon color="disabled" />
        </InputAdornment>
      ),
    }}
  />,
  <ReferenceInput
    style={{ width: 250 }}
    label="Status"
    source="status"
    reference="e_guest_status"
    alwaysOn
    resettable
  >
    <SelectInput optionText="comment" />
  </ReferenceInput>,
];

const exporter = records => {
  const forExport = records.map(record => {
    return {
      first_name: record.first_name,
      last_name: record.last_name,
      email: record.email,
      phone_no: record.phone_no,
      nationality: record.e_nationality.comment,
      status: record.e_guest_status.comment,
      document_type: record.document_type,
      document_no: record.document_no
    };
  });
  jsonExport(forExport, {
    headers: ['first_name', 'last_name', 'email', 'phone_no', 'document_type', 'document_no', 'nationality', 'status']
  }, (err, csv) => {
    downloadCSV(csv, 'guests');
  });
};

const GuestList = props => {
  const authInfo = JSON.parse(localStorage.getItem('auth'));

  return (
    <List sort={{ field: 'created_at', order: 'Desc' }} filters={guestFilters} filterDefaultValues={{ tenant_id: authInfo.tenant.id }} bulkActionButtons={false} exporter={exporter} {...props}>
      <Datagrid>
        <TextField source="first_name" />
        <TextField source="last_name" />
        <EmailField source="email" />
        <TextField source="phone_no" label="Phone number" />
        <TextField source="document_type" label="Document Type" />
        <TextField source="document_no" label="Document No" />
        <TextField source="e_nationality.comment" label="Nationality" />
        <FunctionField sortBy="status" label="Status" render={record => <Chip label={record.e_guest_status?.comment} style={{ color: 'white', background: guestStatus.getColor(record.e_guest_status?.id) }} />} />
        <EditButton style={{ float: "right" }} basePath="/guests" />
      </Datagrid>
    </List>
  );
}

export default GuestList;