import React from 'react';
import { Create } from 'react-admin';
import GuestForm from './GuestForm';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";

const GuestCreate = props => (
  <div>
    <Breadcrumbs aria-label="breadcrumb" style={{
      borderRadius: 5,
      background: "white",
      padding: 12,
      border: "1px solid #e0e0e3",
      //marginLeft: 16,
      //marginRight: 30,
      marginTop: 12,
    }}
    >
      <Link color="inherit" href="/guests">
        Guests
      </Link>
      <Link style={{ fontWeight: "bold" }} color="textPrimary" aria-current="page" disabled>
        Guest
      </Link>
    </Breadcrumbs>
    <Create {...props} component="div">
      <GuestForm />
    </Create>
  </div>

);

export default GuestCreate;
