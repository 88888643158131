import React from "react";
import { useTranslate } from "react-admin";
import MuiPhoneNumber from "material-ui-phone-number";
import { useRecordContext } from "react-admin";
import { useInput } from 'react-admin';

export const IntlPhoneInput = props => {
  const translate = useTranslate();
  const record = useRecordContext();

  const {
    input: { name, onChange, ...rest },
    meta: { touched, error },
    isRequired
  } = useInput(props);
  return record ? (
    <MuiPhoneNumber
      name={name}
      label={props.label}
      onChange={onChange}
      defaultCountry={"id"}
      variant="filled"
      fullWidth
      {...rest}
    />
  ) : null;
};