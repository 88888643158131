import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  EditButton,
  SearchInput,
  FunctionField,
  useListContext,
  TopToolbar,
  ExportButton,
  sanitizeListRestProps,
  TextInput,
  DateInput,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  downloadCSV
} from "react-admin";
import { accommodationStatus } from "../../models/accommodationStatus";
import { formatter } from "../../utils/formatter";
import Chip from "@material-ui/core/Chip";
import EditIcon from "@material-ui/icons/Edit";
import { Button, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import jsonExport from 'jsonexport/dist';


const filters = [
  // <TextInput
  //   type="number"
  //   source="booking.booking_no"
  //   label="Booking No"
  //   alwaysOn
  //   resettable
  //   InputProps={{
  //     startAdornment: <InputAdornment>B</InputAdornment>,
  //     endAdornment: (
  //       <InputAdornment>
  //         <SearchIcon color="disabled" />
  //       </InputAdornment>
  //     ),
  //   }}
  // />,
  //   <ReferenceInput
  //   source="booking_id"
  //   reference="bookings"
  //   filter={{ tenant_id: authInfo.tenant.id }}
  //   sort={{ field: "booking_no", order: "ASC" }}
  //   filterToQuery={(searchText) => ({
  //     booking_no: searchText.replace("B", ''),
  //   })}
  //   label="Booking"
  //   alwaysOn
  //   resettable
  // >
  //   <AutocompleteInput
  //     optionText={(choice) => `${formatter.bookingNo(choice.booking_no)}`}
  //     options={{
  //       InputProps: { autoComplete: "no" },
  //     }}
  //   />
  // </ReferenceInput>,
  // <ReferenceInput
  //   source="booking.guest_id"
  //   reference="guests"
  //   filter={{ tenant_id: authInfo.tenant.id }}
  //   sort={{ field: "display_name", order: "ASC" }}
  //   filterToQuery={(searchText) => ({
  //     display_name: searchText,
  //   })}
  //   label="Guest"
  //   alwaysOn
  //   resettable
  // >
  //   <AutocompleteInput
  //     optionText={(choice) => `${choice.display_name}`}
  //     options={{
  //       InputProps: { autoComplete: "no" },
  //     }}
  //   />
  // </ReferenceInput>,
  <DateInput
    style={{ width: 250 }}
    source="check_in_date"
    label="Check In Date"
    alwaysOn
  />,
  <DateInput
    style={{ width: 250 }}
    source="check_out_date"
    label="Check Out Date"
    alwaysOn
  />,
  <ReferenceInput
    fullWidth
    label="Status"
    source="status"
    reference="e_accommodation_status"
    alwaysOn
    resettable
  >
    <SelectInput optionText="comment" />
  </ReferenceInput>,
];

const ListActions = (props) => {
  const { className, filters, maxResults, ...rest } = props;
  const { total } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <ExportButton disabled={total === 0} maxResults={maxResults} />
    </TopToolbar>
  );
};

const exporter = records => {
  const forExport = records.map(record => {
      return {
        booking_no : formatter.bookingNo(record.booking.booking_no),
        guest : record.booking.guest.display_name,
        check_in_date : record.check_in_date,
        check_out_date : record.check_out_date,
        room_type : record.room_type.name,
        room : record.room.name,
        status : record.e_accommodation_status.comment,
      };
  });
  jsonExport(forExport, {
      headers: ['booking_no', 'guest', 'check_in_date', 'check_out_date','room_type', 'room', 'status']
  }, (err, csv) => {
      downloadCSV(csv, 'accommodations');
  });
};

const AccommodationList = (props) => {
  const authInfo = JSON.parse(localStorage.getItem("auth"));
  return (
    <List
      filters={filters}
      filterDefaultValues={{ tenant_id: authInfo.tenant.id }}
      actions={<ListActions />}
      sort={{ field: "created_at", order: "DESC" }}
      bulkActionButtons={false}
      exporter={exporter}
      {...props}
    >
      <Datagrid>
        <FunctionField
          sortBy="booking.booking_no"
          label="Booking No"
          render={(record) => formatter.bookingNo(record.booking.booking_no)}
        />
        <TextField source="booking.guest.display_name" label="Guest" />
        <TextField source="check_in_date" label="Check In Date" />
        <TextField source="check_out_date" label="Check Out Date" />
        <FunctionField
          sortBy="room_type.name"
          label="Room Type"
          render={(record) => `${record.room_type.name}`}
        />
        <FunctionField
          sortBy="room.name"
          label="Room"
          render={(record) => `${record.room != null ? record.room.name : "-"}`}
        />
        <FunctionField
          sortBy="status"
          label="Status"
          render={(record) => (
            <Chip
              label={record.e_accommodation_status.comment}
              style={{
                color: "white",
                background: accommodationStatus.getColor(
                  record.e_accommodation_status.id
                ),
              }}
            />
          )}
        />
        <FunctionField
          render={(record) => (
            <Button
              style={{ border: "none", float: "right" }}
              startIcon={<EditIcon />}
              variant="outlined"
              color="primary"
              href={`/bookings/${record.booking_id}`}
              target="_blank"
            >
              View Booking
            </Button>
          )}
        />
      </Datagrid>
    </List>
  );
}

export default AccommodationList;
