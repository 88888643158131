import * as React from "react";
import { useHistory } from "react-router-dom";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  EditButton,
  TextInput,
  FunctionField,
  ReferenceInput,
  SelectInput,
  downloadCSV,
} from "react-admin";
import { Button, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import MenuBookIcon from '@material-ui/icons/MenuBook';
import { tenantStatus } from "../../models/tenantStatus";
import Chip from "@material-ui/core/Chip";
import jsonExport from "jsonexport/dist";


const tenantFilters = [
  <TextInput
    source="email,name,phone_no,region,city,country"
    label="Search"
    alwaysOn
    resettable
    InputProps={{
      endAdornment: (
        <InputAdornment>
          <SearchIcon color="disabled" />
        </InputAdornment>
      ),
    }}
  />,
  <ReferenceInput
    style={{ width: 250 }}
    label="Status"
    source="status"
    reference="e_tenant_status"
    alwaysOn
    resettable
  >
    <SelectInput optionText="comment" />
  </ReferenceInput>,
];

const exporter = (records) => {
  const forExport = records.map((record) => {
    return {
      name: record.name,
      email: record.email,
      phone_no: record.phone_no,
      country: record.e_country.comment,
      status: record.e_tenant_status.comment,
    };
  });
  jsonExport(
    forExport,
    {
      headers: ["name", "email", "phone_no", "country", "status"],
    },
    (err, csv) => {
      downloadCSV(csv, "tenants");
    }
  );
};

const TenantList = (props) => {
  const authInfo = JSON.parse(localStorage.getItem('auth'));
  const history = useHistory();
  const tenant_group_id = authInfo.tenant_group_id === undefined ? "00000000-0000-0000-0000-000000000000" : authInfo.tenant_group_id;
  
  const handleView = (tenant) => {
    let auth = JSON.parse(localStorage.getItem('auth'));
    auth.tenant = tenant;
    auth['tenant.id'] = tenant.id;
    auth.tenant_id = tenant.id;
    localStorage.setItem('auth', JSON.stringify(auth));
    setTimeout(() => { history.push("/") }, 1000);
  }

  return (
    <List
      sort={{ field: "created_at", order: "Desc" }}
      filters={tenantFilters}
      filterDefaultValues={{ tenant_group_id: tenant_group_id,is_deleted:false  }} 
      bulkActionButtons={false}
      exporter={exporter}
      {...props}
    >
      <Datagrid>
        <TextField source="name" label="ra.column.name" />
        <EmailField source="email" label="ra.column.email" />
        <TextField source="phone_no" label="ra.column.phone_no" />
        <TextField source="locale.currency_code" label="ra.column.currency" />
        <TextField source="city" label="ra.column.city" />
        <TextField source="e_country.comment" label="ra.column.country" />
        <FunctionField
          sortBy="status"
          label="Status"
          render={(record) => (
            <Chip
              label={record.e_tenant_status?.comment}
              style={{
                color: "white",
                background: tenantStatus.getColor(record.e_tenant_status?.id),
              }}
            />
          )}
        />
        <EditButton style={{ float: "right" }} basePath="/tenants" />
        <FunctionField
          render={(record) => (
            <Button
              style={{ border: "none", float: "right" }}
              startIcon={<MenuBookIcon />}
              variant="outlined"
              color="primary"
              onClick={() => handleView(record)}
            >
              Manage
            </Button>
          )}
        />
      </Datagrid>
    </List>
  );
}

export default TenantList;
