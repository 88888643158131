export const tenantStatus = {
    getColor,
  };
  
  function getColor(status) {
    switch (status) {
      case "Active":
        return "darkgreen";
      case "Inactive":
        return "darkred";
      default:
        return "white";
    }
  }
  