import gql from 'graphql-tag';

const GET_LIST_ORDERS = gql`
{
  is_valid
  accommodation_id
  booking_id
  invoice_id
  created_at
  description
  id
  order_no
  order_type
  price
  qty
  updated_at
  total_price
  status
  service_id
  service_charge
  e_order_status {
    comment
    id
  }
  accommodation {
    room {
      name
    }
    id
  }
}
`;

const GET_ONE_ORDERS = gql`
{
  is_valid
  accommodation_id
  booking_id
  invoice_id
  created_at
  description
  id
  order_no
  order_type
  price
  qty
  updated_at
  total_price
  status
  service_id
  service_charge
  e_order_status {
    comment
    id
  }
  accommodation {
    room {
      name
    }
    id
  }
}
`;

export{GET_LIST_ORDERS, GET_ONE_ORDERS}
