import FaceIcon from "@material-ui/icons/Face";
import CMRoomTypeList from "./CMRoomTypesList";
import CMRoomTypeCreate from "./CMRoomTypesCreate";
import CMRoomTypeEdit from "./CMRoomTypesEdit";

export default {
  list: CMRoomTypeList,
  create: CMRoomTypeCreate,
  edit: CMRoomTypeEdit,
  icon: FaceIcon,
};
