import React, { useState, useEffect } from 'react';
import { Create } from 'react-admin';
import RoomRateForm from './RoomRateForm';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { roomRateApi } from "../../api/roomrateApi";


const RoomRateCreate = props => {
  const authInfo = JSON.parse(localStorage.getItem('auth'));
  const [cancellationPolicies, setCancellationPolicies] = useState(null);

  const fetchCancellationPolicies = async () => {
    Promise.all([
      roomRateApi.getCancellationPolicy(authInfo.tenant.id),
    ])
    .then((results) => {
      const [cancellationPoliciesResponse] = results;
  
      setCancellationPolicies(cancellationPoliciesResponse.data.cancellation_policies);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
  }
  

  useEffect(() => {
    fetchCancellationPolicies();
  },[]);

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb" style={{
        borderRadius: 5,
        background: "white",
        padding: 12,
        border: "1px solid #e0e0e3",
        //marginLeft: 16,
        //marginRight: 30,
        marginTop: 12,
      }}>
        <Link color="inherit" href="/room_rates">
          Room Rates
        </Link>
        <Link style={{ fontWeight: "bold" }} color="textPrimary" aria-current="page" disabled>
          Room Rate
        </Link>
      </Breadcrumbs>
      {cancellationPolicies  && cancellationPolicies.length === 0 ? (
        <div>
          <ErrorAlert errorMessage="Please Create Cancellation Policy First Before Add Room Rate" onDismiss={() => {}} />
        </div>
      ) : null}
      <Create {...props} component="div">
        <RoomRateForm />
      </Create>
    </div>
  
  );
}

export default RoomRateCreate;


const ErrorAlert = ({ errorMessage, onDismiss }) => {
  if (!errorMessage) return null; 

  return (
    <div style={{
      backgroundColor: '#D32F2F',
      color: 'white',
      padding: '10px', 
      borderRadius: '5px',
      margin: '10px 0', 
      textAlign: 'center', 
      display: 'flex', 
      justifyContent: 'space-between', 
      alignItems: 'center', 
    }}>
      {errorMessage}
      <button onClick={onDismiss} style={{
        marginLeft: '20px', 
        backgroundColor: 'transparent',
        border: 'none', 
        color: 'white', 
        cursor: 'pointer', 
      }}>
        ✕
      </button>
    </div>
  );
};
