import FaceIcon from '@material-ui/icons/Face';
import GuestList from './GuestList';
import GuestCreate from './GuestCreate';
import GuestEdit from './GuestEdit';

export default {
    list: GuestList,
    create: GuestCreate,
    edit: GuestEdit,
    icon: FaceIcon,
};