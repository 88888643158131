import React from "react";
import { Create } from "react-admin";
import BookingCreateForm from "./BookingCreateForm";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";

const BookingCreate = (props) => (
  <div>
    <Breadcrumbs
      aria-label="breadcrumb"
      style={{
        borderRadius: 5,
        background: "white",
        padding: 12,
        border: "1px solid #e0e0e3",
        //marginLeft: 16,
        //marginRight: 30,
        marginTop: 12,
      }}
    >
      <Link color="inherit" href="/bookings">
        Bookings
      </Link>
      <Link color="textPrimary" style={{fontWeight:"bold"}} aria-current="page" disabled>
        Booking
      </Link>
    </Breadcrumbs>
    <Create {...props} component="div">
      <BookingCreateForm />
    </Create>
  </div>
);

export default BookingCreate;
