import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import RoomList from './RoomList';
import RoomCreate from './RoomCreate';
import RoomEdit from './RoomEdit';

export default {
    list: RoomList,
    create: RoomCreate,
    edit: RoomEdit,
    icon: MeetingRoomIcon,
};