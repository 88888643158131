import gql from "graphql-tag";

const cm2FASessions = gql`
{
  id
  code
  status
  created_at
  tenant_id
  cm_account {
    login
    cm_client {
      name
    }
  }
}
`;

export const GET_LIST_CM_2FA_SESSIONS = cm2FASessions;
export const GET_ONE_CM_2FA_SESSIONS = cm2FASessions;
