import React from "react";
import ImageUploading from "react-images-uploading";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const ImageUploader = props => {
  // const [images, setImages] = React.useState([]);
  // const maxNumber = 10;

  const onChange = (imageList, addUpdateIndex) => {
    console.log(imageList, addUpdateIndex);
    props.setImages(imageList);
  };

  return (
    <div className="ImageUploader">
      <ImageUploading
        multiple
        value={props.images}
        onChange={onChange}
        maxNumber={props.maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            <Button
              disabled={props.disabled}
              variant="contained" 
              color="primary"
              style={isDragging ? { color: "red" } : null}
              onClick={(e) => {
                e.preventDefault();
                onImageUpload();
              }}
              {...dragProps}
            >
              Click or Drop here
            </Button>
            <div>
              {imageList.map((image, index) => (
                <div key={index} className="image-item">
                  <img src={image.data_url} alt="" width="200" />
                  <div className="image-item__btn-wrapper">
                    <Button
                      disabled={props.disabled}
                      variant="contained" 
                      color="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        onImageRemove(index);
                      }}>Remove</Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </ImageUploading>
    </div>
  );
}

export default ImageUploader;