import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import CancelList from './CancelList';
import CancelCreate from './CancelCreate';
import CancelEdit from './CancelEdit';

export default {
    list: CancelList,
    create: CancelCreate,
    edit: CancelEdit,
    icon: MeetingRoomIcon,
};