import React, { useContext } from 'react';
import {
  SaveButton
} from 'react-admin';
import { useFormState } from 'react-final-form';
import { useNotify, useRedirect } from 'react-admin';
import { DataProviderContext } from 'react-admin';
import { imageApi } from "../../api/imageApi";
import { uuid } from "../../utils/uuid";


const CustomSaveButton = (formProps) => {
  const authInfo = JSON.parse(localStorage.getItem('auth'));
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useContext(DataProviderContext);
  let { values } = useFormState();
  const [saving, setSaving] = React.useState(false);


  //massage data
  var request = {
    ...values,
    tenant_id: authInfo.tenant.id
  }

  const handleCreateClick = () => {
    setSaving(true);
    var promises = [];
    var errors = [];
    let documentFrontImage = null;
    let documentBackImage = null;

    formProps.documentFrontImage.forEach(function (img) {
      promises.push(
        imageApi.upload({
          file: img.data_url.split(",")[1],
          fileName: uuid.create_UUID()
        }).then(
          (data) => {
            documentFrontImage = data.imageUrl;
          },
          (error) => {
            console.log(error);
          })
      );
    });
    formProps.documentBackImage.forEach(function (img) {
      promises.push(
        imageApi.upload({
          file: img.data_url.split(",")[1],
          fileName: uuid.create_UUID()
        }).then(
          (data) => {
            documentBackImage = data.imageUrl;
          },
          (error) => {
            console.log(error);
          })
      );
    });

    Promise.all(promises).then(() => {
      request.document_front_image_url = documentFrontImage;
      request.document_back_image_url = documentBackImage;
      request.bleudine_user_id = formProps.bleudineMembership?.id;
      dataProvider.create(formProps.resource, { data: { ...request } })
        .then(({ data }) => {
          redirect(`/guests`);
          notify("Guests created");
        })
        .catch(error => {
          setSaving(false);

          let message = "Something went wrong.";

          if (error?.message.includes("duplicate") && error?.message.includes("guests_email_key")) {
            message = "Fail to create guest, email already been used. Please search for existing guest.";
          }

          notify(message, 'error');
        })
    }
    );


  }

  const handleUpdateClick = () => {
    setSaving(true);
    if (formProps.record.document_front_image_url && formProps.documentFrontImage && formProps.documentFrontImage.length > 0 && formProps.record.document_front_image_url != formProps.documentFrontImage[0]?.data_url) {
      var splitExImg = formProps.record.document_front_image_url.split("/");
      imageApi.remove({
        fileName: splitExImg[splitExImg.length - 1]
      }).then(
        (data) => { },
        (error) => {
          console.log(error);
        })
    }
    if (formProps.record.document_back_image_url && formProps.documentBackImage && formProps.documentBackImage.length > 0 && formProps.record.document_back_image_url != formProps.documentBackImage[0]?.data_url) {
      var splitExImg = formProps.record.document_back_image_url.split("/");
      imageApi.remove({
        fileName: splitExImg[splitExImg.length - 1]
      }).then(
        (data) => { },
        (error) => {
          console.log(error);
        })
    }

    var promises = [];
    var errors = [];
    let documentFrontImage = null;
    let documentBackImage = null;

    formProps.documentFrontImage.forEach(function (img) {
      if (img.data_url.includes("data")) {
        promises.push(
          imageApi.upload({
            file: img.data_url.split(",")[1],
            fileName: uuid.create_UUID()
          }).then(
            (data) => {
              documentFrontImage = data.imageUrl;
            },
            (error) => {
              console.log(error);
            })
        );
      } else {
        documentFrontImage = img.data_url;
      }
    });

    formProps.documentBackImage.forEach(function (img) {
      if (img.data_url.includes("data")) {
        promises.push(
          imageApi.upload({
            file: img.data_url.split(",")[1],
            fileName: uuid.create_UUID()
          }).then(
            (data) => {
              documentBackImage = data.imageUrl;
            },
            (error) => {
              console.log(error);
            })
        );
      } else {
        documentBackImage = img.data_url;
      }
    });

    Promise.all(promises).then(() => {
      request.document_front_image_url = documentFrontImage;
      request.document_back_image_url = documentBackImage;
      request.bleudine_user_id = formProps.bleudineMembership?.id;
      dataProvider.update(formProps.resource, { id: request.id, data: { ...request } })
        .then(({ data }) => {
          redirect(`/guests`);
          notify("Guests updated");
        })
        .catch(error => {
          setSaving(false);
          let message = "Something went wrong.";

          if (error?.message.includes("duplicate") && error?.message.includes("guests_email_key")) {
            message = "Fail to update guest, email already been used. Please search for existing guest.";
          }

          notify(message, 'error');
        })
    })

  }

  return <SaveButton {...formProps} saving={saving} disabled={saving} onSave={values.id != undefined ? handleUpdateClick : handleCreateClick} />;
};

export default CustomSaveButton;
