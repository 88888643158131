import { apiConfig } from "./apiConfig";
import { executeAsync, outputAsync } from "./bleudine";

export const locationApi = {
  getPickupLocationAsync,
};

async function getPickupLocationAsync(token, rideum_merchant_id, street, district, zipcode, city, country) {
  const url = `${apiConfig.url}${apiConfig.path}/v1/locations/pickup`;
  //const url = `http://localhost:${localhostPort}/api/v1/locations/pickup`;

  const body = {
    correlationId: rideum_merchant_id,
    street,
    district,
    zipcode,
    city,
    country,
  };

  const fetchResponse = await executeAsync("POST", url, token, body);
  const data = fetchResponse.ok ? await fetchResponse.json() : null;
  const value = data?.data;
  return await outputAsync(fetchResponse, value);
}