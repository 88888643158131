export function getFirstLastDayInCurrentMonth() {
  const date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();

  const firstDay = new Date(y, m, 1);
  const lastDay = new Date(y, m + 1, 0);

  return [formatDate(firstDay), formatDate(lastDay)];
}

export function formatDate(date) {
  const offset = date.getTimezoneOffset();
  date = new Date(date.getTime() - offset * 60 * 1000);
  return date.toISOString().split("T")[0];
}
