import { apiConfig } from "./apiConfig";
import { handleResponse, handleError } from "./apiHelper";

export const roomRateApi = {
    getCancellationPolicy,
    getMeals,
    upsertRoomTypeRateAdjustment,
    removeRoomTypeRateAdjustment
};


function getCancellationPolicy(tenant_id) {
    var gql = {
        query: `query MyQuery {
            cancellation_policies(limit: 10, where: {tenant_id: {_eq: "${tenant_id}"}}) {
              id
              created_at
              after_period_cancel_charge
              name
              no_show_cancel_charge
              period_cancel_without_charge
              tenant_id
              updated_at
            }
          }
        `,
    };

    const url = apiConfig.hasuraUrl;
    const requestOptions = {
        method: "POST",
        headers: {
            "x-hasura-admin-secret": apiConfig.hasuraSecret,
            //'Authorization': `Bearer ${apiConfig.getRideumToken()}`,
            "Content-Type": "application/json-patch+json",
        },
        body: JSON.stringify(gql),
    };

    return fetch(url, requestOptions)
        .then(handleResponse, handleError)
        .then((data) => { return data; });
}



function getMeals(tenant_id) {
    var gql = {
        query: `query MyQuery {
            meals(limit: 10, where: {tenant_id: {_eq: "${tenant_id}"}}) {
              created_at
              id
              name
              price
              service_charge
              tenant_id
              updated_at
            }
          }

        `,
    };

    const url = apiConfig.hasuraUrl;
    const requestOptions = {
        method: "POST",
        headers: {
            "x-hasura-admin-secret": apiConfig.hasuraSecret,
            //'Authorization': `Bearer ${apiConfig.getRideumToken()}`,
            "Content-Type": "application/json-patch+json",
        },
        body: JSON.stringify(gql),
    };

    return fetch(url, requestOptions)
        .then(handleResponse, handleError)
        .then((data) => { return data; });
}

/**
    *
    * @param {Array<{room_type_rate_id: string, value: number, date: string}>} roomTypeAdjustmentRates
    * */
function upsertRoomTypeRateAdjustment(roomTypeAdjustmentRates) {
    let payload = roomTypeAdjustmentRates
        .map(adjustment => `{value: ${adjustment.value}, date: "${adjustment.date}", room_type_rate_id: "${adjustment.room_type_rate_id}"}`)
        .join(",")

    var gql = {
        query: `mutation UpsertRoomTypeAdjustmentRate{
	insert_room_type_rate_adjustment(objects:[${payload}],on_conflict:{
    constraint: room_type_rate_adjustment_room_type_rate_id_date_key,
    update_columns:[value]
  }){
    returning {id}
  }
}`
    }

    const url = apiConfig.hasuraUrl;
    const requestOptions = {
        method: "POST",
        headers: {
            "x-hasura-admin-secret": apiConfig.hasuraSecret,
            //'Authorization': `Bearer ${apiConfig.getRideumToken()}`,
            "Content-Type": "application/json-patch+json",
        },
        body: JSON.stringify(gql),
    };

    return fetch(url, requestOptions)
        .then(handleResponse, handleError)
        .then((data) => { return data; });

}


/**
    * @param {{room_type_rate_id: string, date: string}} payload
    * */
function removeRoomTypeRateAdjustment(payload) {
    const gql = {
        query: `mutation DeleteRoomTypeRateAdjustment{
                delete_room_type_rate_adjustment(where:{date: {_eq:"${payload.date}"}, room_type_rate_id:{_eq:"${payload.room_type_rate_id}"}}){
                affected_rows
            }
        }`
    }

    const url = apiConfig.hasuraUrl;
    const requestOptions = {
        method: "POST",
        headers: {
            "x-hasura-admin-secret": apiConfig.hasuraSecret,
            //'Authorization': `Bearer ${apiConfig.getRideumToken()}`,
            "Content-Type": "application/json-patch+json",
        },
        body: JSON.stringify(gql),
    };

    return fetch(url, requestOptions)
        .then(handleResponse, handleError)
        .then((data) => { return data; });


}
