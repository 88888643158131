import { apiConfig } from "./apiConfig";
import { handleResponse, handleError } from "./apiHelper";
const localhostPort = 64638;

export const bleudineUserApi = {
  createUser,
  updateUser,
  deleteUser
};

 function createUser(merchantId,newUser) {
  const url = `${apiConfig.url}${apiConfig.path}/users/bleustay/merchant/${merchantId}`;
  //const url = `https://localhost:54622/api/users/bleustay/merchant/${merchantId}`;
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${apiConfig.getBleudineToken()}`,
      "Content-Type": "application/json-patch+json",
    },
    body: JSON.stringify(newUser),
  };

  return fetch(url, requestOptions)
    .then(handleResponse, handleError)
    .then((newUser) => {
      return newUser;
    });
}

function updateUser(userId,updateUser) {
  const url = `${apiConfig.url}${apiConfig.path}/users/bleustay/${userId}`;
  //const url = `https://localhost:54622/api/users/bleustay/${userId}`;
  const requestOptions = {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${apiConfig.getBleudineToken()}`,
      "Content-Type": "application/json-patch+json",
    },
    body: JSON.stringify(updateUser),
  };

  return fetch(url, requestOptions)
    .then(handleResponse, handleError)
    .then((updatedUser) => {
      return updatedUser
    });
}
function deleteUser(userId) {
  const url = `${apiConfig.url}${apiConfig.path}/users/bleustay/${userId}`;
  //const url = `https://localhost:54622/api/users/bleustay/${userId}`;
  const requestOptions = {
    method: "Delete",
    headers: {
      Authorization: `Bearer ${apiConfig.getBleudineToken()}`,
      "Content-Type": "application/json-patch+json",
    },
    body: JSON.stringify(updateUser),
  };

  return fetch(url, requestOptions)
    .then(handleResponse, handleError)
    .then((deletedUser) => {
      return deletedUser
    });
}
