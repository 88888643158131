export const userStatus = {
  MANAGER: 2,
  FRONTDESK: 9,
  HOUSEKEEPER: 8,
  properties: {
    0: { name: "Manager", value: 2, code: "MANAGER" },
    1: { name: "FrontDesk", value: 9, code: "FRONTDESK" },
    2: { name: "Housekeeper", value: 8, code: "HOUSEKEEPER" },
  },
    getColor,
    getEnum
  };
  
  function getColor(status) {
    switch (status) {
      case "Active":
        return "darkgreen";
      case "Inactive":
        return "blue";
        case "Deleted":
          return "darkred";
      default:
        return "white";
    }
  }

  function getEnum(name) {
    switch (name) {
      case "manager":
        return 2;
      case "frontdesk":
        return 9;
      case "housekeeper":
        return 8;
      default:
        return "Unknown";
    }
  }
  