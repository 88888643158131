export const refundStatus = {
  getColor,
};

function getColor(status) {
  switch (status) {
    case "New":
      return "darkblue";
    case "Completed":
      return "darkgreen";
    case "Confirmed":
      return "darkblue";
    case "Cancelled":
      return "darkred";
    default:
      return "white";
  }
}
