import FaceIcon from '@material-ui/icons/Face';
import CM2FASessionsList from './CM2FASessionsList';
import CM2FASessionsCreate from './CM2FASessionsCreate';
import CM2FASessionsEdit from './CM2FASessionsEdit';

export default {
    list: CM2FASessionsList,
    create: CM2FASessionsCreate,
    edit: CM2FASessionsEdit,
    icon: FaceIcon,
};