import React, { useContext } from 'react';
import {
  SaveButton
} from 'react-admin';
import { useFormState } from 'react-final-form';
import { useNotify, useRedirect } from 'react-admin';
import { DataProviderContext } from 'react-admin';


const CustomSaveButton = (formProps) => {
  const authInfo = JSON.parse(localStorage.getItem('auth'));
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useContext(DataProviderContext);
  let { values } = useFormState();
  const [saving, setSaving] = React.useState(false);

  //massage data
  var request = {
    ...values,
    room_type_rates: { data: values.room_type_rates != null && values.room_type_rates.length > 0 ? values.room_type_rates : [] },
    tenant_id: authInfo.tenant.id
  }

  const checkDuplicateRoomType = (room_type_rates) => {
    if (room_type_rates && room_type_rates.length > 0) {
      var valueArr = request.room_type_rates.data.map(function (item) { return item.room_type_id });
      var isDuplicate = valueArr.some(function (item, idx) {
        return valueArr.indexOf(item) != idx
      });
      return isDuplicate;
    } else {
      return false
    }
  }

  const handleCreateClick = () => {
    setSaving(true);
    if (checkDuplicateRoomType(request.room_type_rates.data)) {
      notify("Room type is duplicated");
      setSaving(false);
    } else {
      //===========================================================
      //add room rate
      dataProvider.create(formProps.resource, { data: { ...request } })
        .then(({ data }) => {
          redirect(`/room_rates`);
          notify("Room rate created");
        })
        .catch(error => {
          setSaving(false);
          let message = "Something went wrong.";

          if (error?.message.includes("duplicate") && error?.message.includes("room_rates_name_tenant_id_key")) {
            message = "Fail to create room rate, name already been used.";
          }

          notify(message, 'error');
        })
    }

  }

  const handleUpdateClick = () => {
    setSaving(true);
    if (checkDuplicateRoomType(request.room_type_rates.data)) {
      notify("Room type is duplicated");
      setSaving(false);
    } else {
      //===========================================================
      //room type rate
      let room_type_rates = request.room_type_rates.data;
      let room_type_rates_id = room_type_rates.map(function (item) {
        return item.id
      });
      let existing_room_type_rates_id = formProps.record.room_type_rates.map(function (item) {
        return item.id
      });

      //delete room type rate
      existing_room_type_rates_id.forEach(element => {
        if (room_type_rates_id.indexOf(element) === -1) {
          dataProvider.delete("room_type_rates", { id: element })
        }
      })
      if (room_type_rates.length > 0) {
        room_type_rates.forEach(element => {
          if (element.id !== undefined) {
            //update room type rate
            dataProvider.update("room_type_rates", { id: element.id, data: { ...element } })
          } else {
            //create room type rate
            dataProvider.create("room_type_rates", { data: { ...element, room_rate_id: request.id, tenant_id: authInfo.tenant.id } })
          }
        })
      }

      //===========================================================
      //update room rate
      dataProvider.update(formProps.resource, { id: request.id, data: { ...request } })
        .then(({ data }) => {
          redirect(`/room_rates`);
          notify("Room rate updated");
        })
        .catch(error => {
          setSaving(false);
          let message = "Something went wrong.";

          if (error?.message.includes("duplicate") && error?.message.includes("room_rates_name_tenant_id_key")) {
            message = "Fail to update room rate, name already been used.";
          }

          notify(message, 'error');
        })
    }

  }

  return <SaveButton {...formProps} saving={saving} disabled={saving} onSave={values.id != undefined ? handleUpdateClick : handleCreateClick} />;
};

export default CustomSaveButton;