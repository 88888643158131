import gql from 'graphql-tag';

const GET_LIST_DEPOSITS = gql`
{
  amount
  booking_id
  created_at
  deposit_no
  payment_transaction_id
  id
  status
  tenant_id
  type
  updated_at
  e_deposit_status {
    comment
    id
  }
  e_deposit_type {
    comment
    id
  }
  invoice_payments {
    amount
    created_at
    deposit_id
    id
    invoice_id
    payment_no
    payment_type
    remarks
    status
    tenant_id
    updated_at
  }
  refunds {
    updated_at
    type
    tenant_id
    status
    refund_no
    order_id
    id
    deposit_id
    created_at
    booking_id
    beneficiary_name
    beneficiary_bank_name
    beneficiary_acc_no
    amount
  }
}
`;

const GET_ONE_DEPOSITS = gql`
{
  amount
  booking_id
  created_at
  deposit_no
  payment_transaction_id
  id
  status
  tenant_id
  type
  updated_at
  e_deposit_status {
    comment
    id
  }
  e_deposit_type {
    comment
    id
  }
  invoice_payments {
    amount
    created_at
    deposit_id
    id
    invoice_id
    payment_no
    payment_type
    remarks
    status
    tenant_id
    updated_at
  }
  refunds {
    updated_at
    type
    tenant_id
    status
    refund_no
    order_id
    id
    deposit_id
    created_at
    booking_id
    beneficiary_name
    beneficiary_bank_name
    beneficiary_acc_no
    amount
  }
}
`;

export{GET_LIST_DEPOSITS, GET_ONE_DEPOSITS}
