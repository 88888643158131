import { Box, Button, FormControl, Input, InputLabel, Paper, TableBody, Table, TableHead, TableCell, TableRow, Typography, Grid, TextField, TableContainer } from '@material-ui/core'
import moment from 'moment'
import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { Title, useTranslate } from 'react-admin'
import { reportApi } from '../../api/reportApi'
import { formatter } from '../../utils/formatter'
import { getFirstLastDayInCurrentMonth } from '../../utils/date'
import DownloadIcon from '@material-ui/icons/GetApp'
import * as XLSX from 'xlsx';

export default function FinancialReport() {
    const translate = useTranslate();
    const authInfo = JSON.parse(localStorage.getItem("auth"))

    const [initialStartDate, initialEndDate] =
        getFirstLastDayInCurrentMonth();

    const [fromDate, setFromDate] = useState(initialStartDate)
    const [toDate, setToDate] = useState(initialEndDate)

    const [report, setReport] = useState(null)
    const [reportGeneratedDate, setReportGeneratedDate] = useState(null);

    useEffect(() => {
        generateFinancialReport()
    }, []);

    const canGenerate = useMemo(() => () => {
        if (fromDate && toDate) {
            if (moment(fromDate).isSameOrBefore(moment(toDate))) {
                return true;
            }
        }
        return false;
    }, [fromDate, toDate])

    const exporter = () => {

        const first_data = [
            [`This is financial report generated from ${moment(fromDate).format("YYYY-MM-DD")} to ${moment(toDate).format("YYYY-MM-DD")}`],
        ]
        const rooms_data = report.rooms.map(room => {
            return {
                room_type: room.room_type,
                booked: formatter.formatMoney(room.booked_gross_total, authInfo.tenant.locale.currency_code, authInfo.tenant.locale.precision),
                invoiced: formatter.formatMoney(room.invoiced_gross_total, authInfo.tenant.locale.currency_code, authInfo.tenant.locale.precision)
            }
        })
        const service_meals_data = report.services.map(service => {
            return {
                name: service.service,
                booked: formatter.formatMoney(service.bookedTotal, authInfo.tenant.locale.currency_code, authInfo.tenant.locale.precision),
                invoiced: formatter.formatMoney(service.invoicedTotal, authInfo.tenant.locale.currency_code, authInfo.tenant.locale.precision)
            }
        })

        const rooms_sheet = XLSX.utils.aoa_to_sheet(first_data)
        const service_meal_sheet = XLSX.utils.aoa_to_sheet(first_data)

        /* generate workbook and add the worksheet */
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, rooms_sheet, 'Rooms');
        XLSX.utils.sheet_add_json(wb.Sheets.Rooms, rooms_data, { origin: "A3" })

        XLSX.utils.book_append_sheet(wb, service_meal_sheet, 'Service_Meals')
        XLSX.utils.sheet_add_json(wb.Sheets.Service_Meals, service_meals_data, { origin: "A3" })

        /* save to file */
        XLSX.writeFile(wb, `Financial_from_${moment(fromDate).format("YYYY-MM-DD")}_to_${moment(toDate).format("YYYY-MM-DD")}.xlsx`);
    };

    const generateFinancialReport = useCallback(async () => {
        setReportGeneratedDate(null);
        setReport(await reportApi.getFinancialReport(moment(fromDate), moment(toDate), authInfo.tenant.id));
        setReportGeneratedDate(moment(new Date()).format("YYYY-MM-DD"))
    }, [fromDate, toDate]);


    return <Box padding={1} margin={2} height={"100%"}>
        <Title title={translate(`ra.label.financial_report`, { smart_count: 2 })}></Title>
        <Grid container direction="column">
            <Grid item>
                <Grid container direction="row" spacing={2} alignItems='center'>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            style={{
                                marginTop: 12,
                            }}
                            type="date"
                            variant="filled"
                            value={fromDate}
                            onChange={(evt) => setFromDate(evt.target.value)}
                            fullWidth
                            label="Start Date"
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            style={{
                                marginTop: 12,
                            }}
                            type="date"
                            variant="filled"
                            value={toDate}
                            onChange={(evt) => setToDate(evt.target.value)}
                            fullWidth
                            label="End Date"
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Button
                            style={{
                                marginTop: 12,
                                marginRight: 5,
                            }}
                            onClick={generateFinancialReport}
                            variant="contained"
                            color="primary"
                        >
                            Search
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={3} style={{ marginLeft: 'auto', textAlignLast: 'end' }}>
                        <Button
                            style={{
                                marginTop: 12,
                                marginRight: 5,
                            }}
                            onClick={exporter}
                            variant="text"
                            color="primary"
                            endIcon={<DownloadIcon />}
                        >
                            Export
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <br />
            <Grid item>
                <Typography variant='h6' style={{ fontWeight: 'bold' }}>Rooms</Typography>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Room type</TableCell>
                                <TableCell>Booked</TableCell>
                                <TableCell>Invoiced</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {report?.rooms?.length > 0 ? report.rooms.map(room => <TableRow key={room.room_type}>
                                <TableCell>{room.room_type}</TableCell>
                                <TableCell>{formatter.formatMoney(room.booked_gross_total, authInfo.tenant.locale.currency_code, authInfo.tenant.locale.precision)}</TableCell>
                                <TableCell>{formatter.formatMoney(room.invoiced_gross_total, authInfo.tenant.locale.currency_code, authInfo.tenant.locale.precision)}</TableCell>
                            </TableRow>) : <TableRow><TableCell colSpan={3}>No record found</TableCell></TableRow>}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <br />
            <Grid item>
                <Typography variant='h6' style={{ fontWeight: 'bold' }}>Meal & Services</Typography>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Booked</TableCell>
                                <TableCell>Invoiced</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {report?.services?.length > 0 ? report.services.map(service => <TableRow key={service.service}>
                                <TableCell>{service.service}</TableCell>
                                <TableCell>{formatter.formatMoney(service.bookedTotal, authInfo.tenant.locale.currency_code, authInfo.tenant.locale.precision)}</TableCell>
                                <TableCell>{formatter.formatMoney(service.invoicedTotal, authInfo.tenant.locale.currency_code, authInfo.tenant.locale.precision)}</TableCell>
                            </TableRow>) : <TableRow><TableCell colSpan={3}>No record found</TableCell></TableRow>}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    </Box>
}
