import * as React from 'react';
import { forwardRef } from 'react';
import { AppBar, UserMenu, MenuItemLink, useTranslate } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/core/styles';
import Logo from './Logo';
import LockIcon from '@material-ui/icons/Lock';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
});

const ConfigurationMenu = forwardRef((props, ref) => {
    const translate = useTranslate();
    return (
        <MenuItemLink
            ref={ref}
            to="/configuration"
            primaryText={translate('ra.label.setting')}
            leftIcon={<SettingsIcon />}
            onClick={props.onClick}
            sidebarIsOpen
        />
    );
});
const ChangePasswordMenu = forwardRef((props, ref) => {
    const translate = useTranslate();
    return (
        <MenuItemLink
            ref={ref}
            to="/password/change"
            primaryText="Change Password"
            leftIcon={<LockIcon />}
            onClick={props.onClick}
            sidebarIsOpen
        />
    );
});

const CustomUserMenu = (props) => {
    return  (
        <UserMenu {...props}>
            {/* <ConfigurationMenu /> */}
            <ChangePasswordMenu />
        </UserMenu>
    );
}

const DynamicTitle = () => {
    const location = useLocation();
    const classes = useStyles();
    
    // Dynamically set the title based on the URL path
    let title = "Default Title"; // Default title
    if (location.pathname.split("/")[3] === 'manager') {
        title = "Manager Dashboard"; // Example title for manager
    }
    // Add more conditions to set different titles for different paths

    return (
        <Typography variant="h6" color="inherit" className={classes.title} id="react-admin-title">
            {title}
        </Typography>
    );
};


const CustomAppBar = (props) => {
    const classes = useStyles();
    const location = useLocation();
    const authInfo = JSON.parse(localStorage.getItem('auth'));
    const { first_name, last_name } = authInfo;

    return (
        <AppBar {...props} elevation={1} userMenu={<CustomUserMenu />}>
            {(location.pathname.split("/")[3] === 'manager') ? (
                <>
                    <Typography
                        variant="h6"
                        color="inherit"
                        className={classes.title}
                    >{first_name}{' '}{last_name}</Typography>
                </>
            ) : 
                <Typography
                    variant="h6"
                    color="inherit"
                    className={classes.title}
                    id="react-admin-title"
                />
            }
             {/* <DynamicTitle /> */}
            <Logo />
            <span className={classes.spacer} />
        </AppBar>
    );
};

export default CustomAppBar;
