import { apiConfig } from "./apiConfig";
import { handleResponse, handleError } from "./apiHelper";
const localhostPort = 5000;

export const paymentApi = {
  midtransGetConfig,
  midtransChargeWithToken,
  midtransCapture,
  midtransCancel
};

function midtransGetConfig() {
  const url = `${apiConfig.url}${apiConfig.path}/payments/midtrans/config`;
  //const url = `http://localhost:${localhostPort}/api/payments/midtrans/config`;
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${apiConfig.getBleudineToken()}`,
      "Content-Type": "application/json-patch+json",
    }
  };

  return fetch(url, requestOptions)
    .then(handleResponse, handleError)
    .then((response) => {
      return response;
    });
}

function midtransChargeWithToken(request) {
  const url = `${apiConfig.url}${apiConfig.path}/payments/midtrans/charge`;
  //const url = `http://localhost:${localhostPort}/api/payments/midtrans/charge`;
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${apiConfig.getBleudineToken()}`,
      "Content-Type": "application/json-patch+json",
    },
    body: JSON.stringify(request),
  };

  return fetch(url, requestOptions)
    .then(handleResponse, handleError)
    .then((response) => {
      return response;
    });
}

function midtransCapture(request) {
  const url = `${apiConfig.url}${apiConfig.path}/payments/midtrans/capture`;
  //const url = `http://localhost:${localhostPort}/api/payments/midtrans/capture`;
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${apiConfig.getBleudineToken()}`,
      "Content-Type": "application/json-patch+json",
    },
    body: JSON.stringify(request),
  };

  return fetch(url, requestOptions)
    .then(handleResponse, handleError)
    .then((response) => {
      return response;
    });
}

function midtransCancel(request) {
  const url = `${apiConfig.url}${apiConfig.path}/payments/midtrans/cancel`;
  //const url = `http://localhost:${localhostPort}/api/payments/midtrans/cancel`;
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${apiConfig.getBleudineToken()}`,
      "Content-Type": "application/json-patch+json",
    },
    body: JSON.stringify(request),
  };

  return fetch(url, requestOptions)
    .then(handleResponse, handleError)
    .then((response) => {
      return response;
    });
}
