import Icon from '@material-ui/icons/Book';
import BookingList from './BookingList';
import BookingCreate from './BookingCreate';
import BookingEdit from './BookingEdit';

export default {
    list: BookingList,
    create: BookingCreate,
    edit: BookingEdit,
    icon: Icon,
};