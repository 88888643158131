import * as React from 'react';
import { SVGProps } from 'react';
import { useTheme } from '@material-ui/core/styles';
import icLogo from "../../images/ic_logo_text_white.png";

const Logo = (props) => {
    const theme = useTheme();
    return <img src={icLogo} style={{height:50}}/>;
};

export default Logo;
